import React from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label as MyLabel,
} from "reactstrap";
import { setCopyText } from "redux/reducers/reducer.copyText";
import store from "redux/store";
import styled from "styled-components";
import { ocrThis, voiceRecThis } from "utils/conversion";
import { tx } from "utils/globalize";
import { showToast } from "utils/Toast";

const thumbnail = (path: string) => {
  return path.replace("&export=download", "");
  // .replace("/uc?", "/thumbnail?sz=w1000&");
};

const ThisImg: any = styled.img`
  width: 100%;
  border: 1px solid black;
`;

const Label: any = styled(MyLabel)`
  padding-top: 0px;
`;

const DelDiv: any = styled.div`
  display: inline-block;
  cursor: pointer;
`;

const OcrDiv: any = styled.div`
  color: blue;
  font-size: 70%;
  font-weight: bold;
  cursor: pointer;
  cursor: pointer;
  position: absolute;
  right: 0px;
  text-decoration: underline;
  background: white;
  padding-right: 15px;
  top: 0px;
`;

const ImgDiv: any = styled.div`
  max-height: 300px;
  overflow: auto;
`;

const MyTextarea: any = styled.textarea`
  width: 100%;
  min-height: 150px;
  color: #4c4a56;
  padding: 0.438rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #4c4a56;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
`;

const ClipBoardItem = (props: any) => {
  const onKeyDown = (e: any) => {
    if (e.key === "Enter" && e.ctrlKey) {
      e.preventDefault();
      props.addItem();
    }
  };

  const ocrMe = async () => {
    props.setIsLoading(true);
    const res = await ocrThis(props.data.img);
    store.dispatch(setCopyText(res || " "));
    props.setIsLoading(false);
  };

  const voiceRecMe = async () => {
    props.setIsLoading(true);
    await voiceRecThis(props.data.mp3);
    // store.dispatch(setCopyText(res || " "));
    props.setIsLoading(false);
    showToast({
      title: tx("success", "Success..."),
      description: tx(
        "acsesdf0898wekkswewkas",
        "Recognition started. You will be notified when it's done."
      ),
      type: "success",
    });
  };

  return (
    <React.Fragment>
      <Col xs="12" style={{ display: "flex", padding: "4px" }}>
        <CustomInput
          style={{ flex: "1 1 auto", marginRight: "8px" }}
          type="select"
          name="select"
          id="select-custom"
          onChange={props.onChangeClipboard}
          value={props.activeClipboard}
        >
          {props.clipboardCategories.map((item: any) => (
            <option value={item} key={item}>
              {decodeURIComponent(item)}
            </option>
          ))}
        </CustomInput>
        <Button
          outline
          className="text-dark-toolbar"
          onClick={props.newClipboard}
        >
          {tx("new", "New")}
        </Button>
      </Col>
      <Form
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingBottom: "15px",
        }}
      >
        {props.data.img ? (
          <FormGroup row className="mt-0">
            <Label style={{ paddingBottom: "0px" }} xs="12" for="title">
              <DelDiv onClick={() => props.clear("img")}>
                {tx("rese3434image", "Image")}
              </DelDiv>{" "}
              <OcrDiv onClick={ocrMe}>OCR</OcrDiv>  
            </Label>
            <Col xs="12">
              <ImgDiv>
                <ThisImg src={thumbnail(props.data.img)}></ThisImg>
              </ImgDiv>
            </Col>
          </FormGroup>
        ) : null}
        {props.data.mp3 ? (
          <FormGroup row style={{ minHeight: "50px" }}>
            <Label style={{ paddingBottom: "0px" }} xs="12" for="title">
              <DelDiv onClick={() => props.clear("mp3")}>
                {tx("rese3434csound", "Sound")}
              </DelDiv>{" "}
              <OcrDiv onClick={voiceRecMe}>
                {tx("rese3434ctrans", "Transcribe")}
              </OcrDiv>
                
            </Label>
            <Col xs="12">
              <div>
                <audio src={props.data.mp3} controls />
              </div>
            </Col>
          </FormGroup>
        ) : null}
        {props.data.file ? (
          <FormGroup row style={{ minHeight: "50px" }}>
            <Label style={{ paddingBottom: "0px" }} xs="12" for="title">
              <DelDiv onClick={() => props.clear("file")}>
                {tx("rese3434cfileset", "File")}
              </DelDiv>{" "}
            </Label>
            <Col xs="12">
              <div>{props.data.file.split("*")[1]}</div>
            </Col>
          </FormGroup>
        ) : null}
        <FormGroup row className="mt-0">
          <Label style={{ paddingBottom: "0px" }} xs="12" for="title">
            <DelDiv onClick={() => props.clear("infox")}>
              {tx("rese3434ctext", "Text")}
            </DelDiv>
          </Label>
          <Col xs="12">
            <InputGroup className="input-group-merge">
              <MyTextarea
                name="infox"
                onKeyDown={onKeyDown}
                ref={props.inputRef}
                id="infox"
                placeholder="..."
                value={props.data.infox}
                onChange={props.changeData}
              />
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label style={{ paddingBottom: "0px" }} xs="12" for="title">
            <DelDiv onClick={() => props.clear("linkx")}>
              {tx("reslinke3434ccopy", "Link")}
            </DelDiv>
          </Label>
          <Col xs="12">
            <InputGroup className="input-group-merge">
              <Input
                type="text"
                name="linkx"
                id="linkx"
                placeholder="..."
                value={props.data.linkx}
                onChange={props.changeData}
              />
            </InputGroup>
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};

export default ClipBoardItem;
