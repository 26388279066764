import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const driveOpenSlice = createSlice({
  initialState,
  name: "driveOpen",
  reducers: {
    setDriveOpen: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setDriveOpen } = driveOpenSlice.actions;

export default driveOpenSlice.reducer;
