export const presentationon = [
  {
    act: "presentationon",
    target: "#presentationx",
    trans: "xxpr1e1",
    content:
      "You are in read only mode, which means that you can view your book as a reader would see it, and you can't edit it. To edit the book turn off this option here and start the tutorial again if you want to. ",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
  },
];

export const booksbyothers = [
  {
    act: "booksbyothers",
    target: "#xchapters",
    trans: "xxpr2e2",
    placement: "center",
    content:
      "This is a book shared with you by somebody else. These are the chapters here. To see the contents of a chapter, click on the chapter title.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
  },
];

export const dochaptersorig = [
  {
    act: "chaptersmenusome",
    anchor: "chaptersmenusome",
    target: "body",

    content:
      "This is the list of the chapters in your book. What would you like to know?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "chaptershow",
        question: {
          trans: "bub35afc14",
          text: "How to add, edit and delete chapters?",
        },
      },
      {
        newride: "gofromchapters",
        question: {
          trans: "bub35afc1516",
          text: "How to add materials to your chapters?",
        },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1e3",
    noNext: true,
  },
  {
    act: "chaptersmenu",
    anchor: "chaptersmenu",
    target: "body",

    content:
      "This will be the list of the chapters in your book. But it's empty yet.",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "myfirstchapter",
        question: { trans: "wszsdfwe17", text: "Add your first chapter." },
      },
      {
        newride: "",
        question: {
          trans: "bub35afc18",
          text: "Not now, thank you",
        },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1e4",
    noNext: true,
  },
  {
    act: "chapters",
    target: "#xchapters",
    content:
      "Here you will see the chapters of the book. There are none yet, though.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    placement: "center",
    trans: "xxpr3e5",
  },
  {
    anchor: "myfirstchapter",
    target: "#xchaptersbutton",
    content:
      "Click here to add the first chapter. (Or close this dialog to stop.)",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "xxpr4e6",
  },
  {
    target: "#promptbody",
    content: "Set the chapter title and optionally a subtitle.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    trans: "xxpr5e7",
  },
  {
    target: "#xpromptbutton",
    content: "Click Add to create the chapter.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    cont: true,
    hideBackButton: true,
    goto: "chaptershow",
    trans: "xxpr6e8",
  },

  {
    anchor: "chaptershow",
    target: "#xchaptersbutton",
    content:
      "To simply add a new chapter to the end of the list of your chapters, click on the Create New Chapter button.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "xxpr7e9",
    goto: "somechapters",
  },
  {
    act: "somechapters",
    anchor: "somechapters",
    target: "#tocexamplesexercise",
    content:
      "This is your first chapter. If you click on the chapter title you will see the contents of the chapter.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "xxpr7e10",
  },
  {
    target: "#ellipsisdiv",
    content: "Click here to show the available options regarding this chapter.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "xxpr8ee11",
  },
  {
    target: "#tocexamplesexercise",
    content: "You see some buttons now to add chapters or change them.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "xxpr8e12",
  },
  {
    target: "#addpart0",
    content: "Click here to add a new chapter above this one.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "xxpr9e13",
  },
  {
    target: "#addtitle0",
    content: "Click here to add a new section title above this one.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "xxpr11e15",
  },
  {
    target: "#delete0",
    content:
      "Click here to delete this chapter. (The chapter has to be empty to delete it.)",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "xxpr13e17",
  },
  {
    target: "#addpartdown0",
    content: "Click here to add a new chapter below this one.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "xxpr10e14",
  },

  {
    target: "#addtitledown0",
    content: "Click here to add a new section title below this one.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "xxpr12e16",
  },

  {
    target: "#change0",
    content: "Click here to change the title of this chapter.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "xxpr14e18",
  },
  {
    target: "#tocexamplesexercise",
    content:
      "You can switch around the sequence of chapters by dragging and dropping them.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "xxpr15e19",
  },
  {
    target: "#presentationx",
    content:
      "If this is checked you will view your book as a reader which is useful for testing your book or doing a presentation from you book.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "xxpr16e20",
  },
  {
    target: "#moron",
    content: "And click here if you want to go back to your library.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "xxpr17e21",
  },
  {
    target: "body",

    content:
      "Do you also want to see how to create a part (with actual text, images files etc.) in your chapter?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "gofromchapters",
        question: { trans: "wszsdfwe19", text: "Yes, show me how." },
      },
      {
        newride: "",
        question: { trans: "bub35afc20", text: "No. That's enough for now." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1e22",
    noNext: true,
  },
  {
    anchor: "gofromchapters",
    target: "#tocexamplesexercise",
    content: "Click the title of the chapter you want to add parts to.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "xxpr17e23",
    goto: "dofirstexercise",
  },
];
