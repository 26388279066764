//student
import { myApiMain } from "app/App";
import React, { useState } from "react";
import {
  DollarSign,
  HelpCircle,
  Link2,
  LogOut,
  Mail,
  RefreshCw,
  Settings,
  User,
} from "react-feather";
import { BiMenu } from "react-icons/bi";
import { useSelector } from "react-redux";
// ** Default Avatar Image
// ** React Imports
import { Link, useHistory, useLocation } from "react-router-dom";
// ** Utils
// ** Store & Actions
// ** Third Party Components
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { setHelpOpen } from "redux/reducers/reducer.help";
import { setLastLocation } from "redux/reducers/reducer.lastLocation";
import { RootState } from "redux/reducers/_index";
import store from "redux/store";
import CoverDiv from "utils/CoverDiv";
import { tx } from "utils/globalize";
import { beginsWith, phaseBodyOut, phaseOut } from "utils/utils";
import styled from "styled-components";

export const noUndefined = (a: any) => (a ? a : "");

const HideDiv: any = styled.div`
  @media (max-width: 600px) {
    &&.invis {
      display: none;
    }
  }
  @media (max-width: 525.99px) {
    &&.invissmall {
      display: none;
    }
  }
`;

const MenuDropdown = (props: any) => {
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const currentUserEmail = useSelector<RootState, any>(
    (state) => state.currentUser?.email
  );

  const factoryOpen = useSelector<RootState, any>((state) => state.factoryOpen);
  const helpRandom = useSelector<RootState, any>((state) => state.helpRandom);
  const confirm = useSelector<RootState, any>((state) => state.confirm);
  const help = useSelector<RootState, any>((state) => state.help);
  const hideMenu = useSelector<RootState, any>((state) => state.hideMenu);
  const openClipboard = useSelector<RootState, any>(
    (state) => state.openClipboard
  );

  // const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  const logout = () => {
    if (myApiMain) {
      myApiMain?.tryLogout();
      return;
    }
    phaseBodyOut();
    setTimeout(() => history.push("/login/login"), 150);
  };

  const gotoSettings = () => {
    setOpen(false);
    if (location.pathname !== "/app/settings") {
      phaseOut("xm-already");

      store.dispatch(setLastLocation(location.pathname));
      setTimeout(() => history.push("/app/settings"), 150);
    }
  };

  const myAccount = () => {
    setOpen(false);
    if (location.pathname !== "/app/account") {
      /// phaseOut();

      store.dispatch(setLastLocation(location.pathname));
      setTimeout(() => history.push("/app/account"), 150);
    }
  };

  const myAffiliate = () => {
    setOpen(false);
    if (location.pathname !== "/app/affiliate") {
      /// phaseOut();

      store.dispatch(setLastLocation(location.pathname));
      setTimeout(() => history.push("/app/affiliate"), 150);
    }
  };

  const mySales = () => {
    setOpen(false);
    if (location.pathname !== "/app/sales") {
      /// phaseOut();

      store.dispatch(setLastLocation(location.pathname));
      setTimeout(() => history.push("/app/sales"), 150);
    }
  };

  // const mySales = () => {
  //   setOpen(false);
  //   if (location.pathname !== "/app/sales") {
  //     /// phaseOut();

  //     store.dispatch(setLastLocation(location.pathname));
  //     setTimeout(() => history.push("/app/sales"), 150);
  //   }
  // };

  const reload = () => {
    setOpen(false);
    if (myApiMain) {
      myApiMain.tryRestart();
      return;
    }
    window.location.href = window.location.href + "?rel=" + Date.now() / 2;
  };

  //   const addToVocabulary = (e: any) => {
  //     e.stopPropagation();
  //     props.clickPopup();
  //     store.dispatch(setNewWordOpen(true));
  //   };

  const runFirstSteps = () => {
    store.dispatch(setHelpOpen("this"));
  };

  const sendFeedback = () => {
    setOpen(false);
    window.open("mailto:feedback@inishare.com");
  };

  if (
    !props.id &&
    !factoryOpen &&
    helpRandom.indexOf("tab") &&
    !confirm.open &&
    !confirm.openPrompt
  ) {
    return null;
  }

  if (
    props.id &&
    (factoryOpen ||
      beginsWith(location.pathname, [
        "/login/",
        "/app/assignment",
        "/terms",
        "/privacy",
        "/eula",
        "/affiliate",
        "/sales",
      ]) ||
      helpRandom.indexOf("tab") === 0 ||
      confirm.open ||
      confirm.openPrompt ||
      openClipboard)
  ) {
    return null;
  }

  return (
    <HideDiv
      className={(help.open ? "invis" : "") + (hideMenu ? " invissmall" : "")}
    >
      <Dropdown
        tag="li"
        className="dropdown-user nav-item spec-dropdown drop-inline mainm"
        direction="left"
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        {open ? <CoverDiv clickPopup={() => setOpen(false)} /> : null}
        <DropdownToggle
          className="btn-icon mr-25 nodraggy text-toolbar"
          color="transparent"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          <BiMenu size={24} />
        </DropdownToggle>

        <DropdownMenu right style={{ width: "unset", maxHeight: "500px" }}>
          {currentUserEmail !== "demo@netenglish.com" ? (
            <React.Fragment>
              <DropdownItem onClick={gotoSettings} tag={Link} to="#">
                <Settings size={14} className="mr-75" />
                <span className="align-middle">
                  {tx("u8dHcxafSiqOUqoG", "Settings", "nbsp")}
                </span>
              </DropdownItem>
              <DropdownItem onClick={myAccount} tag={Link} to="#">
                <User size={14} className="mr-75" />
                <span className="align-middle">
                  {tx("UCgiau_iPxkcvLjZ", "My Account", "nbsp")}
                </span>
              </DropdownItem>
              <DropdownItem onClick={mySales} tag={Link} to="#">
                <DollarSign size={14} className="mr-75" />
                <span className="align-middle">
                  {tx("UCgiawewu_iPxkcvLwejZ", "Your Sales", "nbsp")}
                </span>
              </DropdownItem>
              <DropdownItem onClick={myAffiliate} tag={Link} to="#">
                <Link2 size={14} className="mr-75" />
                <span className="align-middle">
                  {tx("UCgiawewu_iPxkcvLjZ", "Affiliate Program", "nbsp")}
                </span>
              </DropdownItem>

              {/* <DropdownItem onClick={mySales} tag={Link} to="#">
          <BiMoney size={14} className="mr-75" />
          <span className="align-middle">
            {tx("UCgiau_iPxkcvweweLjZ", "My Sales", "nbsp")}
          </span>
        </DropdownItem> */}

              <DropdownItem onClick={reload} tag={Link} to="#">
                <RefreshCw size={14} className="mr-75" />
                <span className="align-middle">
                  {tx("iigiLUuPYCQ9i661", "Reload", "nbsp")}
                </span>
              </DropdownItem>
              <DropdownItem onClick={logout} tag={Link} to="#">
                <LogOut size={14} className="mr-75" />
                <span className="align-middle">
                  {tx("eqcOiGG7keibiyeC", "Log Out", "nbsp")}
                </span>
              </DropdownItem>
              <DropdownItem divider />
            </React.Fragment>
          ) : null}
          <DropdownItem onClick={runFirstSteps} tag={Link} to="#">
            <HelpCircle size={14} className="mr-75" />
            <span className="align-middle">
              {tx("eqcOiGG7keibweweidyeC", "Help", "nbsp")}
            </span>
          </DropdownItem>
          {currentUserEmail !== "demo@netenglish.com" ? (
            <DropdownItem onClick={sendFeedback} tag={Link} to="#">
              <Mail size={14} className="mr-75" />
              <span className="align-middle">
                {tx("eqcOiGG7keibweweiwedyeC", "Feedback", "nbsp")}
              </span>
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </Dropdown>
    </HideDiv>
  );
};

export default MenuDropdown;
