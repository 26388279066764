export const dobooknochapters = [
  {
    anchor: "firstnochapters",
    act: "firstnochapters",
    target: "#createnewbook",
    content: "Click here to create your new book.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    continuous: false,
    noNext: true,
    trans: "dbmx1h1",
  },
  {
    target: "#newbooktitle",
    content: "Add a title for your book.",
    disableBeacon: true,
    spotlightClicks: true,
    xtrafocus: true,
    hideBackButton: true,
    required: true,
    trans: "dbmx2h2",
  },
  // {
  //   target: "#newbooksubtitle",
  //   content: "Add a subtitle to your book (you can leave it empty).",
  //   disableBeacon: true,
  //   spotlightClicks: true,
  //   xtrafocus: true,
  // },
  {
    target: "#newbookauthor",
    content:
      "Specify the author. This is you by default but you can change it.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    xtrafocus: true,
    required: true,
    trans: "dbmx3h3",
  },
  {
    target: "#newbookchapters",
    content:
      "Here you can decide whether you want to divide your book into chapters or just have a list of parts. By default there are no chapters. Opening a book opens the list of its parts straight. In this demo we create a book without chapters.",
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: false,
    trans: "dbmx3h4",
  },
  // {
  //   target: "#newbookinfo",
  //   content: "Add a subtitle to your book (you can leave it empty).",
  //   disableBeacon: true,
  //   spotlightClicks: true,
  //   xtrafocus: true,
  // },
  {
    target: "#xxcoverdiv",
    content:
      "You can create an individual cover here which is described in a separate tour.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "dbmx4h5",
  },
  {
    target: "#createnewbookbutton",
    anchor: "bookcreate",
    content:
      "Click here to actually create the new book, then wait a few seconds until it's done.",
    disableBeacon: true,
    spotlightClicks: true,
    continuous: false,
    noNext: true,
    hideBackButton: true,
    trans: "dbmx5h6",
  },
  {
    target: "#bbbook0",
    content:
      "Your new book has just been created. Click on its title to open it.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "dbmx6h7",
  },
  {
    target: "body",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content:
      "Here you will see the parts (or materials) in the book. There are none yet, though.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "dbmx7h8",
  },
  {
    target: "#moronexe",
    content: "If you click here you can go back to your library.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "dbmx7h9",
  },
  {
    target: "body",

    content:
      "Do you also want to see how to create a part (with actual text, images files etc.) in your book?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "dofirstexercise",
        question: { trans: "wszsdfwe26", text: "Yes, show me how." },
      },
      {
        newride: "",
        question: { trans: "bub35afc27", text: "No. That's enough for now." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1h10",
    noNext: true,
  },
];
