import "react-modal-video/css/modal-video.css";
import "./App.css";
import "./App.modals.css";
import "./fluent.css";
import "./App.search.css";
import "./shepherd.css";
import "@core/components/ripple-button";

import "react-semantic-toasts/styles/react-semantic-alert.css";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import "@mobiscroll/react4/dist/css/mobiscroll.min.css";
// import "@core/scss/core.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "animate.css/animate.css";
import "@core/scss/base/plugins/extensions/ext-component-sweet-alerts.scss";
import "./App.window.css";

// import "nouislider/dist/nouislider.css";
import "@core/scss/react/libs/noui-slider/noui-slider.scss";
import "@core/scss/react/libs/toastify/toastify.scss";
import "blockEditor/blockEditorTypes/fixsentence/sentence/textViewer.css";

import React, { Suspense } from "react";
import { SemanticToastContainer } from "react-semantic-toasts";
import { ToastContainer } from "react-toastify";

import "react-contexify/dist/ReactContexify.css";

import AppStart from "./App.authCheck";

import Routing from "./App.routing";
import AppUpdating from "./App.updating";
import UpgradeChecker from "./App.upgradechecker";
import AppUrlListener from "./app.urlListener";
import AppFirstTime from "./routing/App.firstTime";

import SendIntentSetter from "./App.sendIntent";
import ShortCuts from "./App.shortcuts";
// import Counter from "./App.counter";
import GlobalLoader from "./App.globalLoader";

import styled from "styled-components";
import { Button } from "reactstrap";
import TranslationService from "../@translations/TranslationService";
import JoyRideElement from "joyride/joyride";
import JoyrideVideo from "joyride/joyride.video";
import Theming from "./App.theming";

import AppAuthStateHandling from "./App.authStateHanding";
import SearchService from "./App.searchService";

import { StatusBar } from "@capacitor/status-bar";
import HelpMoveModal from "help/helpModal";
import MenuDropdown from "menu/menu";
import { Portal } from "semantic-ui-react";
import MainMenu from "./App.mainmenu";
import ImageDialog from "utils/gallery/imageDialog";

StatusBar.setBackgroundColor({ color: "#2185d0" });

//@ts-ignore

//@ts-ignore

//@ts-ignore
export const ButtonRipple = styled(Button.Ripple)`
  &&&:active {
    background-color: initial;
  }
  &&&:focus {
    background-color: initial;
  }
  &&&.btn-primary:focus {
    background-color: var(--toolbar-background) !important;
  }
`;

const LanguageSetup = React.lazy(
  () => import("../@translations/TranslationService")
);

// import TourSetup from './Tours';

//@ts-ignore
export const myApi = window.myApi;

//@ts-ignore
export const myApiMain = window.myApiMain;

//@ts-ignore
export const myApiView = window.myApiView;

//@ts-ignore
export const myApiHelper = window.myApiHelper;

try {
  // window.screen.orientation.lock("portrait");
} catch (e) {}
export const isTouch = matchMedia("(hover: none)").matches;

// StatusBar.setStyle({ style: Style.Dark });

function App() {
  // const tourOn = useSelector<RootState, any>((state) => state.tourOn);

  // useEffect(() => {
  //   if (!document.location.href.includes("/help")) {
  //     onAuthStateChanged(auth, async (userAuth) => {

  //       if (userAuth) {

  //         const userRef = await getUserProfileDocument(userAuth);

  //         if (userRef?.id) {
  //           console.log(userRef.id);
  //           setupListeners(userRef);
  //         }
  //         if (store.getState().authLoaded ? )
  //       } else {
  //         console.log("getloggedout");
  //         store.dispatch(setCurrentUser(null));
  //         store.dispatch(setAuthLoaded(true));
  //         cleanListeners();
  //       }
  //     });
  //   }
  // }, []);

  // useLayoutEffect(() => {
  //   const element = document.getElementById("dimmerlite") as any;

  //   if (element) {
  //     element.style.transition = "all 0s";
  //     element.classList.add("mfhidden");
  //     element.style.transition = "all 0.15s";
  //   }
  // }, []);

  // useEffect(() => {
  //   signInWithEmail("chattutor@gmail.com", "Ermitage123", true);
  // }, []);

  return (
    <React.Fragment>
      {/* <TourSetup /> */}
      {/* <SaveAnchor /> */}
      <SearchService />
      <AppAuthStateHandling />
      <Theming />
      <TranslationService />
      <UpgradeChecker />

      <AppUrlListener />
      {/* <Marketing /> */}
      <Routing />
      <AppFirstTime />
      <AppStart />
      {/* <Suspense fallback={<div />}>
        <LanguageSetup />
      </Suspense> */}
      {/* <Timer /> */}

      <SemanticToastContainer className={"xm-ontop"} position="bottom-right" />
      <SendIntentSetter />
      <AppUpdating />
      <ShortCuts />
      {/* <Counter /> */}
      <GlobalLoader />
      <JoyRideElement />
      <JoyrideVideo />
      <MainMenu />

      <HelpMoveModal />
      <ToastContainer
        newestOnTop
        enableMultiContainer
        containerId={"default"}
      />
    </React.Fragment>
  );
}

export default App;
