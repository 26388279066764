//student
// Add the Firebase products that you want to use
//tx("asdfasdf1logerr","===Login error")

import {
  setPersistence,
  signInWithEmailAndPassword,
  signOut as signOutOrig,
  browserLocalPersistence,
  browserSessionPersistence,
  initializeAuth,
  indexedDBLocalPersistence,
  GoogleAuthProvider,
  browserPopupRedirectResolver,
} from "firebase/auth";

import { FirebaseAuthentication } from "@pbackup/authentication";

import { initializeApp } from "firebase/app";

import { getStorage } from "firebase/storage";

import { getFunctions } from "firebase/functions";

import { doc, initializeFirestore } from "firebase/firestore";

import { cleanListeners } from "app/App.listeners";
import { removePushRegistration } from "app/App.push";

import { setAuthLoaded } from "redux/reducers/reducer.authLoaded";
import { setChat } from "redux/reducers/reducer.chat";
import { setDictionaryOpen } from "redux/reducers/reducer.dictionaryOpen";
import { setFakeStudent } from "redux/reducers/reducer.fakeStudent";
import { setInviteOpen } from "redux/reducers/reducer.inviteOpen";
import store from "redux/store";
import { tx } from "utils/globalize";
import { setCurrentUser } from "redux/reducers/reducer.currentUser";
import { setMyBooks } from "redux/reducers/reducer.myBooks";
import { helpEditorOpener } from "help/helpEditor.opener";
import { setCurrentBookReducer } from "redux/reducers/reducer.currentBook";
import { baseStyles, baseTheme } from "blockEditor/utils/baseStyles";
import { Capacitor } from "@capacitor/core";
import { setRideSteps } from "redux/reducers/reducer.rideSteps";

type User = any;

// //@ts-ignore
// window["gapi"] = {
//   load: (name: string) => Promise.resolve(),
//   iframes: {
//     getContext: () => {
//       return {
//         iframe: {
//           contentWindow: {
//             postMessage: (message: any) => {
//               console.log("gapi iframe message:", message);
//             },
//           },
//         },
//       };
//     },
//   },
// } as any;

// load firebase

//const idb = window.indexedDB;

const config = {
  apiKey: "AIzaSyAeM0h_gDnVpXIC9jgqe1IeyCQH7loi9HA",
  authDomain: "inisharex.firebaseapp.com",
  projectId: "inisharex",
  storageBucket: "inisharex.appspot.com",
  messagingSenderId: "731451691842",
  appId: "1:731451691842:web:ab99523500cecfd6366edb",
  measurementId: "G-153GPBPXV1",
};

export const firebaseApp = initializeApp(config);

export const auth = Capacitor.isNativePlatform()
  ? initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    })
  : initializeAuth(firebaseApp, {
      persistence: browserLocalPersistence,
      popupRedirectResolver: browserPopupRedirectResolver,
    });

export const firestore = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});

// enableMultiTabIndexedDbPersistence(firestore)
//   .then(() => {})
//   .catch(function (err: any) {
//     if (err.code === "failed-precondition") {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === "unimplemented") {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });

// export const auth = getAuth(firebaseApp);
// firebase.app().auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export interface FreeData {
  [key: string]: any;
}

export const getUserProfileDocument = async (userAuth: User) => {
  if (!userAuth) return;

  const userRef = doc(firestore, `users/${userAuth.uid}`);

  try {
    return userRef;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const signInWithEmail = (
  email: string,
  password: string,
  rememberme: boolean,
  callbacks: FreeData = {}
) => {
  store.dispatch(setAuthLoaded(false));
  store.dispatch(setCurrentUser(null));

  setPersistence(
    auth,
    rememberme
      ? Capacitor.isNativePlatform()
        ? indexedDBLocalPersistence
        : browserLocalPersistence
      : browserSessionPersistence
  )
    .then(function () {
      signInWithEmailAndPassword(auth, email, password)
        .then((res: any) => {
          if (callbacks.success) {
            callbacks.success(res);
          }
        })
        .catch(function (error: any) {
          if (callbacks.failure) {
            callbacks.failure(
              tx("soicxlkkowe", "Login error.") +
                " (" +
                error.code.replace("auth/", "") +
                ")"
            );
          }
        });
    })
    .catch(function (error) {
      callbacks.failure(
        callbacks.failure(
          tx("soicxlkkowe", "Login error.") + " (" + error.code + ")"
        )
      );
    });
};

export const signOut = async () => {
  // removeListeners();
  // removeReaderListener();
  store.dispatch(setRideSteps(""));
  cleanListeners();
  await removePushRegistration(store.getState().currentUser);

  store.dispatch(setAuthLoaded(false));

  store.dispatch(setFakeStudent(false));

  helpEditorOpener("");
  store.dispatch(setDictionaryOpen(false));
  store.dispatch(setInviteOpen(""));
  store.dispatch(setChat([]));
  store.dispatch(setMyBooks({}));
  store.dispatch(
    setCurrentBookReducer({
      basics: {},
      toc: [],
      cats: {},
      content: {},
      styles: baseStyles,
      theme: baseTheme,
    })
  );
  localStorage.setItem("currentUser", "");
  localStorage.setItem("userType", "");
  console.log("signingoutnowhere");
  await signOutOrig(auth);

  // await FirebaseAuthentication.getIdToken({ forceRefresh: true });
  await FirebaseAuthentication.signOut();
  store.dispatch(setAuthLoaded(true));
  // await firebase.auth().signOut();
  console.log("signed out now");
};

// Create a reference with an initial file path and name

export const cloudStorage = getStorage(firebaseApp);
// export const firestore = getFirestore(firebaseApp);

// Create a reference from a Google Cloud Storage URI

// export const auth = auth;

// if (process.env.NODE_ENV === "development") {
//   firebase.functions().useEmulator("localhost", 5001);
// }

export const functions = getFunctions(firebaseApp);

export const GoogleProvider = new GoogleAuthProvider();
GoogleProvider.setCustomParameters({
  prompt: "select_account",
});

// export const functions = firebase.functions();
