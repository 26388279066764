//student
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  CustomInput,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { RootState } from "redux/reducers/_index";
import {
  setConfirmDefault,
  setConfirmOpen,
} from "redux/reducers/reducer.confirm";
import store from "redux/store";

import { tx } from "./globalize";
import { useTeacherStatus } from "./utils";
import { TextArea } from "semantic-ui-react";

import BackgroundSelect from "background/backgroundSelect";
import styled from "styled-components";

const BackgroundDiv = styled.div<any>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: ${(props) => props.background?.o || 0.3};
  background-color: ${(props) => props.background?.background};
  @media (orientation: portrait) {
    background-image: url("${(props) => props.background?.portrait}");
  }
  @media (orientation: landscape) {
    background-image: url("${(props) => props.background?.landscape}");
  }
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  pointer-events: none;
`;

const CustomPrompt = (props: any) => {
  const confirm = useSelector<RootState, any>((state) => state.confirm);
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [checkbox, setCheckbox] = useState();
  const inputRef = useRef<HTMLInputElement>();
  const [myStyle, setMyStyle] = useState<any>({});

  const [, isTeacher] = useTeacherStatus();

  const currentBook = useSelector<RootState, any>((state) => state.currentBook);

  useEffect(() => {
    setCheckbox(props.defaultChecked || false);
  }, [props.defaultChecked]);

  const help = useSelector<RootState, any>((state) => state.help);

  useEffect(() => {
    if (confirm.openPrompt) {
      setValue(confirm.default || "");
      setValue2(confirm.default2 || "");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
      if (confirm.background) {
        console.log(confirm.background);
        setMyStyle({ value: confirm.background, label: confirm.background });
      } else {
        setMyStyle({ value: "default", label: "default" });
      }
    }
  }, [
    confirm.openPrompt,
    confirm.default,
    confirm.default2,
    confirm.background,
  ]);

  const clickCancel = () => {
    store.dispatch(setConfirmOpen(false));
    store.dispatch(setConfirmDefault(""));
    if (confirm.onCancel) {
      confirm.onCancel();
    }
  };

  const clickOk = () => {
    store.dispatch(setConfirmOpen(false));
    store.dispatch(setConfirmDefault(""));

    if (confirm.onConfirm) {
      if (confirm.checkboxText) {
        confirm.onConfirm(value, checkbox);
      } else if (confirm.backgroundShow) {
        confirm.onConfirm(value, value2, myStyle.name);
      } else if (confirm.content2) {
        confirm.onConfirm(value, value2);
      } else {
        confirm.onConfirm(value);
      }
    }
  };

  const onKeydown = (e: any) => {
    if (e.key === "Enter" && !confirm.textarea) {
      clickOk();
    }
  };

  const changeCheckbox = (e: any) => {
    setCheckbox(e.currentTarget.checked);
  };

  const disabled =
    (confirm.valid && !confirm.valid(value)) ||
    (confirm.valid2 && !confirm.valid2(value2)) ||
    (!confirm.valid &&
      !confirm.valid2 &&
      (!value || (confirm.content2 && !value2)));

  const background =
    myStyle.value === "default"
      ? (currentBook.styles || {})[
          "background-" + currentBook?.basics?.background
        ] || {}
      : (currentBook?.styles || {})["background-" + myStyle.value] || {};

  return (
    <React.Fragment>
      <Modal
        isOpen={confirm.openPrompt}
        toggle={clickCancel}
        className={
          "modal-dialog-centered prompter" + (help.open ? " showhelp" : "")
        }
        returnFocusAfterClose={!confirm.noreturn}
      >
        <BackgroundDiv background={background} />
        <ModalHeader toggle={clickCancel}>
          {confirm.header ||
            tx("64nwJUg6iL_EXmtd", "Confirmation...", isTeacher)}
        </ModalHeader>
        <ModalBody className="pt-1" id="promptbody">
          {confirm.content ? (
            <div className="mb-50">
              {confirm.content ||
                tx("PEgiipg_dbm7liZO", "Are you sure?", isTeacher)}
            </div>
          ) : null}
          {confirm.textarea ? (
            <TextArea
              style={{ width: "100%", height: "300px" }}
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              ref={(ref: any) => (inputRef.current = ref)}
              onKeyDown={onKeydown}
              className="mb-1"
            />
          ) : (
            <Input
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              innerRef={(ref: any) => (inputRef.current = ref)}
              onKeyDown={onKeydown}
              className="mb-1"
            />
          )}
          {confirm.content2 ? (
            <React.Fragment>
              <div className="mb-50">
                {confirm.content2 ||
                  tx("PEgiipg_dbm7liZO", "Are you sure?", isTeacher)}
              </div>
              <Input
                value={value2}
                onChange={(e: any) => setValue2(e.target.value)}
                onKeyDown={onKeydown}
                className="mb-1"
              />
            </React.Fragment>
          ) : null}
          {confirm.checkboxText ? (
            <CustomInput
              id="checki"
              type="checkbox"
              name="checki"
              label={confirm.checkboxText}
              checked={checkbox}
              onChange={changeCheckbox}
              className={"mb-1"}
            />
          ) : null}
          {confirm.backgroundShow ? (
            <BackgroundSelect
              myStyle={myStyle}
              setMyStyle={setMyStyle}
              bg={confirm.background}
            />
          ) : null}
        </ModalBody>
        <ModalFooter style={{ zIndex: 0 }}>
          {confirm.cancelButton ? (
            <Button color="secondary" onClick={clickCancel}>
              {confirm.cancelButton || tx("cancel", "Cancel", isTeacher)}
            </Button>
          ) : null}
          {confirm.confirmButton ? (
            <Button
              color="white"
              className="bg-toolbar text-toolbar"
              onClick={clickOk}
              disabled={disabled}
              id="xpromptbutton"
            >
              {confirm.confirmButton || tx("ok", "OK", isTeacher)}
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CustomPrompt;
