import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const clipboardGlobalSlice = createSlice({
  initialState,
  name: "clipboard",
  reducers: {
    setClipboardGlobal: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setClipboardGlobal } = clipboardGlobalSlice.actions;

export default clipboardGlobalSlice.reducer;
