import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = null;

const activeEditorItemSlice = createSlice({
  initialState,
  name: "activeEditorItem",
  reducers: {
    setActiveEditorItem: (state: boolean, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setActiveEditorItem } = activeEditorItemSlice.actions;

export default activeEditorItemSlice.reducer;
