export const bookTypes = [
  {
    act: "switchtosamplefrommine",
    anchor: "switchtosamplefrommine",
    target: "body",
    placement: "center",
    content:
      "You are currently looking at books you created. To show you how to read a book we are going to switch to some sample books.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: false,
    trans: "libdbmx23d28qcwee",
    gosub: "booktypes-switchtosample",
  },
  {
    anchor: "switchtosamplefromothers",
    target: "body",
    placement: "center",
    content:
      "You are currently looking at books others shared with you. To show you how to read a book we are going to switch to some sample books.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: false,
    trans: "libdbmx23d28qe",
    gosub: "booktypes-switchtosample",
  },
  {
    anchor: "switchtosample",
    target: "#bsamples",
    content:
      "Now select Sample Books from the menu to switch to the sample books.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: true,
    trans: "libdbmx23d28ret",
    goto: "howtoread",
  },

  {
    anchor: "booktypes",
    target: "body",
    placement: "center",
    content:
      "There are three types of books in IniShare.<br/>Books created by you.<br/>Books shared with you by others.<br/>And sample books.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: false,
    trans: "libdbmx23wewed28qe",
  },
  {
    target: "#othersdiv",
    content:
      "When you are in your library you can read at the top which books you are looking at.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: false,
    trans: "liwebdbmx23d28ret",
  },
  {
    target: "#bookfilterid",
    content: "To switch between these types first click on the filter button.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: true,
    trans: "libdbmx23d28low",
  },
  {
    target: "#filtermenux",
    content: "You get a little menu with the three options at the top.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: false,
    trans: "libdbmx23d28ak",
    resume: true,
  },
];
