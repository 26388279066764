import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const bookStatusSlice = createSlice({
  initialState: {
    bookId: "",
    status: "books",
    cat: "",
    exercise: "exewewe",
  },
  name: "bookStatus",
  reducers: {
    setBookStatus: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setBookStatusPart: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setBookStatus, setBookStatusPart } = bookStatusSlice.actions;

export default bookStatusSlice.reducer;
