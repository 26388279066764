import { createSlice } from "@reduxjs/toolkit";

const lastLocationSlice = createSlice({
  initialState: "",
  name: "lastLocation",
  reducers: {
    setLastLocation: (state, action) => {
      return action.payload;
    },
  },
});

export const { setLastLocation } = lastLocationSlice.actions;

export default lastLocationSlice.reducer;
