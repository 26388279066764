export const bookintro = [
  {
    act: "bookintrofromothers",
    anchor: "bookintrofromothers",
    target: "body",

    content:
      "In IniShare you can easily create and share books and booklets. But you can also read books and booklets created by others. What would you like to know?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "switchtosamplefromothers",
        question: {
          trans: "wszsdfwe1",
          text: "How to read a book I received?",
        },
      },
      {
        newride: "interactive2",
        question: {
          trans: "wscnmzwesdfwe1",
          text: "Interactive books/courses",
        },
      },
      {
        newride: "createbooksfromothers",
        question: { trans: "bub35afc2", text: "How to create my own book?" },
      },

      {
        newride: "libraryothers",
        question: { trans: "bub35afc3", text: "Just show me around here." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1d3",
    noNext: true,
  },
  {
    act: "bookintrofromsample",
    anchor: "bookintrofromsample",
    target: "body",

    content:
      "In IniShare you can easily create and share books and booklets. But you can also read books and booklets created by others. What would you like to know?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "howtoread",
        question: {
          trans: "wszsdfwe1",
          text: "How to read a book I received?",
        },
      },
      {
        newride: "createbooksfromsample",
        question: { trans: "bub35afc2", text: "How to create my own book?" },
      },
      {
        newride: "librarysample",
        question: { trans: "bub35afc3", text: "Just show me around here." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1d3",
    noNext: true,
  },
  {
    act: "bookintrofrommine",
    anchor: "bookintrofrommine",
    target: "body",

    content:
      "In IniShare you can easily create and share books and booklets. But you can also read books and booklets created by others. What would you like to know?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "createbooksfrommine",
        question: { trans: "bub35afc4", text: "How to create my own book?" },
      },
      {
        newride: "sharemenobooks",

        question: { trans: "bub445", text: "Publish and share a book" },
      },

      {
        newride: "booksettingsnobooks",
        question: {
          trans: "bub4567",
          text: "Delete a book, change its title or cover.",
        },
      },
      {
        newride: "switchtosamplefrommine",
        question: {
          trans: "wszsdfwe8",
          text: "How to read a book I received?",
        },
      },

      {
        newride: "justlibrary",
        question: { trans: "bub35afc9", text: "Just show me around here." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1d4",
    noNext: true,
  },

  {
    act: "sharemenobooks",
    anchor: "sharemenobooks",
    target: "body",

    content:
      "You have no books you created yet, so I can't show you how to share books. You can create a book now if you want to.",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "createbooksfrommine",
        question: { trans: "bub35afc10", text: "Create my first book." },
      },
      {
        question: { trans: "bacciea11", text: "Not now." },
        newride: "",
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1d5",
    noNext: true,
  },
  {
    act: "booksettingsnobooks",
    anchor: "booksettingsnobooks",
    target: "body",

    content:
      "You have no books you created yet, so I can't show you how to change it. You can create a book now if you want to.",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "createbooksfrommine",
        question: { trans: "bub35afc12", text: "Create my first book." },
      },
      {
        question: { trans: "bacciea13", text: "Not now." },
        newride: "",
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1d6",
    noNext: true,
  },

  {
    anchor: "howtoread",
    target: "#othersdiv",
    content:
      "These are sample books here. You know because it says Sample Books at the top.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2asdfd7cmn",
  },
  {
    target: "#bbbook0",
    content:
      "This is an example book about IniShare included with the app, and shared with you by IniShare. To open and read it, click on its title.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbmwex2asdfd8",
  },

  {
    target: "body",
    placement: "center",
    content:
      "The content may take a few seconds to load when you first open a book. Some IniShare books are divided into chapters. Not all. If the book is divided into chapters, the list of chapters now open.",
    disableBeacon: true,
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22d9",
  },
  {
    target: "#tocexamplesexercise",
    content:
      "This is a chapter in this book. To open this chapter click on the title of the chapter.",
    anchor: "readchapterothers",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbwermx22d11",
  },
  {
    target: "body",
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content: "The chapter opens and you can start reading it.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22d12",
  },
  // {
  //   target: "body",
  //   placement: "center",
  //   content: "The chapter opens and you can start reading it.",
  //   styles: {
  //     options: {
  //       overlayColor: "rgba(0, 0, 0, 0.1)",
  //     },
  //   },
  //   disableBeacon: true,
  //   spotlightClicks: false,
  //   noNext: false,
  //   hideBackButton: true,
  //   trans: "libdbwermx22d13",
  // },
  {
    target: "#exerarrow",
    content: "To go back to the list of chapters, click on this arrow.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbwermx22d14",
  },
  {
    target: "#tocexamplesexercise.braka1",
    content: "Now click on the title of this other chapter to open it.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbwermx22d15",
  },
  {
    target: "body",
    content:
      "As you can see, this chapter has several parts so you see a list of those parts here.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22d16",
  },
  {
    target: "#exeme",
    content: "Click on the title of this part to open it.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbwermx22d17",
    goto: "viewer",
  },
  {
    target: "#bookfilterid",
    content:
      "Each chapter can contain more than one part as well. If there's only one part in a chapter it opens directly.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbwermx22d18",
  },
  {
    target: "#bookfilterid",
    content: "To open a part in a chapter click on its title.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbwermx22d19",
  },
  {
    anchor: "rest",
    target: "body",
    placement: "center",
    content:
      "It opens like this. You can read it now. But there are a few important points to know on how to navigate in your book.",
    disableBeacon: true,
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbwermx22d20",
    goto: "",
  },

  {
    anchor: "createbooksfrommine",
    target: "#othersdiv",
    content:
      "First you have to make sure that you are looking at the books YOU created. This is shown here by the Books by Me sign at the top. You can only create new books when you have your own books visible.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2asdfd21",
  },
  {
    target: "#bookfilterid",
    content:
      "This is the filter button. If you are looking at the wrong books you can change it here.",

    disableBeacon: true,

    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx3l4ck",
    goto: "first",
  },

  {
    anchor: "createbooksfromsample",
    target: "#othersdiv",
    content:
      "Here you read Sample Books at the top. This means that you are looking at Sample Books provided by IniShare with the app. To create and edit your own books you need to switch to the books created by you first.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2asdfdwwee22",
    goto: "contcreat",
  },
  {
    anchor: "createbooksfromothers",
    target: "#othersdiv",
    content:
      "Here you read Books by Others at the top. This means that you are looking at books shared with you by others. To create and edit your own books you need to switch to the books created by you first.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2asdfd22",
  },

  {
    anchor: "contcreat",
    target: "#bookfilterid",
    content: "Click to open the filter menu.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbmx25d23",
  },

  {
    target: "#bbyme",
    content: "Click to show books created by YOU.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "libdbmx26424",
  },
  {
    target: "#booklistscroll",
    content: "And here you will see the books created by you.",
    styles: { options: { overlayColor: "rgba(0,0,0,0.5)" } },
    placement: "center",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx27d25",
    goto: "first",
  },

  {
    act: "movetoothersbooks",
    anchor: "movetoothersbooks",
    target: "#othersdiv",
    content:
      "Right now you are looking at books created by you. To read books OTHERS shared with you you first have to switch to those books.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2asdfd26",
  },
  {
    target: "#bookfilterid",
    content:
      "To switch to the books others shared with you click here to open the filter menu.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbmx22d27",
  },

  {
    target: "#bbyothers",
    content: "Click to show books SHARED with you by OTHERS.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: true,
    trans: "libdbmx23d28",
    goto: "howtoread",
  },
];
