import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { open: false, unsaved: [] };

const unsavedSlice = createSlice({
  initialState,
  name: "unsavedGlobal",
  reducers: {
    setUnsaved: (state: any, action: PayloadAction<any>) => {
      state.unsaved = action.payload;
    },
    setUpdateOpen: (state: any, action: PayloadAction<any>) => {
      state.open = action.payload;
    },
  },
});

export const { setUnsaved, setUpdateOpen } = unsavedSlice.actions;

export default unsavedSlice.reducer;
