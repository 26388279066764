import { functions } from "firebaseUtil/firebaseUtil";
import { httpsCallableFromURL } from "firebase/functions";
import store from "redux/store";
import { recurseFirebaseUrl } from "./cloud";

export const ocrThis = async (file: string) => {
  const ocr = httpsCallableFromURL(
    functions,
    "https://selector-okdm2bbxtq-uc.a.run.app"
  );
  let data: any;

  if (file.indexOf("https://firebase")) {
    data = await ocr({
      fn: "ocr1",
      url: file,
      language: store.getState().settings.ocrLanguage || "en-US",
    });
  } else {
    data = await ocr({
      url: "gs://inisharex.appspot.com/" + recurseFirebaseUrl(file),
      language: store.getState().settings.ocrLanguage || "en-US",
    });
  }

  return data?.data?.text || "";
};

export const voiceRecThis = async (file: string) => {
  const voicerec = httpsCallableFromURL(
    functions,
    "https://selector-okdm2bbxtq-uc.a.run.app"
  );

  // let data: any;

  if (file.indexOf("https://firebase")) {
    await voicerec({
      fn: "voicerec",
      url: file,
      language: store.getState().settings.ocrLanguage || "en-US",
    });
  } else {
    await voicerec({
      url: "gs://inisharex.appspot.com/" + recurseFirebaseUrl(file),
      language: store.getState().settings.ocrLanguage || "en-US",
    });
  }
};
