import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  ref: "",
  me: {
    id: "",
    name: "",
    avatar: "",
  },
  you: {
    id: "",
    name: "",
    avatar: "",
  },
};

const userSetupSlice = createSlice({
  initialState,
  name: "userSetup",
  reducers: {
    setUserSetup: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setUserSetup } = userSetupSlice.actions;

export default userSetupSlice.reducer;
