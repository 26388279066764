import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any[] = [];

const catsSlice = createSlice({
  initialState,
  name: "cats",
  reducers: {
    setCats: (state: any[], action: PayloadAction<any[]>) => {
      return action.payload;
    },
  },
});

export const { setCats } = catsSlice.actions;

export default catsSlice.reducer;
