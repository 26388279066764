import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const nCounterSlice = createSlice({
  initialState,
  name: "nCounter",
  reducers: {
    setNCounter: (state: any, action: PayloadAction<any>) => {
      if (state[action.payload.id] === undefined) {
        state[action.payload.id] = 0;
      } else {
        state[action.payload.id] = state[action.payload.id] + 1;
      }
    },
  },
});

export const { setNCounter } = nCounterSlice.actions;

export default nCounterSlice.reducer;
