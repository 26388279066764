import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const clipOpenSlice = createSlice({
  initialState,
  name: "clipOpen",
  reducers: {
    setClipOpen: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setClipOpen } = clipOpenSlice.actions;

export default clipOpenSlice.reducer;
