//student
// import { getProfileUrl } from "account/account.avatar";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "redux/reducers/_index";
import { setBookStatus } from "redux/reducers/reducer.bookStatus";
// import { setAvatars } from "redux/reducers/reducer.avatars";
import { setConfirm } from "redux/reducers/reducer.confirm";
import { setCurrentUser, UserType } from "redux/reducers/reducer.currentUser";
import { setAutoRoute } from "redux/reducers/reducer.routeMe";
import { setTutorial } from "redux/reducers/reducer.tutorial";
import { setUserSetup } from "redux/reducers/reducer.userSetup";
import store from "redux/store";
import { getDriveTokenFromCode, platform } from "utils/drive";
import { tx } from "utils/globalize";
import { canUse } from "utils/permissions";
// import { setUserGlobal } from "utils/setUser";
// import { setInviteOpen } from "redux/reducers/reducer.inviteOpen";
import { beginsWith, phaseBodyOut, useTeacherStatus } from "utils/utils";

import { removePushRegistration, setupPushNotification } from "./App.push";
import { setupAppPay, updateProduct } from "utils/payments";
import { setUserStatus } from "redux/reducers/reducer.userStatus";
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";
import { dateLineListenerSetup } from "./App.listeners";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "firebaseUtil/firebaseUtil";
import { setTempLocal } from "redux/reducers/reducer.tempLocal";
import { setCommenting } from "redux/reducers/reducer.commenting";

function setDocHeight() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );

  document.documentElement.style.setProperty("--vw", `${window.innerWidth}px`);
  if (window.innerHeight > 600) {
    document.documentElement.style.setProperty("--logoHeight", `170px`);
    document.documentElement.style.setProperty("--logoPadding", `25px`);
  } else {
    document.documentElement.style.setProperty("--logoHeight", `135px`);
    document.documentElement.style.setProperty("--logoPadding", `0px`);
  }
}

const checkTutorial = () => {
  if (!store.getState().settings.hideTutorial) {
    store.dispatch(setTutorial(true));
  }
};

let origCurrentUser: any = null;
let origCurrentUser2: any = null;

const AppStart = (props: any) => {
  const location = useLocation();
  const history = useHistory();

  // const posHistory = useRef<any>();

  // const theme = useSelector<RootState, any>((state) => state.settings.theme);

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  const authLoaded = useSelector<RootState, boolean>(
    (state) => state.authLoaded
  );

  const autoRoute = useSelector<RootState, any>((state) => state.autoRoute);

  const userSetup = useSelector<RootState, any>((state) => state.userSetup);

  const [fakeStudent] = useTeacherStatus();

  useEffect(() => {
    const notFirstTime = localStorage.getItem("notfirst");

    if (
      !notFirstTime &&
      !beginsWith(location.pathname, [
        "/login/books",
        "/login/firsttime",
        "/login/login",
        "/login/signup",
        "/login/demo",
        "/impress",
        "/privacy",
        "/terms",
        "/eula",
      ])
    ) {
      // history.push("/login/firsttime");
    } else {
      localStorage.setItem("notfirst", "true");
    }
  }, [history, location]);

  useEffect(() => {
    if (
      !canUse("chat", currentUser?.permissions) &&
      !location.pathname.indexOf("/app/chat")
    ) {
      history.push("/app/library");
    }
  }, [location, history, currentUser?.permissions]);

  // useEffect(() => {
  //   if (
  //     userSetup.bubo &&
  //     !users.list.includes(userSetup.you?.id) &&
  //     userSetup.you?.id !== currentUser?.id &&
  //     userSetup.you?.id !== "testuser" &&
  //     users.list.length &&
  //     location.pathname.includes("/app/chat")
  //   ) {
  //     setUserGlobal(userSetup.origUser || users.list[0], users);
  //   }
  // }, [users, userSetup, currentUser?.id, location.pathname]);

  useEffect(() => {
    if (autoRoute) {
      store.dispatch(setAutoRoute(""));

      history.push(autoRoute);
    }
  }, [autoRoute, history]);

  useEffect(() => {
    const b = location.search;

    if (location.pathname.includes("/app/link")) {
      return;
    }

    const code = decodeURIComponent(
      b?.match(/code=[^&]*/)?.[0].replace("code=", "") || ""
    );
    const state = decodeURIComponent(
      b?.match(/state=[^&]*/)?.[0].replace("state=", "") || ""
    );

    const stateParts = state?.split("xxxxxxxxxxxxxxxxxxxxxxx");

    if (!(stateParts[2] === "library") || !code) {
      localStorage.setItem("tempcustom", "");
    }

    if (b.includes("error=access_denied")) {
      history.push("/app/" + stateParts[2]);
      store.dispatch(
        setConfirm({
          confirmButton: tx("ok", "OK"),
          header: tx("tdpv7yPTImcgNye_", "Permission Denied..."),
          content: (
            <React.Fragment>
              <div>
                Permission was denied. Please try again and make sure you give
                access where requested.
              </div>
            </React.Fragment>
          ),
          open: true,
        })
      );

      return;
    }

    if (code) {
      const pname = location.pathname;

      const p = pname.substr(pname.indexOf("/app/library"));

      localStorage.setItem(
        "dotempcustom",
        localStorage.getItem("tempcustom") || ""
      );

      history.push(p.replace("library", stateParts[2]));

      getDriveTokenFromCode(code, stateParts?.[0] || "", stateParts?.[1] || "");
    }
  }, [location, history]);

  useEffect(() => {
    if (!currentUser) {
      origCurrentUser = "";
    }
  }, [currentUser]);

  useEffect(() => {
    if (userSetup?.ref) {
      const bookResult = onSnapshot(
        doc(firestore, store.getState().userSetup.ref + "/assignments/book"),
        (data) => {
          store.dispatch(
            setTempLocal({ ...(data.data() || {}), loaded: true })
          );
        }
      );

      return () => {
        bookResult();
        store.dispatch(setCommenting(false));
        store.dispatch(setTempLocal({}));
      };
    }
  }, [userSetup?.ref]);

  useEffect(() => {
    const loadSetup = async () => {
      if (currentUser) {
        if (origCurrentUser === currentUser.id) {
          return;
        } else {
          origCurrentUser = store.getState().currentUser?.id;
        }

        const bookStatus = localStorage.getItem(currentUser?.id + "status");

        if (bookStatus) {
          store.dispatch(setBookStatus(JSON.parse(bookStatus)));
        } else {
          store.dispatch(
            setBookStatus({
              bookId: "",
              status: "books",
              cat: "",
              exercise: "exewewe",
            })
          );
        }

        dateLineListenerSetup();
        // chatListenerSetup();
        // studentScheduleListenerSetup();
      } else {
        origCurrentUser = null;
      }
    };

    if (currentUser?.id && userSetup.ref) {
      loadSetup();
    }
  }, [currentUser, userSetup.ref]);

  // const youId = userSetup?.you?.id;
  // const meId = userSetup?.me?.id;

  // useEffect(() => {
  //   const loadAvatars = async () => {
  //     let myAvatar: any;

  //     if (meId !== "testuser") {
  //       myAvatar = currentUser?.pic
  //         ? await getProfileUrl(currentUser?.pic)
  //         : "";
  //     } else {
  //       myAvatar = await getProfileUrl("testuser");
  //     }

  //     const yourAvatar = users[youId + "-p"]
  //       ? await getProfileUrl(users[youId + "-p"])
  //       : store.getState().fakeStudent
  //       ? await getProfileUrl(currentUser?.pic)
  //       : youId === "testuser"
  //       ? await getProfileUrl("testuser")
  //       : "";

  //     store.dispatch(
  //       setAvatars({
  //         myAvatar: myAvatar || "/images/download.png",
  //         yourAvatar: yourAvatar || "/images/download.png",
  //       })
  //     );
  //   };
  //   loadAvatars();
  // }, [currentUser?.pic, youId, meId, users]);

  useEffect(() => {
    // setInterval(checkTutorial, 3600000);
    // setInterval(checkBuyNow, 1800000);
    window.addEventListener("resize", setDocHeight);
    window.addEventListener("orientationchange", setDocHeight);
    setInterval(checkTutorial, 86400000);

    setDocHeight();

    // const setupGoogleAuth = async () => {
    //   let info: any = undefined;

    //   try {
    //     info = await Device.getInfo();
    //   } catch (e) {
    //     console.log(e);
    //   }

    //   if (info.platform !== "web") {
    //     GoogleAuth.init();
    //     return;
    //   }
    // };
    // setupGoogleAuth();
  }, []);

  useEffect(() => {
    if (currentUser?.id && platform !== "web") {
      setupAppPay(currentUser?.id);
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (currentUser?.expiry) {
      // alert("bigi");

      const product = InAppPurchase2.get("inisharemonthly2");
      store.dispatch(setUserStatus(updateProduct(product)));
    }
  }, [currentUser?.expiry, currentUser?.payPlatform, currentUser?.renewal]);

  // useEffect(() => {
  //   if (currentUser?.id) {
  //     loadWords(currentUser);
  //   }
  // }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setupPushNotification(currentUser);
    } else {
      removePushRegistration(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (authLoaded === false) {
      localStorage.setItem("ne-userbackup", "");

      return;
    }

    if (
      !store.getState().currentUser?.drive?.expiry &&
      localStorage.getItem("nex-a")
    ) {
      store.dispatch(
        setCurrentUser({
          ...store.getState().currentUser,
          drive: { expiry: 5555 },
        })
      );
    }

    localStorage.setItem("nex-a", "");

    const data = localStorage.getItem("ne-userbackup");
    if (data) {
      store.dispatch(setUserSetup(JSON.parse(data)));
      localStorage.setItem("ne-userbackup", "");
    }

    if (origCurrentUser2 !== store.getState().currentUser?.id) {
      // console.log("combinedlistener");
      // setupCombinedListener(store.getState().currentUser?.id);
      origCurrentUser2 = store.getState().currentUser?.id;
    }
  }, [fakeStudent, authLoaded, history, location.pathname]);

  // useEffect(() => {
  //   let custom = localStorage.getItem("customized");
  //   let customObj: any = {};

  //   if (!theme && !custom) {
  //     return;
  //   } else {
  //     customObj = theme || JSON.parse(custom || "{}");
  //   }

  //   setCustomStyles(customObj || customized);
  // }, [theme]);

  // useEffect(() => {
  //   if (
  //     location.pathname.includes("/app/chat") &&
  //     location.search.includes("invitee=") &&
  //     authLoaded &&
  //     currentUser?.type === "teacher"
  //   ) {
  //     setTimeout(() => {
  //       // store.dispatch(setInviteOpen(location.search.replace("?invitee=", "")));
  //       // history.push("/app/chat");
  //     }, 1500);
  //   }
  // }, [location, history, authLoaded, currentUser?.type]);

  useEffect(() => {
    if (!authLoaded) {
      return;
    }

    if (beginsWith(location.pathname, "/login/login")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/books")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/signup")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/editor")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/newpw")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/forgotten")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/app")) {
      if (
        currentUser
        // (currentUser.type === "admin" ||
        //   currentUser.type === "contributor" ||
        //   currentUser.type === "voice" ||
        //   currentUser.type === "subadmin")
      ) {
        //
        return;
      } else {
        phaseBodyOut();
        if (location.pathname.includes("/clipper")) {
          setTimeout(() => history.push("/login/login/?loc=clipper"), 150);
        } else {
          setTimeout(() => history.push("/login/login"), 150);
        }
        return;
      }
    }
  }, [location, history, currentUser, authLoaded]);

  return <React.Fragment></React.Fragment>;
};

export default AppStart;
