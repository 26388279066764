export const bookinfo = [
  {
    anchor: "bookcreate",
    target: "body",
    floaterProps: { hideArrow: true },
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content: "You can create a book with this dialog.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "wesdfwgsdghfsghga",
    goto: "booktitle",
  },
  {
    anchor: "bookinfo",
    target: "body",
    floaterProps: { hideArrow: true },
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content: "You can change the basic info of your selected book here.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx1mrcn1",
  },
  {
    anchor: "booktitle",
    target: "#titlexdiv",
    content: "This is the book title. This is mandatory.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx2n2",
  },
  {
    target: "#subtitlexdiv",
    content: "This is the book's subtitle. It is optional.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx3n3",
  },
  {
    target: "#authorxdiv",
    content:
      "This is the book's author. It is mandatory. By default it will be you.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx4n4",
  },
  {
    target: "#infoxdiv",
    content:
      "You an optionally write a short info about the book here which shows up in your library.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx5n5",
  },
  {
    target: "#chapterxdiv",
    content:
      "Here you can set whether the contents of the book are divided into chapters or are in one list.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx5n6",
  },
  {
    anchor: "bookmore",
    target: "#publishedxx",
    content:
      "If your book is not yet published, this is where you can publish it. You should also re-publish it after you made changes to it.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "sharebdbmx6n7",
  },
  {
    target: "#xbooksave",
    content: "With this button you can save your changes.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "sharebdbmx7n8",
  },
  {
    target: "#xbookdelete",
    content:
      "With this button you can delete your book. This is final and cannot be undone.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "sharebdbmx8n9",
  },
  {
    target: "#xbookhide",
    content:
      "With this button you can hide and unhide your book in the list. It will only be visible then if you select Show hidden books from the filter menu in the top left corner.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "sharebdbmx9n10",
  },
  {
    target: "#xxcoverdiv",
    content: "You can also change the cover design here.",
    action: [
      {
        newride: "coverdesign-coverdesignend",
        question: {
          trans: "bub5842",
          text: "Show me how to change the cover.",
        },
      },
      {
        newride: "",
        question: { trans: "bub5943", text: "Not now. Let's just finish." },
      },
    ],
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx10n11",
  },

  {
    act: "bookbasics",
    anchor: "bookbasics",
    target: "body",
    floaterProps: { hideArrow: true },
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content:
      "Here you can see some info about a book somebody shared with you.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx1n1ck",
  },
];

export const bookcreatedlg = [
  {
    act: "bookcreate",
    anchor: "bookcreate",
    target: "body",
    floaterProps: { hideArrow: true },
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content: "You can create a book with this dialog.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx1n1f",
  },
  {
    anchor: "booktitle",
    target: "#newbooktitle",
    content: "This is the book title. This is mandatory.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx2n2f",
  },
  {
    target: "#newbooksubtitle",
    content: "This is the book's subtitle. It is optional.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx3n3f",
  },
  {
    target: "#newbookauthor",
    content:
      "This is the book's author. It is mandatory. By default it will be you.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx4n4f",
  },
  {
    target: "#newbookinfo",
    content:
      "You an optionally write a short info about the book here which shows up in your library.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx5n5f",
  },
  {
    target: "#newbookchapters",
    content:
      "Here you can set whether the contents of the book are divided into chapters or are in one list.",
    disableBeacon: false,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx5n6f",
  },

  {
    target: "#xxcoverdiv",
    content: "You can also change the cover design here.",
    action: [
      {
        newride: "coverdesign-coverdesignend",
        question: {
          trans: "bub5842",
          text: "Show me how to change the cover.",
        },
      },
      {
        newride: "lastcre",
        question: { trans: "bub5943", text: "Not now. Let's just finish." },
      },
    ],
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx10n11",
  },
  {
    anchor: "lastcre",
    target: "#createnewbookbutton",
    content: "With this button you can create your book.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "sharebdbmx7n8f",
  },
];

export const published = [
  {
    act: "shareme",
    target: "#bbbook0",
    content: "This is a book you created earlier.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx11n12",
  },

  {
    target: "#bbinfo0",
    content: "Click here to see the settings of this book.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    spotlightPadding: 0,
    trans: "sharebdbmx12n13",
  },
  {
    actC: "publishing",
    anchorC: "publishing",
    target: "body",
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    floaterProps: { hideArrow: true },
    content:
      "This is where you can publish and share your book. Make sure the title and all the other settings are correct before sharing it.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx13n14",
  },
  {
    target: "#publishedxx",
    content:
      "Here you will see when your book was last published. If you made changes to it since last publishing it is a good idea to press Publish again to give people the latest version.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    if: { var: "published", value: true },
    trans: "sharebdbmx14n15",
  },
  {
    target: "#publishedxx",
    content:
      "Here you will see when your book was last published. In this case your book was never published before so it needs to be published before sharing it.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    if: { var: "published", value: false },
    trans: "sharebdbmx15n16",
  },
  {
    target: "#publishedxx",
    content:
      "Published actually means that it is made available for sharing with others. Whenever you publish a book, the latest version will be seen by your users.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    if: { var: "published", value: false },
    trans: "sharebdbmx15n1sdf6",
  },
  {
    target: "#publishbutt",
    content: "Click here to publish it and wait a few seconds.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    if: { var: "published", value: false },
    trans: "sharebdbmx16n17",
  },
  {
    target: "#qrpoint",
    content:
      "The easiest way to sign up new users is to click on this button. A QR code shows up and if someone follows the link with their camera and types in their email address on their phone while the QR code is visible, they become users of the book.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx16n17ckr",
  },
  {
    target: "#navxx",
    content:
      "Other than that you can use these tabs to share your books in various ways.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx17n18",
  },
  {
    target: "#tabusersx",
    content: "Click on the Users tab.",
    disableBeacon: true,
    spotlightPadding: 0,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "sharebdbmx18n19",
  },
  {
    anchor: "sharemail",
    target: "body",
    floaterProps: { hideArrow: true },
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    placement: "center",
    content:
      "This is the simplest way to share a book with users using their email address.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx19n20",
  },
  {
    target: "#useraddstart",
    content: "Click here to add a new reader.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "sharebdbmx20n21wwe",
  },
  {
    target: "#usernamex",
    content:
      "Type in the e-mail address and the name (optional) of a person you want to share the book with.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx20n21xxfs",
  },
  {
    target: "#useraddbutton",
    content:
      "Press the add button to share the book with this person. It will appear in the person's IniShare book list.",
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: false,
    noNext: false,
    trans: "sharebdbmx21n22nfk",
  },
  {
    target: "#userclosebutton",
    content: "Press the close button now to close this dialog.",
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: true,
    noNext: true,
    trans: "sharebdbmx21n22nfk124",
  },
  {
    target: "#singleuserlist",
    content: "You will see all users you shared the book with here.",
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: false,
    noNext: false,
    trans: "sharebdbmx22n23",
  },

  // {
  //   anchor: "sharemailend",
  //   target: "#tabhookssx",
  //   content: "Click on the Groups tab.",
  //   disableBeacon: true,
  //   spotlightClicks: true,
  //   hideBackButton: true,
  //   noNext: true,
  //   trans: "sharebdbmx23n24",
  // },
  // {
  //   anchor: "sharegroup",
  //   target: "body",
  //   content:
  //     "You can create groups of people you want to share books with from time to time. For example your office people.",
  //   placement: "center",
  //   styles: {
  //     options: {
  //       overlayColor: "rgba(0, 0, 0, 0.1)",
  //     },
  //   },
  //   disableBeacon: true,
  //   spotlightClicks: false,
  //   hideBackButton: true,
  //   noNext: false,
  //   trans: "sharebdbmx24n25",
  // },
  // {
  //   target: "#groupnew",
  //   content:
  //     "Click here to create a new group and provide a name for the group.",
  //   disableBeacon: false,
  //   spotlightClicks: false,
  //   hideBackButton: true,
  //   noNext: false,
  //   trans: "sharebdbmx25n26",
  // },
  // {
  //   target: "#grouplist",
  //   content:
  //     "Type in here the email addresses of people you want to be part of this group. (Type each of them in a new line.)",
  //   disableBeacon: false,
  //   spotlightClicks: false,
  //   hideBackButton: true,
  //   noNext: false,
  //   trans: "sharebdbmx26n27",
  // },
  // {
  //   target: "#groupsave",
  //   content: "Press Save to save data.",
  //   disableBeacon: false,
  //   hideBackButton: true,
  //   spotlightClicks: false,
  //   noNext: false,
  //   trans: "sharebdbmx27n28",
  // },
  // {
  //   target: "#groupassign",
  //   content: "Click here to share the book with all people in this group.",
  //   disableBeacon: false,
  //   spotlightClicks: false,
  //   hideBackButton: true,
  //   noNext: false,
  //   trans: "sharebdbmx28n29",
  // },
  // {
  //   target: "#groupchange",
  //   content:
  //     "And this is where you can select the right group if you have more than one.",
  //   disableBeacon: false,
  //   spotlightClicks: false,
  //   hideBackButton: true,
  //   noNext: false,
  //   trans: "sharebdbmx29n30",
  // },

  {
    anchor: "sharegroupend",
    target: "#tablinksx",
    content: "For the next option Click on the Links tab.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "sharebdbmx30n31",
  },
  {
    target: "#createlinkdiv",
    anchor: "sharelink",
    content:
      "Here you can create links, that you can send out to users and they can use it to sign up for your books.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx31n32",
  },

  {
    target: "#linkadder",
    content: "Click here to add a new link.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "sharebdwebmx31n32",
  },

  {
    target: "#linkname",
    content:
      "Actually type in a name for your link now. Only you will see this name.",
    disableBeacon: true,
    spotlightClicks: true,
    xtrafocus: true,
    required: true,
    hideBackButton: true,
    noNext: false,
    trans: "sharewsbdbmx32n33",
  },
  {
    target: "#maxusers",
    content:
      "Set a maximum number of users who can get access to your book through this link.",
    disableBeacon: true,
    spotlightClicks: true,
    xtrafocus: true,
    required: true,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx33n34",
  },
  {
    target: ".react-datetime-picker",
    content: "Set an expiration date for your link.",
    disableBeacon: true,
    spotlightClicks: true,
    xtrafocus: true,
    hideBackButton: true,
    noNext: false,
    trans: "sharebdbmx34n35",
  },
  {
    target: "#createlinkbutton",
    content: "Press this button to create the link.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "sharebdbmx35n36",
  },
  {
    target: "#linklist0",
    content:
      "And here is the result. You can get the link by pressing the Copy Link button and send it out to your users in a webinar, an email or anything like this.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "sharebdbmx36n37",
  },
  {
    target: "#showqr0",
    content:
      "And press this button to show a QR Code which your users can just photo and sign up for your book right away.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "sharebdbmx37n38",
  },
  {
    target: "#qrmod .modal-content",
    content: "And the QR Code.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "sharebdbmx38n39",
  },
  {
    target: "#modhead button.close",
    content: "Click here to close the QR Code.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "sharebdbmx39n40",
  },
  {
    lastone: true,
    floaterProps: { hideArrow: true },
    placement: "center",
    target: "body",
    content: "And that's how you can share through links.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "sharebdbmx39nwew40",
  },
];
