import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState = {
  exercises: {},
};

const exercisesSlice = createSlice({
  initialState: initialState as any,
  name: "exercises",
  reducers: {
    setExercises: (state, action) => {
      return action.payload;
    },
    setExerciseComplete: (state, action) => {
      state.exercises[action.payload.id] = Date.now();
    },
  },
});

export const { setExercises, setExerciseComplete } = exercisesSlice.actions;

export default exercisesSlice.reducer;
