import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const turnDirectionSlice = createSlice({
  initialState,
  name: "turnDirection",
  reducers: {
    setTurnDirection: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setTurnDirection } = turnDirectionSlice.actions;

export default turnDirectionSlice.reducer;
