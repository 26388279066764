export const dolibraryminefirst = [
  {
    act: "libraryminefirst",
    target: "body",
    content: "What would you like to know?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "justlibrary",
        question: { trans: "bub3435", text: "Just show me around." },
      },
      {
        newride: "first",
        question: { trans: "bub3536", text: "Show me how to create a book." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1l1",
  },

  {
    anchor: "justlibrary",

    target: "#othersdiv",
    content:
      "There are books others shared with you and there are books you created. This is where you see which books you are looking at now.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2asdfl3",
  },
  {
    target: "#bookfilterid",
    content:
      "This is the filter button. You can click on it, and select here whether you want to see the books YOU created or the books OTHERS shared with you.",

    disableBeacon: true,

    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx3l4",
  },
  {
    target: "#booklistscroll",
    placement: "bottom",
    content:
      "This is the list of the books YOU created. You can create new books, edit them and share them.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2l2",
  },
];

export const coverdesign = [
  {
    anchor: "coverdesign",
    act: "coverdesign",
    target: "#xxcoverdiv",
    content:
      "This is how our cover will look like and where we can design a new cover.<br/>The title and the author is automatically set by the actual title and author.",
    placement: "bottom",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx4l5",
  },
  {
    target: "#xxeditcover",
    content: "Click on the Edit cover link to edit your cover.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "libdbmx5l6",
  },
  {
    anchor: "coverdesignerin",
    target: "#covermodal #xxcovertools",
    content: "You can change most settings here.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx6l7",
  },
  {
    target: "#covermodal #titlec",
    content:
      "Here you can change the font used for the title, and you can set its color by clicking on the square, and with the checkbox you can turn off the title completely.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx6l7a",
  },
  {
    target: "#covermodal #covtitleslider",
    content:
      "Here you can change the intensity of the background behind the title, its color, and whether it's shown at all.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx6l7b",
  },
  {
    target: "#covermodal #authorcsd",
    content:
      "Here you can change the font used for the author, and you can set its color by clicking on the square, and with the checkbox you can turn off the author field completely.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx6l7c",
  },
  {
    target: "#covermodal #covauthorslider",
    content:
      "Here you can change the intensity of the background behind the author, its color, and whether it's shown at all.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx6l7d",
  },
  {
    target: "#covermodal #xxselectbg",
    content: "You can select another background from some built-in pictures.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx7l8",
  },
  {
    target: "#covermodal #xxuseown",
    content: "You can also use your own picture by clicking here.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx8l9",
  },
  {
    target: "#covermodal #bookcover",
    content:
      "Or you can drag and drop a background here, or even create a cover with another program and drag and drop here (in that case you would turn off the title and the author fields.)",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx9l10w",
  },
  {
    anchor: "coverdesignend",
    target: "#covermodal #xxsavecover",
    content: "Save your changes by clicking here now.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "libdbmx10l11",
  },
  {
    target: "#covermodal button.close",
    content: "Now close this dialog by clicking on the close button.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    goto: "bookcreate",
    trans: "libdbmx10al12",
  },
  {
    target: "#xxeditcover",
    content: "Click on the Edit cover link to edit your cover.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "libdbmx11l13",
  },

  {
    act: "any",
    target: "#xxcovertools",
    content: "You can change most settings here.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    cont: true,
    trans: "libdbmx12l14",
  },
];

export const dolibrarymine = [
  {
    act: "librarymine",
    target: "html",
    content: "What would you like to know?",
    placement: "center",
    floaterProps: { hideArrow: true, placement: "center" },
    disableSpotlight: true,
    disableOverlay: false,
    disableBeacon: true,
    styles: { options: { overlayColor: "rgba(0,0,0,0.5)" } },
    action: [
      {
        newride: "youbook",
        question: {
          trans: "bub4237",
          text: "Just show me around on this screen.",
        },
      },
      {
        newride: "first",
        question: { trans: "bub4338", text: "Show me how to create a book." },
      },
    ],
    noNext: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx13l15",
  },
  {
    anchor: "youbook",
    target: "#booklistscroll",
    placement: "center",
    content:
      "This is the list of the books YOU created. You can create new books, edit them and share them.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx14l16",
  },

  {
    target: "#bookfilterid",
    content:
      "This is the filter button. You can click on it, and select here whether you want to see the books YOU created or the books OTHERS shared with you.",

    disableBeacon: true,
    action: [
      {
        newride: "selectit",
        question: { trans: "bub5139", text: "Switch to Books by Others?" },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx15l17",
  },
  {
    target: "#bbbook0",
    content:
      "This is a book you created earlier. If you click on its title you can open and edit the book.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx16l18",
  },
  {
    target: "#bbinfo0",
    content:
      "If you click here you can see and edit the general info about the book. Should we have a look at that?",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    action: [
      {
        newride: "",
        question: { trans: "bub55csd40", text: "Not now. It's enough." },
      },
      {
        newride: "booknowinfo",
        question: {
          trans: "bweub55csd41",
          text: "Show me the book info part.",
        },
      },
    ],
    noNext: true,
    trans: "libdbmx17ckel19",
  },
  {
    anchor: "booknowinfo",
    target: "#bbinfo0",
    content: "Click here to open and edit the book information panel.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,

    noNext: true,
    trans: "libdbmx17wewel20",
    goto: "bookinfo",
  },
];

export const dolibraryothers = [
  {
    anchor: "librarysample",
    act: "librarysample",
    target: "#booklistscroll",
    placement: "center",
    content:
      "This is the list of sample books you get with IniShare. You can read these books, you cannot edit them.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdwwewbwemx18l21",
    goto: "nextp",
  },
  {
    anchor: "libraryothers",
    act: "libraryothers",
    target: "#booklistscroll",
    placement: "center",
    content:
      "This is the list of the books OTHERS shared with you. You can read these books, you cannot edit them.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx18l21",
  },

  {
    anchor: "nextp",
    target: "#bookfilterid",
    content:
      "This is the filter button. You can click on it, and select here whether you want to see the books YOU created or the books OTHERS shared with you.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx19l22",
  },
  {
    target: "#bbbook0",
    content:
      "This is a book shared with you. If you click on its title you can open and read the book.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx20l23",
  },
  {
    target: "#bbinfo0",
    content:
      "If you click here you can see the general info about the book. You can also delete it from your library.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libdbmx21l24",
  },
  // {
  //   complete: true,
  //   target: "#bookfilterid",
  //   content:
  //     "If you want to find out about the books YOU created, close this tutorial, click on this filter button, select Books by Me and start this tutorial again. Otherwise press Next.",
  //   disableBeacon: true,
  //   spotlightClicks: false,
  //   hideBackButton: true,
  // },
];

export const selectit = [
  {
    act: "selectit",
    target: "#bookfilterid",
    content: "Click to open the filter menu.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbmx22l25",
  },

  {
    target: "#bbyothers",
    content: "Click to show books SHARED with you by OTHERS.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "libdbmx23l26",
  },
  {
    target: "#booklistscroll",
    content: "And here you will see the books shared with you by others.",
    placement: "center",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx24l27",
  },
];

export const selectitmine = [
  {
    act: "selectitmine",
    target: "#bookfilterid",
    content: "Click to open the filter menu.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbmx25l28",
  },

  {
    target: "#bbyme",
    content: "Click to show books created by YOU.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "libdbmx26l29",
  },
  {
    target: "#booklistscroll",
    content: "And here you will see the books created by you.",
    styles: { options: { overlayColor: "rgba(0,0,0,0.5)" } },
    placement: "center",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx27l30",
  },
];
