import React, { Suspense, useEffect, useState } from "react";
import styled from "styled-components";
//@ts-ignore
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
import { tx } from "utils/globalize";
import { X } from "react-feather";
import store from "redux/store";
import { setHelpOpen } from "redux/reducers/reducer.help";
import { Portal } from "semantic-ui-react";

const HelpContext = React.lazy(() => import("./helpContext"));

const HelpDiv = styled.div`
  position: relative;
  background: white;
  opacity: 0;
  transition: 0.2s;
  z-index: -1000;
  border: 1px solid lightgrey;

  margin-top: 0px;
  margin-bottom: 0px;

  &&.showmenow {
    opacity: 1;
    display: block;
    z-index: 100000;
  }
`;

const WrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
`;

const HeadDiv = styled.div`
  flex: 0 0 30px;
  background: var(--toolbar-background);
  opacity: 0.8;
  padding-top: 3px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  @media (max-width: 600px) {
    flex: 0 0 50px;
    padding-top: 12px;
    font-size: 18px;
    opacity: 1;
  }
`;

const CloseDiv = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0px;
  right: 10px;
  padding-top: 3px;
  padding-right: 12px;
  color: white;
  @media (max-width: 600px) {
    padding-top: 12px;
  }
`;

const SpreadDiv = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
`;

const getPos = () => {
  let pos: any = {};
  const width = document.body.clientWidth;
  const height = document.body.clientHeight;

  if (width > 600 && width < 1100) {
    pos = { x: width - 400, y: 65, height: height - 110, width: 400 };
    if (store.getState().factoryOpen) {
      pos.y = height * 0.025;
      pos.height = height * 0.95;
    }
  }

  if (width >= 1100) {
    pos = { x: 805, y: 65, height: height - 110, width: width - 810 };

    if (store.getState().factoryOpen) {
      pos.y = height * 0.025;
      pos.height = height * 0.95;
    }
  }

  //   if (width <= 800 && width > 600) {
  //     const dlgWidth = width;
  //     pos = {
  //       x: 0,
  //       y: 0,
  //       height: height,
  //       width: dlgWidth,
  //     };
  //   }

  if (width <= 600) {
    const dlgWidth = width;
    pos = {
      x: 0,
      y: 0,
      height: height - 30,
      width: dlgWidth,
    };
  }

  return pos;
};

const HelpMoveModal = (props: any) => {
  const help = useSelector<RootState, any>((state) => state.help);
  const factoryOpen = useSelector<RootState, any>((state) => state.factoryOpen);

  const [init, setInit] = useState(false);

  useEffect(() => {
    if (help.open) {
      setInit(true);
    }
  }, [help.open]);

  useEffect(() => {
    const pos = getPos();
    setTimeout(() => {
      const modal = document.querySelector("#helpid") as HTMLElement;

      if (modal) {
        modal.style.width = pos.width + "px";
        modal.style.height = pos.height + "px";
        modal.style.top = pos.y + "px";
        modal.style.left = pos.x + "px";
      }
    }, 0);
  }, [help.open, factoryOpen]);

  useEffect(() => {
    try {
      var a = ResizeObserver;
    } catch (e) {
      return;
    }

    const resize_ob = new ResizeObserver(function (entries) {
      // since we are observing only a single element, so we access the first element in entries array

      const pos = getPos();

      setTimeout(() => {
        const modal = document.querySelector("#helpid") as HTMLElement;

        if (modal) {
          modal.style.width = pos.width + "px";
          modal.style.height = pos.height + "px";
          if ((pos.y + "").includes("env")) {
            modal.style.top = pos.y;
          } else {
            modal.style.top = pos.y + "px";
          }
          modal.style.left = pos.x + "px";
        }
      }, 0);
    });

    resize_ob.observe(document.body);

    return () => resize_ob.unobserve(document.body);
  }, []);

  // const showHelp = () => {
  //   store.dispatch(setHelpOpen(""));

  //   if (store.getState().factoryOpen) {
  //     setTimeout(() => {
  //       store.dispatch(setRideSteps("creator"));
  //       store.dispatch(
  //         setPointer(steps.findIndex((item: any) => item.act === "creator"))
  //       );
  //     }, 200);

  //     return;
  //   }

  //   const level1 = document.getElementById("superhelp");
  //   const level2 = document.getElementById("extrahelp");
  //   const level3 = document.getElementById("mainhelp");

  //   (level1 || level2 || level3)?.click();
  // };

  const closeDlg = () => {
    store.dispatch(setHelpOpen(""));
  };

  return (
    <React.Fragment>
      <Portal mountNode={document.body} open={true}>
        <HelpDiv id="helpid" className={help.open ? "showmenow" : ""}>
          <WrapDiv>
            <HeadDiv>
              {tx("inimanck", "IniShare Help")}
              <CloseDiv onClick={closeDlg}>
                <X />
              </CloseDiv>
            </HeadDiv>
            <SpreadDiv>
              {init ? (
                <Suspense fallback={<div />}>
                  <HelpContext />
                </Suspense>
              ) : null}
            </SpreadDiv>
          </WrapDiv>
        </HelpDiv>
      </Portal>
    </React.Fragment>
  );
};

export default HelpMoveModal;
