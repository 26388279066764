export const interact = [
  {
    act: "interactive2",
    anchor: "interactive2",
    target: "body",
    placement: "center",
    content:
      "Each book functions like a course and you can mark various parts completed and track your progress.",
    video: "r0pMsirN14o",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: false,
    trans: "liwebdbmx23d03e28qcwee",
  },
  {
    target: "body",
    placement: "center",
    content:
      "There can be interactive elements the reader can communicate with and their answers will be remembered.",
    video: "v1zLdMNGBBE",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: false,
    trans: "liwebdbmx23wed0we3e28qcwewee",
  },
];
