import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = {};

const thisExerciseSlice = createSlice({
  initialState: initialState as any,
  name: "thisExercise",
  reducers: {
    setThisExercise: (state, action) => {
      return action.payload;
    },
  },
});

export const { setThisExercise } = thisExerciseSlice.actions;

export default thisExerciseSlice.reducer;
