import { arrayUnion, doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "firebaseUtil/firebaseUtil";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { setClipOpen } from "redux/reducers/reducer.clipOpen";
import { setConfirm } from "redux/reducers/reducer.confirm";
import { RootState } from "redux/reducers/_index";
import store from "redux/store";
import { tx } from "utils/globalize";
import ClipBoardItem from "./clipboard.itemInput";

const ClipboardDialog = (props: any) => {
  const [data, setData] = useState({
    linkx: "",
    extrax: "",
    infox: "",
    img: "https://cdn.pixabay.com/photo/2016/11/09/16/24/virus-1812092__340.jpg",
  });
  const [activeClipboard, setActiveClipboard] = useState("default");
  const [view, setView] = useState(false);

  const [clipboardCategories, setClipboardCategories] = useState<any>([]);
  const open = useSelector<RootState, any>((state) => state.clipOpen);

  const setOpen = (b: any) => {
    store.dispatch(setClipOpen(b));
  };

  const loadClipboard = async () => {
    const newList = await getDoc(
      doc(
        firestore,
        "/users/" + store.getState().currentUser?.id + "/data/clipboard"
      )
    );

    let tempClipboard = Object.keys(newList.data() || {});

    if (!tempClipboard.includes("default")) {
      tempClipboard.push("default");
    }

    setClipboardCategories(tempClipboard);
  };

  useEffect(() => {
    if (open) {
      loadClipboard();
      const currentClipboard = localStorage.getItem(
        "ne-" + store.getState().currentUser?.id + "-clipactive"
      );
      setActiveClipboard(currentClipboard || "default");
    }
  }, [open]);

  useEffect(() => {
    const currentClipboard = localStorage.getItem(
      "ne-" + store.getState().currentUser?.id + "-clipactive"
    );
    setActiveClipboard(currentClipboard || "default");
  }, []);

  const onChangeClipboard = (e: any) => {
    setActiveClipboard(e.target.value);
  };

  const changeData = (e: any) => {
    setData((c: any) => ({ ...c, [e.target.name]: e.target.value }));
  };

  const addItem = async () => {
    await setDoc(
      doc(
        firestore,
        "/users/" + store.getState().currentUser?.id + "/data/clipboard"
      ),
      {
        [activeClipboard]: arrayUnion({
          ...data,
          date: Date.now(),
        }),
      },
      { merge: true }
    );
  };

  const switchView = () => {
    setView(!view);
  };

  const newClipboardAction = useCallback(async (clip: string) => {
    clip = clip.trim();

    setClipboardCategories((c: any) => [...c, encodeURIComponent(clip)]);
    await setDoc(
      doc(
        firestore,
        "/users/" + store.getState().currentUser?.id + "/data/clipboard"
      ),
      { [encodeURIComponent(clip)]: [] },
      { merge: true }
    );

    localStorage.setItem(
      "ne-" + store.getState().currentUser?.id + "-clipactive",
      encodeURIComponent(clip)
    );

    setActiveClipboard(encodeURIComponent(clip));
  }, []);

  const newClipboard = useCallback(() => {
    store.dispatch(
      setConfirm({
        header: tx("cwerewakklswewwewewecase66", "New Clipboard..."),
        confirmButton: tx("add", "Add"),
        onConfirm: newClipboardAction,
        cancelButton: tx("cancel", "Cancel"),
        content: tx("assdoowewwex2wwewrereswe834", "New clipboard name:"),
        openPrompt: true,
        default: "",
      })
    );
  }, [newClipboardAction]);

  return (
    <React.Fragment>
      <Modal
        isOpen={open}
        toggle={() => setOpen(false)}
        className={"modal-dialog-centered item.modalClass"}
      >
        <ModalHeader toggle={() => setOpen(false)}>
          {tx("rese3434ccopyadd", "Add to Clipboard")}
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "calc(100% - 100px)", overflowY: "auto" }}
        >
          <ClipBoardItem
            data={data}
            activeClipboard={activeClipboard}
            onChangeClipboard={onChangeClipboard}
            changeData={changeData}
            clipboardCategories={clipboardCategories}
            newClipboard={newClipboard}
            setData={setData}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ float: "right" }}
            color="primary"
            onClick={switchView}
            outline
          >
            {tx("rese3434ccopywait", "Switch")}
          </Button>
          <Button
            style={{ float: "right" }}
            color="primary"
            onClick={addItem}
            outline
          >
            {tx("add", "Add")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ClipboardDialog;
