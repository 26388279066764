import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = "";

const datelineLoadedSlice = createSlice({
  initialState,
  name: "datelineLoaded",
  reducers: {
    setDatelineLoaded: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setDatelineLoaded } = datelineLoadedSlice.actions;

export default datelineLoadedSlice.reducer;
