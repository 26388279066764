import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = null;

const tourSlice = createSlice({
  initialState,
  name: "tour",
  reducers: {
    setTour: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setTour } = tourSlice.actions;

export default tourSlice.reducer;
