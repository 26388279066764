import { createSlice } from "@reduxjs/toolkit";

// ** Initial State

const extPointerSlice = createSlice({
  initialState: -1,
  name: "extPointer",
  reducers: {
    setExtPointer: (state, action) => {
      return action.payload;
    },
  },
});

export const { setExtPointer } = extPointerSlice.actions;

export default extPointerSlice.reducer;
