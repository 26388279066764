import HowlTour from "app/howls/howlnow";
import LegalStandAlone from "app/legal/legal.standalone";
import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootState } from "redux/reducers/_index";
import { setHelpHeader } from "redux/reducers/reducer.help";
import store from "redux/store";
// import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import CustomConfirm from "utils/Confirm";
import FunctionRunner from "utils/FunctionRunner";
import CustomPrompt from "utils/Prompt";
import ClipboardDialog from "clipboard/clipboard.dialog";
import HelpModalContent from "help/helpEditor.content";
import CustomPrompt2 from "utils/Prompt2";
import MailFinder from "emailer/emailer";

const AppUi = React.lazy(() => import("./routing/ui.app"));
const LoginUi = React.lazy(() => import("./routing/ui.login"));
// const Privacy = React.lazy(() => import("legal/legal.privacy"));
// const Terms = React.lazy(() => import("legal/legal.terms"));

// child matches will...

const LoaderDiv = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const Routing = () => {
  const helpOpen = useSelector<RootState, any>((state) => state.help.open);

  const confirmOpen = useSelector<RootState, any>(
    (state) => state.confirm.open
  );
  const confirmPrompt = useSelector<RootState, any>(
    (state) => state.confirm.openPrompt
  );

  useEffect(() => {
    if (!helpOpen) {
      store.dispatch(setHelpHeader(""));
    }
  }, [helpOpen]);

  return (
    <React.Fragment>
      {/* <HelpModal /> */}
      {/* <DriveDialog /> */}
      <HowlTour />
      <LoaderDiv>{/* <Loader id="loaderdiv" active={true} /> */}</LoaderDiv>
      <Switch>
        <Route path="/frunner">
          <FunctionRunner />
        </Route>
        <Route path="/fmailer">
          <MailFinder />
        </Route>

        <Route path="/login">
          <Suspense fallback={<div />}>
            <LoginUi />
          </Suspense>
        </Route>
        <Route path="/app">
          <Suspense fallback={<div />}>
            <AppUi />
          </Suspense>
        </Route>
        <Route path="/help/help">
          <div
            style={{
              background: "white",
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <HelpModalContent />
          </div>
        </Route>
        <Route path="/privacy">
          <LegalStandAlone doc="privacy" />
        </Route>
        <Route path="/terms">
          <LegalStandAlone doc="terms" />
        </Route>
        <Route path="/eula">
          <LegalStandAlone doc="eula" />
        </Route>
        <Route path="/impress">
          <LegalStandAlone doc="impress" />
        </Route>
        <Route path="/">
          <Redirect to="/app/library" />
        </Route>
      </Switch>
      {confirmOpen || confirmPrompt ? <CustomConfirm /> : null}
      <ClipboardDialog />
      <CustomPrompt />
      <CustomPrompt2 />

      {/*<BaseDialog />
      <FullDialog />*/}
    </React.Fragment>
  );
};

export default Routing;

// <div className={classes.backdrop} />
