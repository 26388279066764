import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = null;

const selectionSlice = createSlice({
  initialState,
  name: "selection",
  reducers: {
    setSelection: (state: boolean, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setSelection } = selectionSlice.actions;

export default selectionSlice.reducer;
