import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = 0;

const chatCountSlice = createSlice({
  initialState,
  name: "chatCount",
  reducers: {
    setChatCount: (state, action) => {
      return action.payload;
    },
  },
});

export const { setChatCount } = chatCountSlice.actions;

export default chatCountSlice.reducer;
