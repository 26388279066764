//nocheck

import {
  HelpDiv,
  HelpHeaderDiv,
  HelpTextDiv,
} from "app/help/topics/topic.complete";
import { auth, functions } from "firebaseUtil/firebaseUtil";
import React from "react";
import { setConfirm } from "redux/reducers/reducer.confirm";
import { setCurrentUser } from "redux/reducers/reducer.currentUser";
import store from "redux/store";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import { tx, tz } from "utils/globalize";

import { Http as WebHTTP, HttpResponse } from "@capacitor-community/http";
import { Browser } from "@capacitor/browser";
import { Device } from "@capacitor/device";
// import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { HTTP as MobileHTTP } from "@ionic-native/http";
import { setDriveOpen } from "redux/reducers/reducer.driveOpen";
import { httpsCallable } from "firebase/functions";

const BDiv = styled.div`
  text-align: right;
`;

export let platform: any = "web";

try {
  Device.getInfo().then((i: any) => (platform = i.platform));
} catch (e) {
  console.log(e);
}

export const driveFullSignIn = async (wh = "library") => {
  store.dispatch(setDriveOpen(false));

  const token = await auth.currentUser?.getIdToken();

  const googleIt = httpsCallable(functions, "googleIt");

  if (platform === "android") {
    // const res = await GoogleAuth.signIn();

    // ("asd", res);

    const token = (await auth.currentUser?.getIdToken()) || "";

    const f: any = await googleIt({
      authorization: token,
      uid: store.getState().currentUser?.id,
      // code: res.serverAuthCode,
      ios: false,
    });

    if (f.data.error === "noscope") {
      store.dispatch(
        setConfirm({
          confirmButton: tx("ok", "OK"),
          header: tx("tdpv7yPTImcgNye_", "Permission Denied..."),
          content: (
            <React.Fragment>
              <div>
                Permission was denied. Please try again and make sure you give
                access where requested.
              </div>
            </React.Fragment>
          ),
          open: true,
        })
      );
      return;
    }

    store.dispatch(
      setCurrentUser({
        ...store.getState().currentUser,
        drive: {
          ...f.data,
        },
      })
    );

    return;
  }

  // ios

  if (platform === "ios") {
    let res1: any = undefined;

    // res1 = await GoogleAuth.signIn();

    const token = (await auth.currentUser?.getIdToken()) || "";

    await googleIt({
      authorization: token,
      expiry: Date.now() + 20000,
      access_token: res1.authentication.accessToken,
      refresh_token: res1.authentication.refreshToken,
      uid: store.getState()?.currentUser?.id,
      ios: true,
      email: res1.email,
    });

    return;
  } else {
    const redirUrl =
      process.env.NODE_ENV === "production"
        ? "https%3A//app.inishare.com/"
        : "http%3A//localhost:3000/";

    const clientId =
      "170989860060-c4rquc040honth30s49frmovjuv28lqv.apps.googleusercontent.com";

    document.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/drive.file&prompt=consent&access_type=offline&include_granted_scopes=true&response_type=code&state=${token}xxxxxxxxxxxxxxxxxxxxxxx${
      store?.getState()?.currentUser?.id
    }xxxxxxxxxxxxxxxxxxxxxxx${wh}&redirect_uri=${redirUrl}app/library&client_id=${clientId}`;
  }
};

const showDriveSignUp = () => {
  store.dispatch(setDriveOpen(true));
};

export const signInDriveIfNeeded = async () => {
  if (!store.getState().currentUser?.drive?.expiry) {
    if (!store.getState().currentUser?.drive?.expiry) {
      showDriveSignUp();
      return;
    }
  }

  if (store.getState().currentUser?.drive?.expiry < Date.now()) {
    const token = (await auth.currentUser?.getIdToken()) || "";

    let res: any;

    const googleIt = httpsCallable(functions, "googleIt");

    res = await googleIt({
      authorization: token,
      refresh_token: store.getState()?.currentUser?.drive?.refresh_token,
      ios: store.getState()?.currentUser?.drive?.ios,
      uid: store.getState()?.currentUser?.id,
      email: store.getState()?.currentUser?.drive?.email,
    });

    if (res.data.error) {
      showDriveSignUp();
      return;
    } else {
      return res.data;
    }
  } else {
    return store.getState().currentUser?.drive;
  }
};

export const getDriveTokenFromCode = async (
  code: string,
  token: string,
  id: string
) => {
  const googleIt = httpsCallable(functions, "googleIt");

  localStorage.setItem("nex-a", "drived");

  await googleIt({
    authorization: token,
    uid: id,
    code: code,
    ios: false,
  });
};

const makeSureFolderExists = async (access_token: any) => {
  let f: any;

  // alert("bubu");
  // let data = await App.getState();

  let options: any = {};

  try {
    // f = await xhrLoad({
    //   url:
    //     "https://www.googleapis.com/drive/v3/files?q=" +
    //     encodeURIComponent('name="appbook4d-files" and trashed = false'),
    //   headers: { Authorization: "Bearer " + access_token },
    //   type: "GET",
    // });

    if (platform === "web") {
      options = {
        url:
          "https://www.googleapis.com/drive/v3/files?q=" +
          encodeURIComponent('name="appbook4d-files" and trashed = false'),
        headers: { Authorization: "Bearer " + access_token },
      };

      const resp: HttpResponse = await WebHTTP.get(options);

      f = resp.data;
    } else {
      options = {
        headers: { Authorization: "Bearer " + access_token },
        method: "get",
        responseType: "json",
      };

      const resp = await MobileHTTP.sendRequest(
        "https://www.googleapis.com/drive/v3/files?q=" +
          encodeURIComponent('name="appbook4d-files" and trashed = false'),
        options
      );

      f = resp.data;
    }
    // f = await axios({
    //   url:
    //     "https://www.googleapis.com/drive/v3/files?q=" +
    //     encodeURIComponent('name="appbook4d-files" and trashed = false'),
    //   method: "GET",
    //   headers: {
    //     Authorization: "Bearer " + access_token,
    //   },
    // });
  } catch (e) {
    console.log(e);
    showDriveSignUp();
    return;
  }

  let id = "";

  if (f.files && f.files[0]) {
    id = f.files[0].id;
  }

  if (!id) {
    var form = {
      name: "appbook4d-files",
      mimeType: "application/vnd.google-apps.folder",
      space: "drive",
    };

    if (platform === "web") {
      options = {
        url: "https://www.googleapis.com/drive/v3/files",
        method: "POST",
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        data: form,
      };

      const response: HttpResponse = await WebHTTP.post(options);

      f = response.data;
    } else {
      options = {
        method: "post",
        serializer: "json",
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        responseType: "json",
        data: form,
      };

      try {
        const resp = await MobileHTTP.sendRequest(
          "https://www.googleapis.com/drive/v3/files",
          options
        );

        f = resp.data;
      } catch (e) {
        console.log(e);
        return "";
      }
    }

    // const val = await axios({
    //   url: "https://www.googleapis.com/drive/v3/files",
    //   method: "POST",
    //   headers: {
    //     Authorization: "Bearer " + access_token,
    //     "Content-Type": "application/json",
    //   },
    //   data: form,
    // });

    const permissions = {
      type: "anyone",
      role: "reader",
    };

    if (platform === "web") {
      options = {
        url:
          "https://www.googleapis.com/drive/v3/files/" + f.id + "/permissions",
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        data: permissions,
      };

      try {
        await WebHTTP.post(options);
      } catch (e) {
        console.log(e);
        return "";
      }
    } else {
      options = {
        method: "post",
        serializer: "json",
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        data: permissions,
      };

      try {
        await MobileHTTP.sendRequest(
          "https://www.googleapis.com/drive/v3/files/" + f.id + "/permissions",
          options
        );
      } catch (e) {
        console.log(e);
        return "";
      }
    }
    id = f.id;
  }

  return id;
};

export const uploadDriveFile = async (
  file: any,
  setStatus: any,
  init: any = undefined
) => {
  let f: any;

  const drive = init || store.getState().currentUser?.drive;

  setStatus?.(0);

  const folderId = await makeSureFolderExists(drive.access_token);

  let metadata = {
    name: file.name,
    parents: [folderId],
  };

  const form = new FormData();

  let newType = file.type;

  if (file.type.includes("presentation")) {
    newType = "application/vnd.google-apps.spreadsheet";
  }

  form.append(
    "metadata",
    new Blob([JSON.stringify(metadata)], {
      type: newType,
    })
  );
  form.append("file", file);

  try {
    if (platform === "web") {
      f = await WebHTTP.post({
        url: "https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable&fields=id,trashed,webViewLink,webContentLink,properties",
        headers: {
          Authorization: "Bearer " + drive.access_token,
          "Content-Type": "application/json",
        },
        data: {
          name: file.name,
          mimeType: newType,
          parents: [folderId],
          properties: { book: store.getState().currentBook.id },
        },
      });

      const chars = new Uint8Array(await file.arrayBuffer());
      const CHUNK_SIZE = 0x40000;

      let index = 0;
      const length = chars.length;
      let slice;

      const uploadLocation = f.headers.location;

      while (index < length) {
        slice = chars.subarray(index, Math.min(index + CHUNK_SIZE, length));

        try {
          f = await fetch(uploadLocation, {
            method: "PUT",
            headers: {
              "Content-Range": `bytes ${index}-${
                Math.min(index + CHUNK_SIZE, length) - 1
              }/${file.size}`,
              "Content-Length": slice.byteLength + "",
            },
            body: slice,
          });
        } catch (e) {
          console.log(e);
          return [""];
        }

        index += CHUNK_SIZE;

        setStatus?.(Math.ceil((index / file.size) * 100));
      }

      f = await f.json();
    } else {
      let options: any = {
        method: "post",
        headers: {
          Authorization: `Bearer ${drive.access_token}`,
          "Content-Type": "application/json",
        },
        data: {
          name: file.name,
          mimeType: newType,
          parents: [folderId],
          properties: { book: store.getState().currentBook.id },
        },
        serializer: "json",
      };

      const res = await MobileHTTP.sendRequest(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable&fields=id,trashed,webViewLink,webContentLink",
        options
      );

      const chars = new Uint8Array(await file.arrayBuffer());
      const CHUNK_SIZE = 0x40000;

      let index = 0;
      const length = chars.length;
      let slice;

      const uploadLocation = res.headers.location;

      while (index < length) {
        slice = chars.subarray(index, Math.min(index + CHUNK_SIZE, length));

        const newSlice = slice.buffer.slice(
          slice.byteOffset,
          slice.byteLength + slice.byteOffset
        );

        options = {
          method: "put",
          serializer: "raw",
          headers: {
            "Content-Range": `bytes ${index}-${
              Math.min(index + CHUNK_SIZE, length) - 1
            }/${file.size}`,
            "Content-Length": slice.byteLength + "",
          },
          data: newSlice,
        };

        try {
          f = await MobileHTTP.sendRequest(uploadLocation, options);
        } catch (e: any) {
          if (e.status === 308) {
            f = e;
          } else {
            console.log(e);
            return [""];
          }
        }
        index += CHUNK_SIZE;

        setStatus?.(Math.ceil((index / file.size) * 100));
      }

      f = JSON.parse(f.data);

      // const options: any = {
      //   method: "post",
      //   serializer: "multipart",
      //   headers: {
      //     Authorization: "Bearer " + drive.access_token,
      //     "content-type": "multipart/form-data",
      //   },
      //   data: form, //await createMultiPart(metadata, file),
      // };

      // MobileHTTP.setDataSerializer("multipart");

      // f = await MobileHTTP.sendRequest(
      //   "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id,trashed",
      //   options
      // );
      // f = f.data;
    }
  } catch (e) {
    console.log(e);
    return [""];
  }

  return f;
};

export const deleteDriveFile = async (id: string, token: string) => {
  if (platform === "web") {
    await WebHTTP.del({
      url: "https://www.googleapis.com/drive/v3/files/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  } else {
    let options: any = {
      method: "delete",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await MobileHTTP.sendRequest(
      "https://www.googleapis.com/drive/v3/files/" + id,
      options
    );
  }
};

export const deleteDriveFiles = async () => {
  let f: any;

  const drive = store.getState().currentUser?.drive;

  const files = (
    localStorage.getItem("unsaved-" + store.getState().currentUser?.id) || ""
  ).split("\n");

  for (let g = 0; g < files.length; g++) {
    if (files[g]) {
      deleteDriveFile(files[g], drive.access_token);
    }
  }

  localStorage.setItem("unsaved-" + store.getState().currentUser?.id, "");

  // try {
  //   if (platform === "web") {
  //     f = await WebHTTP.post({
  //       url: "https://www.googleapis.com/batch/drive/v3",
  //       headers: {
  //         Authorization: "Bearer " + drive.access_token,
  //         "Content-Type": "multipart/mixed; boundary=END_OF_PART",
  //       },
  //       data: prefix,
  //     });
  //     console.log(f);
  //     f = await f.data;
  //   } else {
  //     let options: any = {
  //       method: "post",
  //       headers: {
  //         Authorization: `Bearer ${drive.access_token}`,
  //         "Content-Type": "application/json",
  //       },
  //       data: {
  //         parents: [folderId],
  //         properties: { book: store.getState().currentBook.id },
  //       },
  //       serializer: "json",
  //     };

  //     const res = await MobileHTTP.sendRequest(
  //       "https://www.googleapis.com/drive/v3/files/copy?fields=id,trashed,webViewLink,webContentLink,properties",
  //       options
  //     );

  //     f = JSON.parse(res.data);

  //     // const options: any = {
  //     //   method: "post",
  //     //   serializer: "multipart",
  //     //   headers: {
  //     //     Authorization: "Bearer " + drive.access_token,
  //     //     "content-type": "multipart/form-data",
  //     //   },
  //     //   data: form, //await createMultiPart(metadata, file),
  //     // };

  //     // MobileHTTP.setDataSerializer("multipart");

  //     // f = await MobileHTTP.sendRequest(
  //     //   "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id,trashed",
  //     //   options
  //     // );
  //     // f = f.data;
  //   }
  // } catch (e) {
  //   console.log(e);
  //   return [""];
  // }

  return f;
};

const signUpGoogle = () => {
  if (platform === "web") {
    window.open("https://accounts.google.com/signup");
  } else {
    Browser.open({ url: "https://accounts.google.com/signup" });
  }
};

export const DriveNotice = () => {
  return (
    <React.Fragment>
      <HelpDiv>
        <HelpHeaderDiv>
          {tx("wasdweeoowe4wewe", "Need connection to Google Drive")}
        </HelpHeaderDiv>
        <HelpTextDiv>
          {tx(
            "efggi1wew",
            "In the free version IniShare uses a folder in your Google Drive to save files you use in your exercises."
          )}{" "}
          {tx(
            "efggi1w",
            "You need to give access to IniShare by clicking on the Connect button and approving such a connection."
          )}
        </HelpTextDiv>
        <HelpTextDiv>
          {tx(
            "efggi1wwewewe",
            "IniShare only has access to its own files and folders."
          )}
        </HelpTextDiv>
        <HelpTextDiv>
          {tx(
            "ewefggi1wwewewe",
            "The files will be available for anybody who gets the link. (Normally people would only get the link with the book, though.)"
          )}
        </HelpTextDiv>
        <HelpTextDiv>
          {tx(
            "ewefggi1wwewewewe",
            "If you want more security, choose one of the paid plans."
          )}
        </HelpTextDiv>
        <HelpTextDiv>
          {tz(
            "efggi12a",
            "If you don't have a Google account, create one [[[here]]] and come back here afterwards.",
            signUpGoogle
          )}{" "}
        </HelpTextDiv>

        <BDiv>
          <Button
            positive
            onClick={() =>
              driveFullSignIn(
                document.location.href.includes("/settings")
                  ? "settings"
                  : "library"
              )
            }
          >
            {tx("loginklas", "Connect")}
          </Button>
        </BDiv>
      </HelpDiv>
    </React.Fragment>
  );
};
