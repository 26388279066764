import { createSlice } from "@reduxjs/toolkit";

export interface ChatType {
  messages: any[];
}

// ** Initial State
const initialState: ChatType = {
  messages: [],
};

const chatSlice = createSlice({
  initialState,
  name: "chat",
  reducers: {
    setChat: (state, action) => {
      state.messages = action.payload || [];
    },
  },
});

export const { setChat } = chatSlice.actions;

export default chatSlice.reducer;
