import { createSlice } from "@reduxjs/toolkit";

const xfilesSlice = createSlice({
  initialState: null as any,
  name: "xfiles",
  reducers: {
    setXfiles: (state, action) => {
      return action.payload;
    },
  },
});

export const { setXfiles } = xfilesSlice.actions;

export default xfilesSlice.reducer;
