import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
//@ts-ignore
import ModalVideo from "react-modal-video";
import store from "redux/store";
import { setJoyrideVideo } from "redux/reducers/reducer.joyrideVideo";

const JoyrideVideo = (props: any) => {
  const joyrideVideo = useSelector<RootState, any>(
    (state) => state.joyrideVideo
  );

  return (
    <React.Fragment>
      <ModalVideo
        channel="youtube"
        autoplay="1"
        mute="1"
        isOpen={!!joyrideVideo}
        videoId={joyrideVideo}
        onClose={() => store.dispatch(setJoyrideVideo(""))}
        ratio={"10:14"}
      />
    </React.Fragment>
  );
};

export default JoyrideVideo;
