export const exercisepresentationon = [
  {
    act: "presentationon",
    target: "#presentationx",
    content:
      "You are in read only mode, which means that you can view your book as a reader would see it, and you can't edit it. To edit the book turn off this option here and start the tutorial again if you want to. ",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "exedbmx1j1",
  },
];

export const exercisenoedit = [
  {
    act: "exercisenoedit",
    anchor: "exercisenoedit",
    target: "#exeme",
    content:
      "This is a book shared with you by somebody else. These are the items and files in this chapter. To see the contents of an item, click on the item title.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "exedbmx2j2",
  },
];
