export const viewer = [
  {
    act: "viewer",
    anchor: "viewer",
    target: "body",
    content:
      "This is the IniShare viewer. This is where you can see the contents of your books. There can be various files, youtube videos, pictures etc.",
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },

    disableSpotlight: true,
    disableOverlay: false,
    disableBeacon: true,

    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viewbdbmx1m1",
  },
  // {
  //   target: "#xopenbutton",
  //   content:
  //     "There are some additional controls to move around in your book in IniShare. To open or close the toolbar for this click here.",
  //   if: { var: "fullButtons", value: false },

  //   disableBeacon: true,
  //   spotlightClicks: true,
  //   hideBackButton: true,
  //   noNext: true,
  //   trans: "viewbdbmx2m2",
  // },
  {
    target: "#xnavbuttons",
    content: "With these buttons you can navigate between parts.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    spotlightPadding: 0,
    noNext: false,
    trans: "viewbdbmx3m3cwedff",
  },
  {
    target: "#xmovebackbutton",
    content:
      "With this you can move to the previous file or part in your book without exiting the viewer.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viewbdbmx4m4",
  },
  // {
  //   target: "#xsavebutton",
  //   content: "You can mark a part complete or incomplete by clicking here.",
  //   disableBeacon: true,
  //   spotlightClicks: false,
  //   hideBackButton: true,
  //   noNext: false,
  //   trans: "viewbdbmx5",
  // },

  {
    target: "#xmoveforwardbutton",
    content:
      "With this you can move to the next file or part in your book without exiting the viewer.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viewbdbmx7m6",
  },
  {
    target: "body",
    content:
      "You can also take notes or make comments to each pragraph or element.",
    placement: "center",
    floaterProps: { hideArrow: true, placement: "center" },
    disableSpotlight: true,
    disableOverlay: false,
    disableBeacon: true,
    styles: { options: { overlayColor: "rgba(0,0,0,0.5)" } },

    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viewbdbmx8m7",
  },
  {
    target: "#openxer",
    content:
      "On small screens you have to click on these three dots to open the toolbar here.",
    openit: true,

    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "viewbdbmx9m8",
  },

  {
    target: "#firstxbutton",
    content: "Click here to enable commenting on this file.",

    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "viewbdbmx10m9",
  },

  {
    target: "#commenting0",
    content:
      "You will see an icon like this next to all paragraphs or elements. If you click on one of them you can add a comment or note to that line.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viewbdbmx11m10",
  },
  {
    target: "#openxer",
    content:
      "When you are done you have to turn off commenting. But on small screens you have to click on these three dots to open the toolbar here.",
    openit: true,

    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "viewbdbmx12m11",
  },

  {
    target: "#firstxbutton",
    content:
      "When you are done with your comments turn off commenting by clicking on this button again.",

    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "viewbdbmx13m12",
  },
  {
    target: "body",
    placement: "center",
    content:
      "Then only the parts with comments will have a little comment icon next to them. If you click on those you can see the comments.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viewbdbmx14m13",
  },
  {
    target: "#openxer",
    content:
      "On small screens you have to click on these three dots to open the toolbar here.",
    openit: true,

    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "viewbdbmx9m8",
  },

  {
    target: "#xfullscreenbutton",
    content:
      "You can make the item go fullscreen which is useful for slide shows or seminars.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viewbdbwemx6m5",
  },
  {
    target: "#xdonebutton",
    content:
      "With this button you can mark a part completed which shows up in the title and in the list of parts by the number being green.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    video: "r0pMsirN14o",
    trans: "viwerewbdbmx6m5cku",
  },
  {
    target: "#xdonebutton",
    content:
      "If a part is completed you can also unmark it as done with this button.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viwerewbdbmwesx6m5cku",
  },

  {
    target: "#exerarrow",
    content:
      "With this arrow you can return to the list of parts, and then to your chapters or the library.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "viewbdbmx15m14",
  },
];
