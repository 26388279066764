import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const quotasSlice = createSlice({
  initialState,
  name: "quotasGlobal",
  reducers: {
    setQuotas: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setQuotas } = quotasSlice.actions;

export default quotasSlice.reducer;
