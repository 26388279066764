import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = {};

const userStatusSlice = createSlice({
  initialState,
  name: "userStatus",
  reducers: {
    setUserStatus: (state, action) => {
      return action.payload;
    },
  },
});

export const { setUserStatus } = userStatusSlice.actions;

export default userStatusSlice.reducer;
