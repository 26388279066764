export const allLanguages: any = [
  {
    code: "en",
    name: "English",
    ownName: "English",
    support: "helpen@netenglish.com",
  },
  {
    code: "hu",
    name: "Hungarian",
    ownName: "Magyar",
    support: "helphu@netenglish.com",
  },

  // {
  //   code: "es",
  //   name: "Spanish",
  //   ownName: "Español",
  //   support: "helpes@netenglish.com",
  // },
  // {
  //   code: "it",
  //   name: "Italian",
  //   ownName: "Italiano",
  //   support: "helpit@netenglish.com",
  // },
  // {
  //   code: "pl",
  //   name: "Polish",
  //   ownName: "Polski",
  //   support: "helppl@netenglish.com",
  // },
];

export const supportEmail = (lang: string) => {
  return (
    allLanguages.find((item: any) => item.code === lang)?.support ||
    "support@netenglish.com"
  );
};
