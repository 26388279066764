import MenuDropdown from "menu/menu";
import React from "react";
import { Portal } from "semantic-ui-react";

const MainMenu = React.memo((props: any) => {
  return (
    <React.Fragment>
      <Portal mountNode={document.body} open={true}>
        <div
          id="mainmenu"
          style={{
            position: "fixed",
            right: "12px",
            top: "calc(env(safe-area-inset-top) + 5px)",
            zIndex: "10000005",
          }}
          className="mfhidden"
        >
          <MenuDropdown id="misc" />
        </div>
      </Portal>
    </React.Fragment>
  );
});

export default MainMenu;
