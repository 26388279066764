import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const sidebarOpenSlice = createSlice({
  initialState,
  name: "sidebarOpen",
  reducers: {
    setSidebarOpen: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setSidebarOpen } = sidebarOpenSlice.actions;

export default sidebarOpenSlice.reducer;
