import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const statusSlice = createSlice({
  initialState: "reader",
  name: "bookStatus",
  reducers: {
    setStatus: (state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setStatus } = statusSlice.actions;

export default statusSlice.reducer;
