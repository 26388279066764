import styled from "styled-components";

export const HelpDiv = styled.div`
  padding: 5px;
  padding-top: 5px;
`;

export const HelpHeaderDiv = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: var(--toolbar-background);
  margin-bottom: 25px;
  margin-top: 20px;
`;

export const HelpTextDiv = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  font-family: var(--font-normal);
`;

export const HelpTextDivIndented = styled.div`
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 18px;
  font-family: var(--font-normal);
`;

export const CodeDiv = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  padding-left: 35px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  background: #efefef;
  border: 1px solid lightgrey;
  font-family: monospace;
`;

export const CodeLine = styled.div`
  position: relative;
  overflow-wrap: break-word;
`;

export const CodeNum = styled.div`
  position: absolute;
  left: -30px;
  top: 0;
`;

export const ImgDiv = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

export const ImgImg = styled.img`
  width: 95%;
  max-width: 340px;
`;

// const HelpTutorial = (props: any) => {
//   return (
//     <React.Fragment>
//       <HelpDiv>
//         <HelpHeaderDiv>{tx("help1bb", "What is AppBook4D?")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help1",
//             "AppBook4D is an app which makes it possible for English tutors to easily communicate to their students, assign them homework and follow them up through their studies."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help13",
//             "It has hundreds of prepared exercises and texts for all levels, and you are able to use your already existing exercises, audio, video, youtube files and texts as well."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help14",
//             "You can easily send tests to your students, schedule them for their lessons, follow how much they study, automatically nudge them etc. etc."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help14a",
//             "Importantly it is forever free for all teachers and your connection to your students is direct."
//           )}{" "}
//           {tx(
//             "help14b",
//             "We are not in the middle, we only facilitate your work with them."
//           )}
//         </HelpTextDiv>

//         <HelpTextDiv>
//           {tx(
//             "help15",
//             "Students pay a low monthly fee while they are using the program with a simple subscription through Google Play or the Appstore, and in exchange they get tons of grammar exercises, texts, pronunciation drills, a built in dictionary, a unique vocabulary building tool and special help from you in a very direct way."
//           )}
//         </HelpTextDiv>

//         <HelpTextDiv>
//           {tx(
//             "help16",
//             "With AppBook4D you don't need to gather exercises on the Internet in all kinds of formats, and it gives you one tool to follow up on all your students."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help22",
//             "AppBook4D has over 250 texts with mp3 files at the moment on 5 different levels for improving vocabulary, and hundreds of grammar drills for virtually every part of the English grammar which can come up in teaching."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help23",
//             "That means, you probably never have to find any exercises outside AppBook4D as we have you covered."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help24",
//             "However, if you have your own texts, exercises, files and videos you want to assign to your students, you can easily create and re-use your own custom exercises as well."
//           )}
//         </HelpTextDiv>
//         <HelpHeaderDiv>
//           {tx("help17", "How does AppBook4D work?")}
//         </HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help18",
//             "AppBook4D is like a chat application similar to Skype or Facebook Messenger or even to the sms function of your phone."
//           )}{" "}
//           {tx(
//             "help14a",
//             "Teachers and individual students can communicate in a dedicated chat and all students are available in a single list."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img2.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help26",
//             "When you sign up as a teacher on AppBook4D, you get a simple chat window very much like what you get in Facebook Messenger or in the sms function of your phone."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help27",
//             "NOTE: Every teacher signing up will get a Test Student as a student which is basically a fake user you can use to try out the functions of the app."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img3.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help28", "This is the basic chat window.")}{" "}
//           {tx("help14b", "In the top right corner you will see a back arrow.")}{" "}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img4.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help14c",
//             "If you click on it you will get to the list of your currently active and inactive students."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img4a.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help29",
//             "If you click on any of them, your communication with that student appears in the Chat window."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img5.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help30",
//             "You can type in anything you want to say to your student and press the Send button to send it to them."
//           )}{" "}
//           {tx(
//             "help14d",
//             "It will instantly appear in their own application and they will receive a notification on their mobile phone if the app is installed for them."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img6.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help31",
//             "NOTE: Click on the Test Student if you want to try out these functions in the app."
//           )}{" "}
//           {tx(
//             "help14ewew",
//             "If you send a message to the Test Student it will automatically send back some kind of answer."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img7.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help32",
//             "But if that was all, you could just use Skype or Facebook Messenger, and AppBook4D would not be needed at all."
//           )}
//         </HelpTextDiv>
//         <HelpHeaderDiv>{tx("help33", "So why AppBook4D?")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help33a",
//             "Well, with AppBook4D you can communicate to your students about the SPECIFIC things that are related to your relationship with your students as a tutor."
//           )}
//         </HelpTextDiv>
//         <HelpHeaderDiv>{tx("help34", "Homework")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help35",
//             "The most important part of it is the ability to send homework to your students."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help36",
//             "Just open the chat to one of your students (here the Test Student) and click on the three dots in the bottom left corner. "
//           )}{" "}
//           {tx("help36c", "That is the button for the chat menu.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img7a.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help37", "You will see a little menu.")}{" "}
//           {tx("help14g", "The top item is Homework.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img8.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help38",
//             "If you click on it you get a new window where you can put together homework for your students."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img9.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help39",
//             "At the top you will see various categories of exercises you might assign:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img10.png" />
//         </ImgDiv>
//         <HelpTextDivIndented>
//           {ty(
//             "help40",
//             "*Grammar* - hundreds of grammar exercises for practicing all kinds of grammar points."
//           )}
//         </HelpTextDivIndented>
//         <HelpTextDivIndented>
//           {ty(
//             "help41",
//             "*Reading* - hundreds of texts with recorded audio in all kinds of topics on five levels to improve your students' vocabulary and understanding."
//           )}
//         </HelpTextDivIndented>
//         <HelpTextDivIndented>
//           {ty(
//             "help42",
//             "*Misc* - miscelaneous drills you can assign, most importantly pronunciation drills, translation drills, and writing drills."
//           )}
//         </HelpTextDivIndented>
//         <HelpTextDivIndented>
//           {ty(
//             "help43",
//             "*Custom* - exercises you personally created in all kinds of formats (more on this later)."
//           )}
//         </HelpTextDivIndented>
//         <HelpTextDivIndented>
//           {ty(
//             "help44",
//             "*History* - this shows ALL the exercises your student has already completed since you've been in communication with them broken down by day."
//           )}
//         </HelpTextDivIndented>
//         <HelpTextDiv>
//           {tx(
//             "help39aaa",
//             "Not all fits on a small screen, so you might have to pull it left and right to see all."
//           )}
//         </HelpTextDiv>
//         <HelpHeaderDiv>{tx("help45", "How to use these?")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help46",
//             "If you click on Grammar you will see a long list of exercises showing up."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img11.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help47",
//             "You will see that each of them are 'labeled', i.e. they have various labels describing them."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img12.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help48",
//             "To look for specific drills start typing in the name of a category. If there are labels corresponding to what you type in you will see those options showing up in a list."
//           )}
//         </HelpTextDiv>

//         <HelpTextDiv>
//           {tx(
//             "help49",
//             "For example if you type 'present' you will see something like this:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img13.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help50",
//             "If you want to see only exercises with one of these labels, just click on the label you want or use the arrows to go up and down in the list and press Enter to select them."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help51",
//             "If you select 'present perfect' you will see something like this:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img14.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help52",
//             "Now you only see those drills which are labeled as 'present perfect'."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help53",
//             "You can type in and select more than one labels. In this case you will see those exercises which have ALL those labels."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img15.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help54",
//             "Now you can assign any of those exercises as homework by simply clicking on the text part of them (not the icon)."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img16.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help55",
//             "In the lower right corner you will see that the exercises you click on get added to the homework to be assigned."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img17.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help56",
//             "Also the exercise you selected as homework will be dimmed, showing that this exercise was already assigned, so that you don't assign it twice."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img18.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help57",
//             "In fact any exercise which your student has already done in the past will be dimmed."
//           )}{" "}
//           {tx(
//             "help15a",
//             "Like this you don't have to remember what exercises you already assigned to your students over the months."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help58",
//             "If you have a bigger screen (not on a phone), on the right side you will see the homework as your selections appear there."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img19.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help59", "On a phone there's not enough space for that.")}{" "}
//           {tx(
//             "help15baa",
//             "On a phone if you want to see the currently selected homework, click on the Homework tab here:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img20.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help60",
//             "You can change the sequence of the assigned files by simply dragging the items with your finger or mouse."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img21.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help61",
//             "You can also delete items by simply clicking on their text part (not the icon)."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help62",
//             "If you want to check what the student will see in a specific exercise, simply click on the icon in front of the exercise and you will see a preview."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img22.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help63",
//             "You can also try to solve the exercise to see what your student will see."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img23.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help64",
//             "In the Preview window in the lower right corner you will see a Check button (if you have to answer any questions) or a Complete button if there are no questions to answer in the given exercise."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img24.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help64c",
//             "(These are only usable if all questions are answered in a drill.)"
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help65",
//             "If you click on Check the computer will show you the correct answers and your result."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img25.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help66",
//             "With the Complete button you complete the exercise and close the Preview."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img26.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help67",
//             "You can also close the Preview by clicking on the Close button in the top right corner."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img27.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help68",
//             "Now you can add further exercises to the Homework from the various categories by clicking on the categories at the top and searching in the Search field as needed."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img28.png" />
//         </ImgDiv>
//         <HelpTextDiv>{tx("help69", "Everyhing else is the same.")}</HelpTextDiv>

//         <HelpTextDiv>
//           {tx(
//             "help75",
//             "It is important that the Homework is not yet sent to your student at this point."
//           )}{" "}
//           {tx(
//             "help15e",
//             "You have to go to the Homework tab and click on the Send button to actually assign the exercises."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img29.png" />
//         </ImgDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img29a.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help76",
//             "If you click on it the student will get a special message in the chat window that they received homework with a link to start doing it."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img30.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help77",
//             "When they complete the last one you will automatically receive a notification in the chat window, like this:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img31.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help78cf", "Now you can check the results of your student.")}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx("help78cfa", "For this open the lower menu and select Homework.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img31a.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help78cfas", "Then at the top select the History option.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img31b.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help79",
//             "It will bring you to the students history showing all exercises done so far with a little checkmark next to all of those done."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img32.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help80",
//             "Click on the icon in front of an exercise to see the results of the student on that exercise."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img33.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help81",
//             "You will see the percentage of right answers at the top and also any errors the student might have made marked in red."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img34.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help82", "In the lower left corner you will see two arrows.")}{" "}
//           {tx(
//             "help16a",
//             "With these you can go through the exercises the student completed backwards and forwards."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help82cs",
//             "It's important to know that in certain exercises the student is required to complete a minimum of three tasks."
//           )}{" "}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img35.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help83a",
//             "If you finished checking, you can close the window by clicking on the Back button in the top left corner."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img36.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help84",
//             "If you want to re-assign an item to the student from the History just click on the item (not the icon) and it will be added to the proposed next homework."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help85",
//             "(Never forget to actually send the Homework after selecting the proper exercises.)"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img37.png" />
//         </ImgDiv>
//         <HelpTextDiv>{tx("help86", "Trick:")}</HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help87",
//             "When you have a bunch of exercises shown in a list, clicking on the double arrow will assign all those not yet assigned (dimmed) to the homework without having to click on each separately."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img38.png" />
//         </ImgDiv>
//         <HelpHeaderDiv>{tx("help88", "Sets:")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help89",
//             "You can save sets of exercises in a group for later use together."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help90",
//             "For this assign a bunch of exercises as homework, and then click on the Homework tab."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img39.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help91a",
//             "Now click on the little star (favorite) icon in the top line and a little dialog appears:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img40a.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help92",
//             "Now type in a name under which you want to save those exercises together."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img40.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help93a", "In our case it's 'present pefect easy'.")}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help94",
//             "In the future if you want to assign these exercises together, just click on the Sets menu option at the top, and start typing in 'present perfect' in the search field."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img42.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help95",
//             "When 'Prepared: present perfect' shows up, just click on it or move on it with the arrows and press enter."
//           )}{" "}
//           {tx(
//             "help16c",
//             "The exercises which are part of this set will appear in the list."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img43.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help96",
//             "You can assign them by clicking on them one by one or by clicking on the double triangle button to assign all of them to homework."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help97",
//             "That's the simplicity of assigning Homework to your students."
//           )}
//         </HelpTextDiv>
//         <HelpHeaderDiv>{tx("help98", "Custom exercises")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help99",
//             "In AppBook4D you can create your own exercises or even use files or links to other websites and you can send these to your students."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help100",
//             "If you enter the Homework assignment section, at the top you will see a Custom tab."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img44.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help101a",
//             "If you click on this you will see your own exercises if they exist."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help102",
//             "To create a new one just click on the little + button and it opens the Exercise Factory."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img45.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help103", "When you create a new exercise you have to:")}
//         </HelpTextDiv>
//         <HelpTextDivIndented>
//           {tx("help104", "a) Select the type of exercise you want to create.")}
//         </HelpTextDivIndented>
//         <HelpTextDivIndented>
//           {tx("help105", "b) Create the content of the exercise.")}
//         </HelpTextDivIndented>
//         <HelpTextDivIndented>
//           {tx("help106", "c) Add labels to identify the exercise.")}
//         </HelpTextDivIndented>
//         <HelpTextDivIndented>
//           {tx("help107", "d) Save the exercise.")}
//         </HelpTextDivIndented>
//         <HelpTextDiv>
//           {tx(
//             "help108",
//             "The first step is always to select the type of exercise you want to create."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img46.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help109",
//             "(The available types might change as we add more options.)"
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help110",
//             "When you select a type at the top of the window you will see a short description about the type of exercise and also a link where you can see more data on how to create that type of exercise so we are not going into details about each here."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img47.png" />
//         </ImgDiv>
//         <HelpHeaderDiv>{tx("help111", "Creating an exercise")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help112",
//             "As an example let's say you want to use a pdf file to assign as an exercise."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help113",
//             "Many teachers have already created exercises, pdf files, mp3-s, youtube or other videos they like to use as exercise."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help114",
//             "In AppBook4D you can simply upload your files to your Google Drive, create a link to the file and create a custom exercise for it."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>{tx("help115", "This is how you do it:")}</HelpTextDiv>
//         <HelpTextDivIndented>
//           {tx("help116", "a) Upload your file to Google Drive.")}
//         </HelpTextDivIndented>
//         <ImgDiv>
//           <ImgImg src="/help/img48.png" />
//         </ImgDiv>
//         <HelpTextDivIndented>
//           {tx(
//             "help117",
//             "b) Get a link to it (for this you have to share your file with everybody)."
//           )}
//         </HelpTextDivIndented>
//         <ImgDiv>
//           <ImgImg src="/help/img49.png" />
//         </ImgDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img49a.png" />
//         </ImgDiv>
//         <HelpTextDivIndented>
//           {tx(
//             "help118",
//             "c) Select Google Drive file as type in the Exercise Factory."
//           )}
//         </HelpTextDivIndented>
//         <ImgDiv>
//           <ImgImg src="/help/img50.png" />
//         </ImgDiv>
//         <HelpTextDivIndented>
//           {tx(
//             "help119",
//             "d) Insert the link into the Editor as the first line as shown here."
//           )}
//         </HelpTextDivIndented>
//         <ImgDiv>
//           <ImgImg src="/help/img51.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help120",
//             "If you now click on the Preview button you will see what a student will see when they open this exercise."
//           )}{" "}
//           {tx("help198a", "The file will open in a AppBook4D window.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img52.png" />
//         </ImgDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img52a.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help121",
//             "You can close the Preview by the close button in the top right corner."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img53.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help122a",
//             "If this is an exercise you only want to use with this one student and one homework, click on the Save button."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img53a.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help122b",
//             "If you want to re-use this exercise you have to label it. Add a few words or phrases by which you will find this exercise."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx("help123", "Type in a word or phrase and press Tab.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img54.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help124",
//             "You will see that a little label appears with that word or phrase."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img55.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help125",
//             "You can now type in another word or phrase and press Tab to include that, too."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img56.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help126", "An example of labeling such an exercise could be:")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img57.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help127",
//             "Do not overdo labeling. Give just enough labels for yourself to be able to find the exercise when you need it."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help128",
//             "If a label already exists and you start typing it you will see something like this:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img58.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help129",
//             "At this point just click on the already existing label, or use the arrows (up and down) to select the right one and press Enter."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx("help130", "Now you are ready to save your new custom exercise.")}{" "}
//           {tx("help17c", "Just click on the Save button.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img59.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help131",
//             "Important: Custom exercises are saved based on the labels you gave to them. So if an exercise already exists with the exact same labels, the app will ask you if you want to overwrite it."
//           )}
//         </HelpTextDiv>

//         <HelpTextDiv>
//           {tx(
//             "help132",
//             "If you want to create a new exercise it has to have some difference in its labels."
//           )}
//         </HelpTextDiv>
//         <HelpHeaderDiv>{tx("help133", "Links")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help134",
//             "Another often used exercise type is a link to another page where you want to send your student to read or study something."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx("help135", "a) For this select Link as the exercise type.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img62.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help136",
//             "b) Type in some text which will appear as an explanation or instruction for the student."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img63.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help137",
//             "c) In the LAST line (it has to be a new line) paste the actual link you want to send the student to."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img64.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help138",
//             "Then you can click Preview and see what your student would see."
//           )}{" "}
//           {tx(
//             "help18a",
//             "If they click on the link a new window will appear with the link you sent them to."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help139",
//             "Then you would close the Preview, label the new exercise and press Save."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img65.png" />
//         </ImgDiv>
//         <HelpHeaderDiv>{tx("help140", "Texts")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help141",
//             "In AppBook4D you can create complex texts with all kinds of materials."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx(
//             "help142",
//             "For this you would choose the Textual exercise type."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img66.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help143a",
//             "You can read a complete description by clicking on the link there."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img67.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help144",
//             "For right now we only show an example of what's possible."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img68.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help145",
//             "The above textual exercise would show you some formatted text and then a youtube video."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {tx("help146s", "It would include an mp3 file as well.")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img69.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help147",
//             "There are lots of possibilities here which are described in detail if you click on the link shown there."
//           )}
//         </HelpTextDiv>
//         <HelpHeaderDiv>{tx("help148", "Special exercises")}</HelpHeaderDiv>
//         <HelpTextDiv>
//           {tx(
//             "help149",
//             "AppBook4D offers a few popular exercise types that you can use to create your own versions."
//           )}
//         </HelpTextDiv>
//         <HelpTextDiv>
//           {ty(
//             "help150",
//             "One of these is the exercise type we call *Selector* where the student is given options to choose from in various sentences:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img69a.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {ty(
//             "help151",
//             "Another one is where the student has to fill in words or parts of sentences called *Gap fill*:"
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img70.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help152",
//             "Then you can have a hint shown to the student in a similar exercise."
//           )}{" "}
//           {ty("help19a", "This type is called *Hint*:")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img71.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help153",
//             "And you can have suggestions shown at the end of each line."
//           )}{" "}
//           {ty("help19b", "We call this type *Suggestion*:")}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img72.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx(
//             "help154",
//             "The detailed description of these exercise types can be found if you click on the link after selecting the respective exercise types."
//           )}
//         </HelpTextDiv>
//         <ImgDiv>
//           <ImgImg src="/help/img73.png" />
//         </ImgDiv>
//         <HelpTextDiv>
//           {tx("help155", "It's worth exploring the options.")}{" "}
//           {tx(
//             "help19c",
//             "Theoretically you could write a complete English book with these options."
//           )}
//         </HelpTextDiv>
//       </HelpDiv>
//     </React.Fragment>
//   );
// };

// export default HelpTutorial;
