export const creator = [
  {
    act: "creator",
    anchor: "creator",
    target: "body",
    content: "What would you like to know?",
    placement: "center",
    floaterProps: { hideArrow: true, placement: "center" },
    disableSpotlight: true,
    disableOverlay: false,
    disableBeacon: true,
    styles: { options: { overlayColor: "rgba(0,0,0,0.5)" } },
    trans: "mzzzpr1f6",
    action: [
      {
        newride: "editor",
        question: {
          trans: "bub2821",
          text: "Just show me around in the editor.",
        },
      },
      // {
      //   newride: "booksettings",
      //   question: "Change the title and info on a book.",
      // },
      // {
      //   newride: "shareme",
      //   question: "Publish and share a book",
      // },
      // {
      //   newride: "first",
      //   question: "Show me how to create a book.",
      // },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    noNext: true,
  },
  {
    anchor: "editor",
    target: "#movescroll",
    content:
      "This is the Editor which works similarly to a Text Editor with some extra functionality. You can type and paste text and most of the usual editing stuff works in it.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "mzzzpr2f7",
  },

  {
    target: "#exedit",
    content:
      "If you press this button you can change the title and subtitle of this item.",
    video: "WkxIuVg_y8g",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr3f8",
  },
  {
    target: "#exlink",
    content: "You can insert a link with this button.",
    video: "5YmQnEnbxko",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr4f9",
  },
  {
    target: "#exlist",
    content:
      "You can convert the selected paragraphs to a list, and you can change the list format with this button.",
    video: "oWjVLDOdJIo",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr5f10",
  },
  {
    target: "#expara",
    content: "You can apply a new paragraph format with this button.",
    video: "qEkn5wqNTHw",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr6f11",
  },
  {
    target: "#exsettings",
    content:
      "You can change the paragraph formatting or create new formats with this button.",
    video: "QnpS3bhjoUI",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr7f12",
  },
  {
    target: "#exadd",
    content: "You can add special elements to your item (videos, images etc.).",
    video: "1Hk3qS_qZ2k",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr8f13",
  },
  {
    target: "#crcamera",
    content: "You can take a photo and add it to your item with this button.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr9f14",
  },
  {
    target: "#crrecorder",
    content:
      "You can record voice from your microphone and add it to your item.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr10f15",
  },
  {
    target: "#droopper",
    content:
      "You can drop files on this button and they will be added to the text in the proper format.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr10f16",
  },
  {
    target: "#crsave",
    content: "You can save your changes with this.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr11f17",
  },
  {
    target: "#crclose",
    content: "You can close the Editor with this.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr11f18",
  },

  {
    target: "#crclip",
    content:
      "You can open your clipboard and insert saved items into your document.",

    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "mzzzpr12f19",
  },
];
