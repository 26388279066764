import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const bookLoadedSlice = createSlice({
  initialState,
  name: "bookLoaded",
  reducers: {
    setBookLoaded: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setBookLoaded } = bookLoadedSlice.actions;

export default bookLoadedSlice.reducer;
