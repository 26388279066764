import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = {};

const activeSentenceSlice = createSlice({
  initialState,
  name: "activeSentence",
  reducers: {
    setActiveSentence: (state, action) => {
      return action.payload;
    },
  },
});

export const { setActiveSentence } = activeSentenceSlice.actions;

export default activeSentenceSlice.reducer;
