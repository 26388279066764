import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { open: "", command: "" };

const tourOnSlice = createSlice({
  initialState,
  name: "tourOn",
  reducers: {
    setTourOnCommand: (state: any, action: PayloadAction<any>) => {
      state.command = action.payload;
    },
    setTourOnOpen: (state: any, action: PayloadAction<any>) => {
      state.open = action.payload;
    },
  },
});

export const { setTourOnCommand, setTourOnOpen } = tourOnSlice.actions;

export default tourOnSlice.reducer;
