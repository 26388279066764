import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const backgroundSlice = createSlice({
  initialState,
  name: "backgroundGlobal",
  reducers: {
    setBackground: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.background;
    },
    deleteBackground: (state: any, action: PayloadAction<any>) => {
      delete state[action.payload.id];
    },
  },
});

export const { setBackground, deleteBackground } = backgroundSlice.actions;

export default backgroundSlice.reducer;
