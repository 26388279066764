import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = "Exercises";

const activeItemGlobalSlice = createSlice({
  initialState,
  name: "activeItemGlobal",
  reducers: {
    setActiveItem: (state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setActiveItem } = activeItemGlobalSlice.actions;

export default activeItemGlobalSlice.reducer;
