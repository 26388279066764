import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any[] = [];

const origSentencesSlice = createSlice({
  initialState,
  name: "chat",
  reducers: {
    setOrigSentences: (state, action) => {
      return action.payload;
    },
  },
});

export const { setOrigSentences } = origSentencesSlice.actions;

export default origSentencesSlice.reducer;
