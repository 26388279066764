import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const inviteOpenSlice = createSlice({
  initialState: "",
  name: "inviteOpen",
  reducers: {
    setInviteOpen: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setInviteOpen } = inviteOpenSlice.actions;

export default inviteOpenSlice.reducer;
