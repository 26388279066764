import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = [];

const datelineSlice = createSlice({
  initialState,
  name: "dateline",
  reducers: {
    setDateline: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setDateline } = datelineSlice.actions;

export default datelineSlice.reducer;

// const example = {
//   dateline: [
//     {
//       id: "asdfawerq",
//       origId: "werwer",
//       date: 165344444,
//       done: 345345,
//       i: 0,
//       t: ["grammar", "present perfect"],
//       iType: "grammar",
//       f: "filename",
//     },
//   ],
// };
