export const libraryshow = [
  {
    target: "#bookfilterid",
    content:
      "Here you read Books by Others at the top. This means that you are looking at books shared with you by others. To create and edit your own books you need to switch to the books created by you first.",
    spotlightClicks: false,
    trans: "libdbmx2asdfd22",
  },
  {
    target: "#othersdiv",
    content:
      "Here you read Books by Others at the top. This means that you are looking at books shared with you by others. To create and edit your own books you need to switch to the books created by you first.",
    spotlightClicks: false,
    trans: "libdbmx2asdfd22",
  },
  {
    target: "#bbbook0",
    content:
      "Here you read Books by Others at the top. This means that you are looking at books shared with you by others. To create and edit your own books you need to switch to the books created by you first.",
    spotlightClicks: false,
    trans: "libdbmx2asdfd22",
  },
  {
    target: "#bbinfo0",
    content:
      "Here you read Books by Others at the top. This means that you are looking at books shared with you by others. To create and edit your own books you need to switch to the books created by you first.",
    spotlightClicks: false,
    trans: "libdbmx2asdfd22",
  },
  {
    target: "#othersdiv",
    content:
      "Here you read Books by Others at the top. This means that you are looking at books shared with you by others. To create and edit your own books you need to switch to the books created by you first.",
    spotlightClicks: false,
    trans: "libdbmx2asdfd22",
  },
];
