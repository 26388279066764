import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  Label,
} from "reactstrap";
import styled from "styled-components";
import { tx } from "utils/globalize";
import Select from "react-select";
import store from "redux/store";

import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
import { setConfirm2 } from "redux/reducers/reducer.confirm2";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "firebaseUtil/firebaseUtil";
import { useDropzone } from "react-dropzone";
import { imageTypes } from "utils/mime";
import { tooLarge } from "utils/validMember";
import { setCurrentBookReducer } from "redux/reducers/reducer.currentBook";
import { getDirectory, uploadGCFile } from "utils/cloud";
import { Dimmer, Loader } from "semantic-ui-react";
import CoverColor from "library/cover/cover.color";
import SettingsSlider from "settings/settings.slider";
import ImageDialog from "utils/gallery/imageDialog";

const PicDiv = styled.div<any>`
  width: 200px;
  height: 300px;
  position: relative;
  &&.landscape {
    width: 300px;
    height: 200px;
  }
  border: 1px solid lightgrey;
  &&:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: ${(props) => props?.o || 0.3};
    background-image: url("${(props) => props.background}");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
`;

const LeftDiv = styled.div<any>`
  margin-left: auto;
  margin-right: auto;
`;

const RightDiv = styled.div<any>`
  margin-left: auto;
  margin-right: auto;
`;

const BackgroundCreator = React.memo((props: any) => {
  const [backgroundLandscape, setBackgroundLandscape] = useState("");
  const [backgroundPortrait, setBackgroundPortrait] = useState("");
  const [opacity, setOpacity] = useState(1);

  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [dimmer, setDimmer] = useState(false);
  const [changed, setChanged] = useState(false);
  const [open, setOpen] = useState("");
  const [isSetup, setIsSetup] = useState(false);

  const [myStyle, setMyStyle] = useState<any>({});

  const currentStyles = useSelector<RootState, any>(
    (state) => state.currentBook?.styles
  );

  const help = useSelector<RootState, any>((state) => state.help);

  const { getRootProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      accept: imageTypes,
      noClick: true,
      onDrop: async (acceptedFiles: any, _, event: any) => {
        event?.preventDefault();

        setTimeout(async () => {
          const nfiles = acceptedFiles.map((file: any) => {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
          });

          if (nfiles[0].size > 15000000) {
            tooLarge("15");
            return;
          }
          setDimmer(true);
          setChanged(true);
          //@ts-ignore
          if (event.target.id === "lands") {
            await uploadGCFile(
              getDirectory("statusbackground"),
              nfiles[0],
              setBackgroundLandscape,
              setDimmer,
              () => {}
            );
          } else {
            await uploadGCFile(
              getDirectory("statusbackground"),
              nfiles[0],
              setBackgroundPortrait,
              setDimmer,
              () => {}
            );
          }
        }, 0);
      },
    });

  const styleList = useMemo(() => {
    const list = [
      ...Object.keys(currentStyles || {})
        .filter((style: any) => currentStyles[style].type === "background")
        .map((style: any) => ({
          ...currentStyles[style],
          label: currentStyles[style].name,
          value: currentStyles[style].name,
        })),
    ];

    return list;
  }, [currentStyles]);

  const selectStyle = (a: any) => {
    setMyStyle(a);
    setBackgroundPortrait(a?.portrait || "");
    setBackgroundLandscape(a?.landscape || "");
    setBackgroundColor(a?.background || "");
    setChanged(false);
    setOpacity(a?.o || 0.3);
  };

  useEffect(() => {
    if (isSetup || !styleList.length || !props.open) {
      return;
    }

    selectStyle(styleList.find((item: any) => item.value === props.orig));
    setIsSetup(true);
  }, [isSetup, styleList, props.orig, props.open]);

  useEffect(() => {
    if (!props.open) {
      setIsSetup(false);
    }
  }, [props.open]);

  const selectIt = (a: any) => {
    if (open === "portrait") {
      setBackgroundPortrait(a);
    } else {
      setBackgroundLandscape(a);
    }
    setOpen("");
  };

  const openGallery = (e: any) => {
    if (e.target.id === "lands") {
      setOpen("landscape");
    } else {
      setOpen("portrait");
    }
  };

  const newBackgroundAction = useCallback(
    (style: string) => {
      style = style.trim();

      const bab: any = {
        ...store.getState().currentBook,
        styles: {
          ...store.getState().currentBook?.styles,
          ["background-" + style]: {
            type: "background",
            name: style,
            portrait: backgroundPortrait || backgroundLandscape || "",
            landscape: backgroundLandscape || backgroundPortrait || "",
            background: backgroundColor || "#ffffff",
            o: opacity || 0.3,
          },
        },
      };

      setMyStyle({
        name: style,
        portrait: backgroundPortrait || backgroundLandscape || "",
        landscape: backgroundLandscape || backgroundPortrait || "",
        background: backgroundColor || "#ffffff",
        o: opacity || 0.3,
        value: style,
      });

      store.dispatch(setCurrentBookReducer(bab));
      setChanged(false);

      setTimeout(async () => {
        setMyStyle({
          value: style,
          label: style,
          portrait: backgroundPortrait || backgroundLandscape,
          landscape: backgroundLandscape || backgroundPortrait,
          background: backgroundColor || "#ffffff",
          o: opacity || 0.3,
        });

        await setDoc(
          doc(firestore, "/booksa/" + store.getState().currentBook?.id),
          {
            styles: {
              ["background-" + style]: {
                name: style,
                type: "background",
                portrait: backgroundPortrait || backgroundLandscape,
                landscape: backgroundLandscape || backgroundPortrait,
                background: backgroundColor || "#ffffff",
                o: opacity || 0.3,
              },
            },
          },
          { merge: true }
        );
      }, 0);
    },
    [backgroundColor, backgroundLandscape, backgroundPortrait, opacity]
  );

  const newBackground = useCallback(() => {
    store.dispatch(
      setConfirm2({
        header: tx("cwweerewakklswewwewewwe", "New background..."),
        confirmButton: tx("add", "Add"),
        onConfirm: newBackgroundAction,
        cancelButton: tx("cancel", "Cancel"),
        content: tx("assdooccswexdfd2wwewrereswe", "New background name:"),
        openPrompt: true,
        default: "",
      })
    );
  }, [newBackgroundAction]);

  const deleteBackground = () => {};

  const saveChanges = () => {
    const bab: any = {
      ...store.getState().currentBook,
      styles: {
        ...store.getState().currentBook?.styles,
        ["background-" + myStyle.value]: {
          type: "background",
          name: myStyle.value,
          portrait: backgroundPortrait || backgroundLandscape || "",
          landscape: backgroundLandscape || backgroundPortrait || "",
          background: backgroundColor || "#ffffff",
          o: opacity || 0.3,
        },
      },
    };

    store.dispatch(setCurrentBookReducer(bab));

    setTimeout(async () => {
      setMyStyle({
        value: myStyle.value,
        label: myStyle.label,
        portrait: backgroundPortrait || backgroundLandscape,
        landscape: backgroundLandscape || backgroundPortrait,
        background: backgroundColor || "#ffffff",
        o: opacity || 0.3,
      });

      await setDoc(
        doc(firestore, "/booksa/" + store.getState().currentBook?.id),
        {
          styles: {
            ["background-" + myStyle.value]: {
              name: myStyle.value,
              type: "background",
              portrait: backgroundPortrait || backgroundLandscape,
              landscape: backgroundLandscape || backgroundPortrait,
              background: backgroundColor || "#ffffff",
              o: opacity || 0.3,
            },
          },
        },
        { merge: true }
      );
    }, 0);
    setChanged(false);
  };

  const deletePortrait = () => {
    setBackgroundPortrait("");
    setChanged(true);
  };
  const deleteLandscape = () => {
    setBackgroundLandscape("");
    setChanged(true);
  };

  const onUpdate = (a: any) => {
    setOpacity(a / 100);
    if (!changed) {
      setChanged(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.open}
        id="bging"
        toggle={() => props.setOpen(false)}
        className={
          "modal-dialog-centered modal-contentabs middlesize2" +
          (help.open ? " showhelp" : "")
        }
      >
        <ModalHeader toggle={() => props.setOpen(false)}>
          {tx("kokowewwweew", "Background Editor")}
        </ModalHeader>
        <ModalBody style={{ overflow: "auto" }}>
          <Dimmer active={dimmer} inverted={true}>
            <Loader />
          </Dimmer>
          <Form>
            <Row className="mx-0">
              <Col sm="12" className="pl-0 pr-0">
                <Label for="nameVerticalIcons">
                  {tx("backkokok", "Background")}
                </Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{ flex: "1 1 200px", paddingRight: "8px" }}
                    id={"prselect"}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={myStyle}
                      isSearchable={true}
                      name="bing"
                      autoFocus
                      options={styleList}
                      onChange={selectStyle}
                      menuPlacement={"auto"}
                    />
                  </div>

                  <div>
                    <Button
                      className="pl-75 pr-75 mr-50"
                      outline
                      color="primary"
                      onClick={newBackground}
                      id="prnew"
                    >
                      {tx("new", "New")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      id="prdelete"
                      className="pl-75 pr-75"
                      outline
                      color="primary"
                      onClick={deleteBackground}
                    >
                      {tx("delete", "Delete")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
          <div
            style={{ textAlign: "center", display: "flex", flexWrap: "wrap" }}
            className="mt-1 mb-1"
          >
            <LeftDiv>
              {tx("porooqow", "Landscape")}{" "}
              {backgroundLandscape ? (
                <div
                  style={{
                    display: "inline-block",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={deleteLandscape}
                >
                  {tx("delete", "Delete")}
                </div>
              ) : null}
              <PicDiv
                {...getRootProps({
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                })}
                id="lands"
                className={"landscape"}
                background={backgroundLandscape || backgroundPortrait}
                o={opacity}
                onClick={openGallery}
              >
                {/* <input {...getInputProps()} id="mysewel" /> */}
              </PicDiv>
            </LeftDiv>
            <RightDiv>
              {tx("porooqweweow", "Portrait")}{" "}
              {backgroundPortrait ? (
                <div
                  style={{
                    display: "inline-block",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={deletePortrait}
                >
                  {tx("delete", "Delete")}
                </div>
              ) : null}
              <PicDiv
                {...getRootProps({
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                })}
                id="port"
                className={"portrait ml-1"}
                background={backgroundPortrait || backgroundLandscape}
                o={opacity}
                onClick={openGallery}
              >
                {/* <input {...getInputProps()} id="mysewel1" /> */}
              </PicDiv>
            </RightDiv>
          </div>
          <SettingsSlider
            header={tx("opaciii", "Opacity")}
            value={Math.ceil(opacity * 100)}
            min={0}
            max={100}
            change={onUpdate}
            sign={"px"}
          />
        </ModalBody>
        <ModalFooter>
          <div
            style={{ flex: "1 1 auto", display: "flex", alignItems: "center" }}
          >
            <div className="pr-1">{tx("bakkokokw", "Background: ")}</div>
            <CoverColor
              color={backgroundColor}
              setColor={setBackgroundColor}
              action={() => setChanged(true)}
              nomargin
            />
          </div>
          <Button
            style={{}}
            color="primary"
            onClick={saveChanges}
            disabled={!changed || !myStyle?.value}
          >
            {tx("save", "Save")}
          </Button>
        </ModalFooter>
      </Modal>
      <ImageDialog open={open} setOpen={setOpen} selectPic={selectIt} />
    </React.Fragment>
  );
});

export default BackgroundCreator;
