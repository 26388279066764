import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = false;

const openClipboardSlice = createSlice({
  initialState,
  name: "openClipboard",
  reducers: {
    setOpenClipboard: (state, action) => {
      return action.payload;
    },
  },
});

export const { setOpenClipboard } = openClipboardSlice.actions;

export default openClipboardSlice.reducer;
