import React from "react";
// @material-ui/core components
import styled from "styled-components";

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: baseline;
  padding-bottom: 40px;
`;

const DivleftDiv = styled.div`
  flex: 0 0 65px;
`;
const DivrightDiv = styled.div`
  flex: 1 1 auto;
`;
const MainTextDiv = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
  color: black;
  margin-bottom: 10px;
`;
const OptionTextDiv = styled.div`
  font-size: 18px;
  line-height: 135%;
  color: #5e5e5e;
`;

const XDiv = styled.div`
  font-weight: 700;
  color: var(--toolbar-background);
  font-size: 18px;
  text-align: center;
`;

let currentValue = 0;

const SettingsSlider = (props: any) => {
  const changeSetting = (event: any) => {
    if (currentValue !== event.target.value) {
      currentValue = event.target.value;
      props.change(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <HorizontalDiv>
        <DivleftDiv>
          <XDiv>
            {props.value}
            {props.add || ""}
          </XDiv>
        </DivleftDiv>
        <DivrightDiv>
          <MainTextDiv>{props.mainText}</MainTextDiv>
          <OptionTextDiv>
            <input
              type="range"
              id="volume"
              name="volume"
              min={props.min || "0.5"}
              max={props.max || "2"}
              step={props.step || "0.1"}
              value={props.value}
              onChange={changeSetting}
              style={{ width: "100%" }}
            ></input>
          </OptionTextDiv>
        </DivrightDiv>
      </HorizontalDiv>
    </React.Fragment>
  );
};

export default SettingsSlider;
