import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MouseType = {
  mouseX: null | number;
  mouseY: null | number;
};

const initialState: MouseType = {
  mouseX: null,
  mouseY: null,
};

const mouseSlice = createSlice({
  initialState,
  name: "mouse",
  reducers: {
    setMouse: (state: MouseType, action: PayloadAction<MouseType>) => {
      return action.payload;
    },
  },
});

export const { setMouse } = mouseSlice.actions;

export default mouseSlice.reducer;
