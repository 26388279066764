import { functions } from "firebaseUtil/firebaseUtil";
import React, { useEffect } from "react";
import { httpsCallableFromURL } from "firebase/functions";

const testMe = async () => {
  // const googleIt = httpsCallable(functions,"googleIt");
  const selector = httpsCallableFromURL(
    functions,
    "https://selector-okdm2bbxtq-uc.a.run.app"
  );

  console.time("a");
  // await googleIt({ test: true });
  // console.log("googleIt");
  // console.timeEnd("a");
  // console.time("a");

  console.time("a");
  await selector({ test: true });
  console.log("selector");
  console.timeEnd("a");
};

const FunctionRunner = (props: any) => {
  useEffect(() => {
    const inti = setInterval(testMe, 30000);
    testMe();
    return () => clearInterval(inti);
  }, []);

  return <div>Running</div>;
};

export default FunctionRunner;
