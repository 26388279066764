import store from "redux/store";

const perm: any = {
  gyakangol: [
    "library",
    "deleteexercise",
    "candelete",
    "testbutton",
    "vocabbutton",
    "selfassigned",
    "premium",
    "account",
    "clipboard",
  ],
  basestudent: [
    "chat",
    "calendar",
    "editbook",
    "jointeacher",
    "schedule",
    "account",
    "backbutton",
    "hasavatar",
    "clipboard",
    "library",
  ],
  baseteacher: [
    "clipboard",
    "chat",
    "library",
    "calendar",
    "schedule",
    "account",
    "backbutton",
    "hasavatar",
    "editbook",
  ],
};

export const canUse = (what: string, permissions: any) => {
  const tempPermissions: any =
    permissions ||
    (store.getState().currentUser?.type === "teacher"
      ? ["baseteacher"]
      : ["basestudent"]);

  let result = false;
  tempPermissions.forEach((item: any) => {
    if (perm[item].includes(what)) {
      result = true;
    }
  });

  return result;
};

export const baseUrl = (permissions: any) => {
  return canUse("chat", permissions) ? "/app/chat" : "/app/library";
};
