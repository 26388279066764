import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteField } from "firebase/firestore";

import { v4 } from "uuid";

const initialState: any = { list: [] };

const setEditorRenumber = (state: any, action: any) => {
  let levelNumbers: any[] = [];
  let levelTypes: any[] = [];

  const currentId = action.payload.target.closest(".sectionit")?.id;

  const editorId = action.payload.editorId;

  let thisIndex = (
    currentId ? state[editorId].content[currentId] : state[editorId]
  ).list.findIndex(
    (item: any) => item === (action.payload.itemId || action.payload.id)
  );

  let thisList = currentId
    ? state[editorId].content[currentId].list
    : state[editorId].list;

  let firstListIndex = -1;

  if (action.payload.delete) {
    firstListIndex = thisIndex;
  } else if (action.payload.origListType) {
    firstListIndex = thisIndex + 1;
  }

  for (let n = thisIndex; n > -1; n--) {
    if (!state[editorId].content[thisList[n]].listType) {
      break;
    }
    firstListIndex = n;
  }

  if (action.payload.origListType !== thisList[thisIndex]?.listType) {
    levelTypes[thisList[thisIndex].level] = thisList[thisIndex].listType;
  }

  while (state[editorId].content[thisList[firstListIndex]]?.listType) {
    const item = state[editorId].content[thisList[firstListIndex]];
    let thisType = "";

    if (levelTypes[item.level]) {
      thisType = levelTypes[item.level];
    } else {
      thisType = state[editorId].content[thisList[firstListIndex]].listType;
      levelTypes[item.level] = thisType;
    }

    const thisNumber =
      item.startNumber !== undefined
        ? item.startNumber
        : levelNumbers[item.level] === undefined
        ? 1
        : levelNumbers[item.level] + 1;

    levelNumbers[item.level] = thisNumber || 0;
    levelNumbers = levelNumbers.slice(0, item.level + 1);

    if (
      state[editorId].content[thisList[firstListIndex]].number !== thisNumber
    ) {
      state[editorId].content[thisList[firstListIndex]].number =
        thisNumber || 0;
      state[editorId].content[thisList[firstListIndex]].date = v4();
    }

    if (
      state[editorId].content[thisList[firstListIndex]].listType !== thisType
    ) {
      state[editorId].content[thisList[firstListIndex]].listType =
        thisType || "";
      state[editorId].content[thisList[firstListIndex]].date = v4();
    }

    firstListIndex++;
  }
};

const editorDataSlice = createSlice({
  initialState,
  name: "editorData",
  reducers: {
    setEditorDataRemove: (state: any, action: PayloadAction<any>) => {
      const editorId = action.payload.editorId;
      state[editorId] = undefined;
    },
    setEditorData: (state: any, action: PayloadAction<any>) => {
      const editorId = action.payload.editorId;
      state[editorId] = action.payload;
    },

    setEditorLine: (state: any, action: PayloadAction<any>) => {
      const editorId = action.payload.editorId;

      const origLevel = state[editorId].content[action.payload.id].level;
      const origListType = state[editorId].content[action.payload.id].listType;

      state[editorId].content[action.payload.id] = {
        ...action.payload.value,
        date: v4(),
      };

      if (action.payload.level) {
        delete state[editorId].content[action.payload.id].startNumber;
      }

      state[editorId].undoDate = Date.now();

      if (action.payload.norenumber) {
        return;
      }
      const target = document.getElementById("n" + action.payload.id);

      setEditorRenumber(state, {
        ...action,
        payload: {
          ...action.payload,
          target: target,
          origLevel: origLevel,
          origListType: origListType,
        },
      });
    },

    setEditorMakeSection: (state: any, action: PayloadAction<any>) => {
      const newId = v4();
      const editorId = action.payload.editorId;

      let types: any = [
        ...new Set([
          ...action.payload.activeId.map(
            (item: any) => state[editorId].content[item].parent || null
          ),
        ]),
      ];

      if (types.length > 1 || types[0]) {
        let currentSection: any =
          state[editorId].content[
            state[editorId].content[action.payload.activeId[0]].parent
          ]?.list || [];
        let currentParent =
          state[editorId].content[action.payload.activeId[0]].parent;
        let startParent = currentParent;
        let extracted: any = [];
        let beforeSection: any = [];

        const firstItem = state[editorId].list.findIndex(
          (item: any) =>
            item === action.payload.activeId[0] ||
            item === state[editorId].content[action.payload.activeId[0]].parent
        );
        const lastItem = state[editorId].list.findIndex(
          (item: any) =>
            item === action.payload.activeId.slice(-1)[0] ||
            item ===
              state[editorId].content[action.payload.activeId.slice(-1)[0]]
                .parent
        );

        let sectionStyle: any = currentParent
          ? { ...state[editorId].content[currentParent] }
          : {};

        if (
          currentSection.length &&
          currentSection.findIndex(
            (item: any) => item === action.payload.activeId[0]
          )
        ) {
          beforeSection = currentSection.slice(
            0,
            currentSection.findIndex(
              (item: any) => item === action.payload.activeId[0]
            )
          );
          currentSection = currentSection.slice(beforeSection.length);
        }

        for (let n = 0; n < action.payload.activeId.length; n++) {
          extracted.push(action.payload.activeId[n]);
          let active = state[editorId].content[action.payload.activeId[n]];

          if (active.parent !== currentParent) {
            if (
              currentParent &&
              (currentParent !== startParent || !beforeSection.length)
            ) {
              delete state[editorId].content[currentParent];
            }

            currentParent = active.parent;

            if (active.parent) {
              currentSection = state[editorId].content[currentParent].list;
              sectionStyle = currentParent
                ? { ...state[editorId].content[currentParent] }
                : {};
            } else {
              currentSection = [];
            }
          }

          if (currentParent) {
            currentSection = currentSection.filter(
              (item: any) => item !== action.payload.activeId[n]
            );
          } else {
            currentSection = [];
          }

          delete state[editorId].content[action.payload.activeId[n]].parent;
          state[editorId].content[action.payload.activeId[n]].date = v4();
        }

        if (beforeSection.length) {
          state[editorId].content[startParent].list = beforeSection;
          state[editorId].content[startParent].date = v4();
        }

        if (currentSection.length) {
          const newParent = v4();

          state[editorId].list = [
            ...state[editorId].list.slice(
              0,
              firstItem + (beforeSection.length ? 1 : 0)
            ),
            ...extracted,
            newParent,
            ...state[editorId].list.slice(lastItem + 1),
          ];

          currentSection.forEach((item: any) => {
            state[editorId].content[item].parent = newParent;
            state[editorId].content[item].date = v4();
          });

          state[editorId].content[newParent] = {
            ...sectionStyle,
            date: v4(),
            list: currentSection,
          };
        } else {
          state[editorId].list = [
            ...state[editorId].list.slice(
              0,
              firstItem + (beforeSection.length ? 1 : 0)
            ),
            ...extracted,
            ...state[editorId].list.slice(lastItem + 1),
          ];
        }
        state[editorId].date = v4();
        state[editorId].undoDate = Date.now();
        return;
      }

      action.payload.activeId.forEach((id: any) => {
        state[editorId].content[id].parent = newId;
        state[editorId].content[id].date = v4();
      });

      state[editorId].content[newId] = {
        list: [...action.payload.activeId],
        type: "section",
        class: "greysection",
        date: v4(),
      };

      const firstIndex = state[editorId].list.findIndex(
        (item: any) => item === action.payload.activeId[0]
      );

      const lastIndex = state[editorId].list.findIndex(
        (item: any) => item === action.payload.activeId.slice(-1)[0]
      );

      state[editorId].list = [
        ...state[editorId].list.slice(0, firstIndex),
        newId,
        ...state[editorId].list.slice(lastIndex + 1),
      ];
      state[editorId].date = v4();
      state[editorId].undoDate = Date.now();
    },

    setEditorAddLine: (state: any, action: PayloadAction<any>) => {
      const editorId = action.payload.editorId;
      const currentId = action.payload.target.closest(".sectionit")?.id;

      let thisIndex =
        (currentId
          ? state[editorId].content[currentId]
          : state[editorId]
        ).list.findIndex((item: any) => item === action.payload.itemId) +
        (action.payload.after ? 1 : 0);

      let thisList = currentId
        ? state[editorId].content[currentId].list
        : state[editorId].list;

      let tempList = [
        ...thisList.slice(0, thisIndex),
        action.payload.id,
        ...thisList.slice(thisIndex),
      ];

      if (currentId) {
        state[editorId].content[currentId].list = tempList;
        state[editorId].content[currentId].date = v4();
      } else {
        state[editorId].list = tempList;
      }

      state[editorId].date = v4();

      state[editorId].content[action.payload.id] = {
        ...action.payload.value,
        parent: currentId || null,
        date: v4(),
      };

      state[editorId].undoDate = Date.now();

      setEditorRenumber(state, action);
    },

    setEditorPaste: (state: any, action: PayloadAction<any>) => {
      const editorId = action.payload.editorId;
      const currentId = action.payload.target.closest(".sectionit")?.id;

      let thisIndex =
        (currentId
          ? state[editorId].content[currentId]
          : state[editorId]
        ).list.findIndex((item: any) => item === action.payload.id) +
        (action.payload.after ? 1 : 0);

      let thisList = currentId
        ? state[editorId].content[currentId].list
        : state[editorId].list;

      let newList: any = { list: [], content: {} };

      action.payload.list.forEach((item: any, index: number) => {
        const newId = action.payload.newIds[index];

        newList.list.push(newId);
        newList.content[newId] = action.payload.content[item];
      });

      let tempList = [
        ...thisList.slice(0, thisIndex),
        ...newList.list,
        ...thisList.slice(thisIndex),
      ];

      if (currentId) {
        state[editorId].content[currentId].list = tempList;
        state[editorId].content[currentId].date = v4();
      } else {
        state[editorId].list = tempList;
      }

      state[editorId].date = v4();

      newList.list.forEach((item: any) => {
        if (currentId) {
          state[editorId].content[item] = {
            ...newList.content[item],
            date: v4(),
            parent: currentId,
          };
        } else {
          state[editorId].content[item] = {
            ...newList.content[item],
            date: v4(),
          };
        }
      });

      state[editorId].undoDate = Date.now();

      setEditorRenumber(state, action);
    },

    setEditorAddOnlyLine: (state: any, action: PayloadAction<any>) => {
      const newId = action.payload.id;
      const editorId = action.payload.editorId;

      state[editorId].content[newId] = {
        date: v4(),
        type: "paragraph",
        name: "Body",
        text: "",
      };
      state[editorId].list = [newId];

      state[editorId].date = v4();

      state[editorId].undoDate = Date.now();
    },

    setEditorDeleteLine: (state: any, action: PayloadAction<any>) => {
      const currentId = action.payload.target?.closest(".sectionit")?.id || "";
      const editorId = action.payload.editorId;

      const usedList = currentId
        ? [...state[editorId].content[currentId].list]
        : state[editorId].list;

      const tempList = usedList.filter(
        (item: any) => item !== action.payload.id
      );

      if (tempList.length === 0 && currentId) {
        state[editorId].list = state[editorId].list.filter(
          (id: any) => id !== currentId
        );
        state[editorId].content[currentId] = deleteField();
        state[editorId].date = v4();
        return;
      }

      if (currentId) {
        state[editorId].content[currentId].list = tempList;
        state[editorId].content[currentId].date = v4();
      } else {
        state[editorId].list = tempList;
        state[editorId].date = v4();
      }

      state[editorId].content[action.payload.id] = deleteField();

      if (action.payload.target) {
        setEditorRenumber(state, { ...action, delete: true });
      }

      state[editorId].undoDate = Date.now();
    },
    setEditorDataStyles: (state, action) => {
      const editorId = action.payload.editorId;
      state[editorId].styles[action.payload.name] = action.payload.data;
      state[editorId].undoDate = Date.now();
      state[editorId].date = v4();
    },
    setEditorDataDefaults: (state, action) => {
      const editorId = action.payload.editorId;
      if (state[editorId].styles.defaults) {
        state[editorId].styles.defaults[action.payload.element] =
          action.payload.name;
      } else {
        state[editorId].styles.defaults = {
          [action.payload.element]: action.payload.name,
        };
      }
      state[editorId].undoDate = Date.now();
      state[editorId].date = v4();
    },
  },
});

export const {
  setEditorData,
  setEditorDataDefaults,
  setEditorLine,
  setEditorAddLine,
  setEditorDataStyles,
  setEditorDeleteLine,
  setEditorMakeSection,
  setEditorDataRemove,
  setEditorPaste,
  setEditorAddOnlyLine,
} = editorDataSlice.actions;

export default editorDataSlice.reducer;
