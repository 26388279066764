//student
import { firestore } from "firebaseUtil/firebaseUtil";
import { setHelpOpen } from "redux/reducers/reducer.help";
import { setInviteOpen } from "redux/reducers/reducer.inviteOpen";
import { setAutoRoute } from "redux/reducers/reducer.routeMe";
// import { setChat } from "redux/reducers/reducer.chat";
import { setSidebarOpen } from "redux/reducers/reducer.sidebarOpen";
import store from "redux/store";
import { setUserGlobal } from "utils/setUser";

import { Device } from "@capacitor/device";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { Toast } from "@capacitor/toast";
import { doc, setDoc } from "firebase/firestore";

let pushId: string;
let userId: string;

export const setupPushNotification = async (currentUser: any) => {
  if (currentUser?.id === userId || !currentUser?.id) {
    return;
  }

  let info: any = undefined;

  try {
    info = await Device.getInfo();
  } catch (e) {
    console.log(e);
  }

  if (info.platform === "web") {
    return;
  }

  userId = currentUser?.id;

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM

      PushNotifications.register();
    } else {
      // Show some error
    }
  });

  PushNotifications.addListener("registration", (token: Token) => {
    if (!(currentUser.pushReg || []).includes(token.value)) {
      setDoc(
        doc(firestore, "/users/" + currentUser.id),
        { pushReg: [...(currentUser.pushReg || []).slice(5), token.value] },
        { merge: true }
      );
    }
    pushId = token.value;
  });

  PushNotifications.addListener("registrationError", (error: any) => {
    console.log("Error on registration: " + JSON.stringify(error));
  });

  PushNotifications.addListener(
    "pushNotificationReceived",
    async (notification: PushNotificationSchema) => {
      if (
        store.getState().userSetup.you.id !== notification.data?.senderId &&
        !store.getState().fakeStudent
      ) {
        await Toast.show({
          text: notification.body || "",
        });
      }
    }
  );

  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification: ActionPerformed) => {
      if (notification.notification.data.userList) {
        store.dispatch(setHelpOpen(""));
        store.dispatch(setInviteOpen(""));
        store.dispatch(setSidebarOpen(true));
        store.dispatch(setAutoRoute("/app/chat"));
      } else {
        if (notification.notification.data.invited) {
          setTimeout(() => {
            setUserGlobal(
              notification.notification.data.senderId,
              store.getState().users
            );
          }, 2000);
        } else {
          setTimeout(() => {
            setUserGlobal(
              notification.notification.data.senderId,
              store.getState().users
            );
          }, 2000);
        }
        // store.dispatch(setChat([]));
        store.dispatch(setHelpOpen(""));
        store.dispatch(setInviteOpen(""));
        store.dispatch(setSidebarOpen(false));
        store.dispatch(setAutoRoute("/app/chat"));
      }
    }
  );
};

export const removePushRegistration = async (currentUser: any) => {
  if (!currentUser?.id) {
    return;
  }

  const info = await Device.getInfo();

  if (info.platform === "web") {
    return;
  }

  PushNotifications.removeAllListeners();
  await setDoc(
    doc(firestore, "/users/" + currentUser.id),
    {
      pushReg: [
        ...(currentUser.pushReg || []).filter((item: any) => item !== pushId),
      ],
    },
    { merge: true }
  );

  userId = "";
};
