import GetApp from "app/App.getApp";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { setHelpOpen } from "redux/reducers/reducer.help";
import { setJoyrideVideo } from "redux/reducers/reducer.joyrideVideo";
import { setPointer } from "redux/reducers/reducer.pointer";
import { setRideSteps } from "redux/reducers/reducer.rideSteps";
import { setTutorialVarIndiv } from "redux/reducers/reducer.tutorialVars";
import { RootState } from "redux/reducers/_index";
import store from "redux/store";
import styled from "styled-components";
import { prepareFormat, tx } from "utils/globalize";
import { steps } from "./joyride";

const TooltipBody: any = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  font-size: 16px;
  max-width: 100%;
  padding: 15px;
  position: relative;
  width: 380px;
  &&.invis {
    display: none;
  }
`;

const TooltipContent: any = styled.div`
  padding: 20px 10px 17px 10px;
`;

const TooltipAction: any = styled.div`
  padding-top: 8px;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-bottom: 8px;
`;

const TooltipTitle: any = styled.div`
  padding: 10px 10px 10px 10px;
  font-weight: bold;
  font-size: 18px;
  color: var(--toolbar-background);
`;

const CloseButton: any = styled.button`
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 15px;
  appearance: none;
  position: absolute;
  right: 0px;
  top: 0px;
`;

const TooltipFooter: any = styled.div`
  align-items: center;
  display: flex;
`;

const Button: any = styled.button`
  background-color: var(--toolbar-background);
  border: 0px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 8px;
  margin-left: 10px;
  appearance: none;
  &:disabled {
    opacity: 0.5;
  }
`;

const GuideDiv = styled.div`
  color: blue;
  cursor: pointer;
  flex: 1 1 auto;
  font-size: 14px;
`;

const JoyToolTip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: any) => {
  const [nextDisabled, setNextDisabled] = useState(false);
  const [noNext, setNoNext] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const rideSteps = useSelector<RootState, any>((state) => state.rideSteps);
  const [nextText, setNextText] = useState("");

  useEffect(() => {
    setNoNext(!!step.noNext);
    const next = steps[index + 1];

    if (next.anchor && next.anchor === rideSteps.split("-")[1]) {
      setNextText(tx("txfw", "Finish"));
      return;
    }

    if (step.lastone) {
      setNextText(tx("txfw", "Finish"));
      return;
    }

    if (
      step.resume ||
      step.goto ||
      step.cont ||
      step.gosub ||
      (next && !next.act)
    ) {
      setNextText(tx("next", "Next"));
    } else {
      setNextText(tx("txfw", "Finish"));
    }
  }, [step, index, rideSteps]);

  useEffect(() => {
    if (step.action) {
      let action = Array.isArray(step.action) ? step.action : [step.action];

      let tempOptions = action.filter((act: any) => {
        if (!act.switch) {
          return true;
        }
        if (
          store.getState().tutorialVars[act.switch.var] === act.switch.value
        ) {
          return true;
        }
        return false;
      });

      setOptions(tempOptions);
    } else {
      setOptions([]);
    }
  }, [step.action]);

  const closeThis = () => {
    store.dispatch(setRideSteps(""));
    if (store.getState().help.open === null) {
      store.dispatch(setHelpOpen("fasdf"));
    }
  };

  useEffect(() => {
    if (step.required) {
      const targetNode = document.querySelector(step.target);

      setNextDisabled(!targetNode.value);

      // Options for the observer (which mutations to observe)
      const config = { attributes: true };

      // Callback function to execute when mutations are observed
      const callback = function (mutationList: any, observer: any) {
        // Use traditional 'for loops' for IE 11
        for (const mutation of mutationList) {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "value"
          ) {
            if (targetNode.value) {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          }
        }
      };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config);

      // Later, you can stop observing
      return () => observer.disconnect();
    }
  }, [step]);

  const newAction = (e: any, action: any) => {
    e.stopPropagation();

    if (action.includes("xxx")) {
      store.dispatch(setRideSteps(action));
      return;
    }

    if (action.includes("nobooks")) {
      if (
        Object.keys(store.getState().myBooks).filter((item: any) =>
          item.indexOf("xrx-")
        ).length
      ) {
        action = action.replace("nobooks", "");
      }
    }

    if (action === "helpoptions") {
      store.dispatch(setHelpOpen("fasdf"));
      store.dispatch(setRideSteps(""));
      return;
    }

    if (!action) {
      store.dispatch(setRideSteps(""));
      if (store.getState().help.open === null) {
        store.dispatch(setHelpOpen("fasdf"));
      }
      return;
    }

    store.dispatch(setTutorialVarIndiv({ var: "thisRide", value: action }));

    if (action === "nextnext") {
      store.getState().rideActions.skip();
      return;
    }

    store.dispatch(setRideSteps(action));

    action = action.split("-")[0].trim();

    store.dispatch(
      setPointer(
        steps.findIndex(
          (item: any) => item.anchor === action || item.act === action
        )
      )
    );
  };

  const showVideo = () => {
    store.dispatch(setJoyrideVideo(step.video));
  };

  const goNext = () => {
    store.getState().rideActions.next();
    if (nextText === tx("txfw", "Finish")) {
      store.dispatch(setRideSteps(""));
      if (store.getState().help.open === null) {
        store.dispatch(setHelpOpen("asdasdf"));
      }
    }
  };

  return (
    <React.Fragment>
      <TooltipBody
        className={rideSteps === "bubu" ? "invis" : ""}
        {...tooltipProps}
        onKeyDown={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <CloseButton onClick={closeThis} tabIndex="-1">
          <svg
            width="14px"
            height="14px"
            viewBox="0 0 18 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
          >
            <g>
              <path
                d="M8.13911129,9.00268191 L0.171521827,17.0258467 C-0.0498027049,17.248715 -0.0498027049,17.6098394 0.171521827,17.8327545 C0.28204354,17.9443526 0.427188206,17.9998706 0.572051765,17.9998706 C0.71714958,17.9998706 0.862013139,17.9443526 0.972581703,17.8327545 L9.0000937,9.74924618 L17.0276057,17.8327545 C17.1384085,17.9443526 17.2832721,17.9998706 17.4281356,17.9998706 C17.5729992,17.9998706 17.718097,17.9443526 17.8286656,17.8327545 C18.0499901,17.6098862 18.0499901,17.2487618 17.8286656,17.0258467 L9.86135722,9.00268191 L17.8340066,0.973848225 C18.0553311,0.750979934 18.0553311,0.389855532 17.8340066,0.16694039 C17.6126821,-0.0556467968 17.254037,-0.0556467968 17.0329467,0.16694039 L9.00042166,8.25611765 L0.967006424,0.167268345 C0.745681892,-0.0553188426 0.387317931,-0.0553188426 0.165993399,0.167268345 C-0.0553311331,0.390136635 -0.0553311331,0.751261038 0.165993399,0.974176179 L8.13920499,9.00268191 L8.13911129,9.00268191 Z"
                fill="#333"
              ></path>
            </g>
          </svg>
        </CloseButton>
        {step.title && (
          <TooltipTitle>{tx(step.transtitle, step.title)}</TooltipTitle>
        )}
        <TooltipContent>
          <div style={{ marginBottom: "10px" }}>
            {prepareFormat(tx(step.trans, step.content))}
          </div>
          {step.video && (
            <TooltipAction onClick={showVideo}>
              {tx("seevid", "See video...")}
            </TooltipAction>
          )}

          {options?.map((act: any, index: number) => (
            <TooltipAction
              key={index}
              onClick={(e: any) => newAction(e, act.newride)}
            >
              {tx(act.question.trans, act.question.text)}
            </TooltipAction>
          ))}
          <GetApp />
        </TooltipContent>
        <TooltipFooter>
          <GuideDiv />
          {step.noNext ? (
            <div style={{ textAlign: "center" }}>
              <img src="/images/click.gif" alt="clicking" width="30px" />
            </div>
          ) : null}
          {index > 0 && !step.hideBackButton && (
            <Button {...backProps}>
              <span id="back">{tx("backxxxxm", "Back")}</span>
            </Button>
          )}
          {!noNext && (
            <Button
              {...primaryProps}
              disabled={nextDisabled}
              data-action=""
              tabIndex="-1"
              onClick={goNext}
            >
              <span id="next">{nextText}</span>
            </Button>
          )}
          {/* {!continuous && (
            <Button {...closeProps}>
              <span id="close">Close</span>
            </Button>
          )} */}
        </TooltipFooter>
      </TooltipBody>
    </React.Fragment>
  );
};

export default JoyToolTip;
