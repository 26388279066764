import { createSlice } from "@reduxjs/toolkit";
import { baseStyles, baseTheme } from "blockEditor/utils/baseStyles";

// ** Initial State
const initialState: any = {
  basics: {},
  toc: [],
  cats: {},
  content: {},
  styles: baseStyles,
  theme: baseTheme,
};

const currentBookSlice = createSlice({
  initialState,
  name: "currentBook",
  reducers: {
    setCurrentBookReducer: (state, action) => {
      return action.payload;
    },
    setCurrentBookStyles: (state, action) => {
      state.styles[action.payload.name] = action.payload.data;
    },
  },
});

export const { setCurrentBookReducer, setCurrentBookStyles } =
  currentBookSlice.actions;

export default currentBookSlice.reducer;
