import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: [] = [];

const validHotkeysSlice = createSlice({
  initialState,
  name: "validHotkeys",
  reducers: {
    setValidHotkeys: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setValidHotkeys } = validHotkeysSlice.actions;

export default validHotkeysSlice.reducer;
