import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { query: "", open: false };

const dictionarySlice = createSlice({
  initialState,
  name: "dictionaryOpen",
  reducers: {
    setDictionary: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setDictionaryOpen: (state: any, action: PayloadAction<any>) => {
      state.open = action.payload;
    },
    setDictionaryQuery: (state: any, action: PayloadAction<any>) => {
      state.query = action.payload;
    },
  },
});

export const { setDictionaryOpen, setDictionary, setDictionaryQuery } =
  dictionarySlice.actions;

export default dictionarySlice.reducer;
