import React from "react";
import { setConfirm } from "redux/reducers/reducer.confirm";
import store from "redux/store";
import { tx } from "./globalize";
import { validMember } from "./validMember";
import styled from "styled-components";
import { appPayPay, webPay } from "./payments";
import { platform } from "./drive";
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";

const WarnDiv = styled.div`
  padding-bottom: 8px;
`;

const subscribeNow = () => {
  if (platform === "web") {
    webPay(store.getState().currentUser?.id);
    // alert(
    //   "Paid packages are coming soon! Right now enjoy everything for free."
    // );
  } else {
    appPayPay(store.getState().currentUser?.id);
  }
};

export const outOfOCRDialog = () => {
  store.dispatch(
    setConfirm({
      confirmButton: tx("HPF7diTALiiuwewei1wein", "See Options"),
      onConfirm: subscribeNow,
      header: tx("Mr53mXRBQSXX1ifXgZas", "Out of credit..."),
      cancelButton: "Cancel",
      content: (
        <React.Fragment>
          <WarnDiv>
            {tx(
              "hiwerr",
              "Hey! You've run out of credit for transcription and text recognition."
            )}
          </WarnDiv>
          {validMember() ? (
            <WarnDiv>
              {tx(
                "hiwerr2",
                "You can buy extra minutes and packages of text recognition by clicking on the See Options button."
              )}
            </WarnDiv>
          ) : (
            <WarnDiv>
              {tx(
                "hiwerr3",
                "Upgrade to a paid membership clicking on the See Options button to receive 1000 credits every month."
              )}
            </WarnDiv>
          )}
        </React.Fragment>
      ),
      open: true,
    })
  );
};

export const windowsUpdate = () => {
  store.dispatch(
    setConfirm({
      confirmButton: tx("HPF7diTALiiuwewei1wein", "See Options"),
      onConfirm: () => alert("Function coming soon..."),
      header: tx("MrmXRBQS343XX1ifXgZas", "Out of credit..."),
      cancelButton: tx("cancel", "Cancel"),
      content: (
        <React.Fragment>
          <WarnDiv>
            {tx(
              "hiwerr",
              "Hey! You've run out of credit for transcription and text recognition."
            )}
          </WarnDiv>
          {validMember() ? (
            <WarnDiv>
              {tx(
                "hiwerr2",
                "You can buy extra minutes and packages of text recognition by clicking on the See Options button."
              )}
            </WarnDiv>
          ) : (
            <WarnDiv>
              {tx(
                "hiwerr3",
                "Upgrade to a paid membership clicking on the See Options button to receive 1000 credits every month."
              )}
            </WarnDiv>
          )}
        </React.Fragment>
      ),
      open: true,
    })
  );
};

export const outOfStorageQuota = () => {
  store.dispatch(
    setConfirm({
      confirmButton: tx("HPF7diTALiiuwewei1weinks", "Buy Subscription"),
      onConfirm: subscribeNow,
      header: tx("MrmXRBQSXX1ifXgZweas", "Out of storage..."),
      cancelButton: tx("cancel", "Cancel"),
      content: (
        <React.Fragment>
          <WarnDiv>
            {tx(
              "youruncsdd",
              "Hey! You've exceeded your 50MB upload quota for free members."
            )}
          </WarnDiv>

          <WarnDiv>
            {tx(
              "unlimitwe",
              "Click on the See Options button for professional membership and unlimited upload amount."
            )}
          </WarnDiv>
        </React.Fragment>
      ),
      open: true,
    })
  );
};

export const upgradeDialog = () => {
  let pricing: any = null;

  if (platform === "ios") {
    const product = InAppPurchase2.get("inisharemonthly2");

    pricing = (
      <div
        style={{ paddingTop: "5px", textAlign: "center", paddingBottom: "5px" }}
      >
        <div>Pro Subscription price:</div>
        <div
          className="text-dark-toolbar"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {product.price + " / month"}
        </div>
      </div>
    );
  }

  store.dispatch(
    setConfirm({
      confirmButton: tx("HPF7diTALiiuwewei1weiwen", "Go to Payment"),
      onConfirm: subscribeNow,
      header: tx("MrmXRBQSXX1ifXgZweawes", "Upgrade Account"),
      cancelButton: tx("cancel", "Cancel"),
      content: (
        <React.Fragment>
          <WarnDiv>
            {tx(
              "uprade3wewesdwewe",
              "Get a professional account to be able to upload more than 50 MB worth of files and share your books with unlimited number of people."
            )}
          </WarnDiv>

          {pricing}

          <WarnDiv>
            {tx("diowercw", "Click on the Go to Payment button to subscribe.")}
          </WarnDiv>
        </React.Fragment>
      ),
      open: true,
    })
  );
};

export const outOfOpenNotes = () => {
  store.dispatch(
    setConfirm({
      confirmButton: tx("HPF7diweTALiiuwewei1wein", "See Options"),
      onConfirm: () => alert("Function coming soon..."),
      header: tx("MrmXRBQSXX1ifXgZwweeas", "Free Account Limit..."),
      cancelButton: "Cancel",
      content: (
        <React.Fragment>
          <WarnDiv>
            {tx(
              "onefree",
              "Hey! With a Free Acount you can only open two notes at the same time."
            )}
          </WarnDiv>

          <WarnDiv>
            {tx(
              "clickowe",
              "Click on the See Options button for a Subscription and unlimited open notes."
            )}
          </WarnDiv>

          <WarnDiv>
            {tx(
              "kcoswer",
              "Or close one of the open note windows and open a new one."
            )}
          </WarnDiv>
        </React.Fragment>
      ),
      open: true,
    })
  );
};
