export const audioTypes: any = {
  "audio/aac": [".aac"],
  "audio/mpeg": [".mp3"],
};

export const imageTypes: any = {
  "image/avif": [".avif"],
  "image/bmp": [".bmp"],
  "image/gif": [".gif"],
  "image/vnd.microsoft.icon": [".ico"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/svg+xml": [".svg"],
  "image/tiff": [".tif", ".tiff"],
  "video/webm": [".webm"],
};

export const epubTypes: any = {
  "application/epub+zip": [".epub"],
};

export const documentTypes: any = {
  "text/css": [".css"],
  "text/csv": [".csv"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "text/html": [".htm", ".html"],
  "application/pdf": [".pdf"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "application/rtf": [".rtf"],
  "text/plain": [".txt"],
  "image/webp": [".webp"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/xml": [".xml"],
  "text/xml": [".xml"],
};

export const allTypes: any = {
  "audio/aac": [".aac"],
  "application/x-abiword": [".abw"],
  "application/x-freearc": [".arc"],
  "image/avif": [".avif"],
  "video/x-msvideo": [".avi"],
  "application/vnd.amazon.ebook": [".azw"],
  "application/octet-stream": [".bin"],
  "image/bmp": [".bmp"],
  "application/x-bzip": [".bz"],
  "application/x-bzip2": [".bz2"],
  "application/x-cdf": [".cda"],
  "application/x-csh": [".csh"],
  "text/css": [".css"],
  "text/csv": [".csv"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.ms-fontobject": [".eot"],
  "application/epub+zip": [".epub"],
  "application/gzip": [".gz"],
  "image/gif": [".gif"],
  "text/html": [".htm", ".html"],
  "image/vnd.microsoft.icon": [".ico"],
  "text/calendar": [".ics"],
  "application/java-archive": [".jar"],
  "image/jpeg": [".jpeg", ".jpg"],
  "text/javascript": [".js"],
  "application/json": [".json"],
  "application/ld+json": [".jsonld"],
  "audio/mpeg": [".mp3"],
  "video/mp4": [".mp4"],
  "video/mpeg": [".mpeg"],
  "application/vnd.apple.installer+xml": [".mpkg"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "audio/ogg": [".oga"],
  "video/ogg": [".ogv"],
  "application/ogg": [".ogx"],
  "audio/opus": [".opus"],
  "font/otf": [".otf"],
  "image/png": [".png"],
  "application/pdf": [".pdf"],
  "application/x-httpd-php": [".php"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "application/vnd.rar": [".rar"],
  "application/rtf": [".rtf"],
  "application/x-sh": [".sh"],
  "image/svg+xml": [".svg"],
  "application/x-shockwave-flash": [".swf"],
  "application/x-tar": [".tar"],
  "image/tiff": [".tif", ".tiff"],
  "video/mp2t": [".ts"],
  "font/ttf": [".ttf"],
  "text/plain": [".txt"],
  "application/vnd.visio": [".vsd"],
  "audio/wav": [".wav"],
  "audio/webm": [".weba"],
  "video/webm": [".webm"],
  "image/webp": [".webp"],
  "font/woff": [".woff"],
  "font/woff2": [".woff2"],
  "application/xhtml+xml": [".xhtml"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/xml": [".xml"],
  "text/xml": [".xml"],
  "application/vnd.mozilla.xul+xml": [".xul"],
  "application/zip": [".zip"],
  "application/x-7z-compressed": [".7z"],
};

// //eelint-disable-next
// const all = {
//   "audio/aac": [".aac"],
//   "application/x-abiword": [".abw"],
//   "application/x-freearc": [".arc"],
//   "image/avif": [".avif"],
//   "video/x-msvideo": [".avi"],
//   "application/vnd.amazon.ebook": [".azw"],
//   "application/octet-stream": [".bin"],
//   "image/bmp": [".bmp"],
//   "application/x-bzip": [".bz"],
//   "application/x-bzip2": [".bz2"],
//   "application/x-cdf": [".cda"],
//   "application/x-csh": [".csh"],
//   "text/css": [".css"],
//   "text/csv": [".csv"],
//   "application/msword": [".doc"],
//   "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
//     ".docx",
//   ],
//   "application/vnd.ms-fontobject": [".eot"],
//   "application/epub+zip": [".epub"],
//   "application/gzip": [".gz"],
//   "image/gif": [".gif"],
//   "text/html": [".htm", ".html"],
//   "image/vnd.microsoft.icon": [".ico"],
//   "text/calendar": [".ics"],
//   "application/java-archive": [".jar"],
//   "image/jpeg": [".jpeg", ".jpg"],
//   "text/javascript": [".js"],
//   "application/json": [".json"],
//   "application/ld+json": [".jsonld"],
//   "audio/mpeg": [".mp3"],
//   "video/mp4": [".mp4"],
//   "video/mpeg": [".mpeg"],
//   "application/vnd.apple.installer+xml": [".mpkg"],
//   "application/vnd.oasis.opendocument.presentation": [".odp"],
//   "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
//   "application/vnd.oasis.opendocument.text": [".odt"],
//   "audio/ogg": [".oga"],
//   "video/ogg": [".ogv"],
//   "application/ogg": [".ogx"],
//   "audio/opus": [".opus"],
//   "font/otf": [".otf"],
//   "image/png": [".png"],
//   "application/pdf": [".pdf"],
//   "application/x-httpd-php": [".php"],
//   "application/vnd.ms-powerpoint": [".ppt"],
//   "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
//     ".pptx",
//   ],
//   "application/vnd.rar": [".rar"],
//   "application/rtf": [".rtf"],
//   "application/x-sh": [".sh"],
//   "image/svg+xml": [".svg"],
//   "application/x-shockwave-flash": [".swf"],
//   "application/x-tar": [".tar"],
//   "image/tiff": [".tif", ".tiff"],
//   "video/mp2t": [".ts"],
//   "font/ttf": [".ttf"],
//   "text/plain": [".txt"],
//   "application/vnd.visio": [".vsd"],
//   "audio/wav": [".wav"],
//   "audio/webm": [".weba"],
//   "video/webm": [".webm"],
//   "image/webp": [".webp"],
//   "font/woff": [".woff"],
//   "font/woff2": [".woff2"],
//   "application/xhtml+xml": [".xhtml"],
//   "application/vnd.ms-excel": [".xls"],
//   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
//     ".xlsx",
//   ],
//   "application/xml": [".xml"],
//   "text/xml": [".xml"],
//   "application/vnd.mozilla.xul+xml": [".xul"],
//   "application/zip": [".zip"],
//   "application/x-7z-compressed": [".7z"],
// };
