import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const confirm2Slice = createSlice({
  initialState: { open: false } as any,
  name: "confirm2",
  reducers: {
    setConfirm2: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setConfirm2Open: (state: any, action: PayloadAction<boolean>) => {
      state.open = action.payload;
      state.openPrompt = action.payload;
    },
    setConfirm2Default: (state: any, action: PayloadAction<string>) => {
      state.default = action.payload;
    },
  },
});

export const { setConfirm2, setConfirm2Default, setConfirm2Open } =
  confirm2Slice.actions;

export default confirm2Slice.reducer;
