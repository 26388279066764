import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = "";

const autoRouteSlice = createSlice({
  initialState,
  name: "autoRoute",
  reducers: {
    setAutoRoute: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAutoRoute } = autoRouteSlice.actions;

export default autoRouteSlice.reducer;
