import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const newWordOpenSlice = createSlice({
  initialState: false,
  name: "newWordOpen",
  reducers: {
    setNewWordOpen: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setNewWordOpen } = newWordOpenSlice.actions;

export default newWordOpenSlice.reducer;
