import { updateSetting } from "firebaseUtil/firebaseUtil.firestore";
// import { helpEditorOpener } from "help/helpEditor.opener";
import { steps } from "joyride/joyride";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import { setPointer } from "redux/reducers/reducer.pointer";
import { setRideSteps } from "redux/reducers/reducer.rideSteps";
import { RootState } from "redux/reducers/_index";
import store from "redux/store";
import styled from "styled-components";
import { tx } from "utils/globalize";

const TextDiv = styled.div`
  font-size: 16px;
  padding: 10px;
`;

const ButtonDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: right;
`;

const AppFirstTime = (props: any) => {
  const settings = useSelector<RootState, any>((state) => state.settings);
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const [open, setOpen] = useState(false);

  const loadIntro = (accept: boolean) => {
    setTimeout(() => {
      let target = "intro";

      if (
        localStorage.getItem("bookby" + store.getState().currentUser?.id) ===
        "0"
      ) {
        target = "introsamples";
      }

      store.dispatch(setRideSteps(target));
      store.dispatch(
        setPointer(steps.findIndex((item: any) => item.act === target))
      );

      // store.dispatch(setHelpContent(<WelcomeTeacher />));

      updateSetting({ second: true, acceptNL: accept });
    }, 200);
  };

  useEffect(() => {
    // const showInvite = async () => {
    //   store.dispatch(setInviteOpen(currentUser?.invitee));
    // };

    if (settings && settings.loaded && !settings.second) {
      setTimeout(() => {
        setOpen(true);
      }, 1500);
    }

    // if (currentUser?.invitee && currentUser?.type === "student") {
    //   showInvite();
    // }
  }, [settings, currentUser?.invitee, currentUser?.id, currentUser?.type]);

  const clickNext = () => {
    setOpen(false);
    loadIntro(false);
  };

  const clickAccept = () => {
    setOpen(false);
    loadIntro(true);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={open}
        toggle={clickNext}
        className={"modal-dialog-centered prompter"}
      >
        <ModalBody className="pt-1">
          <TextDiv
            style={{ color: "var(--toolbar-background", fontWeight: "bold" }}
          >
            {tx("PEgiweipg_dbm7liZnO", "You're almost there...")}{" "}
          </TextDiv>
          <TextDiv>
            {tx("PweEgiweipg_dbm7liZnO", "Can we keep in touch?")}{" "}
            {tx(
              "PweEgiweipg_dbm7liZwenO",
              "We would like to send you tips on how to get the most out of IniShare and any improvements we add to the app."
            )}{" "}
            {tx(
              "PwweeEgiweipg_dbm7liZwenO",
              "Even in crazy times this means maximum 1 email per week and you can unsusbscribe at any time."
            )}
          </TextDiv>

          <ButtonDiv>
            <Button color="white" outlined onClick={clickNext}>
              {tx("cancokokwekow", "Not now, please")}
            </Button>
            <Button
              color="primary"
              onClick={clickAccept}
              className="bg-toolbar text-toolbar"
            >
              {tx("cancelwewe", "Sure, why not")}
            </Button>
          </ButtonDiv>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AppFirstTime;
