import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { tx } from "utils/globalize";
import PicGallery from "./picGallery";

const ImageDialog = (props: any) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={!!props.open}
        toggle={() => props.setOpen("")}
        className={"modal-dialog-centered middlesizeold xgallery"}
      >
        <ModalHeader toggle={() => props.setOpen("")}>
          {tx("imagesel", "Image Gallery")}
        </ModalHeader>
        <ModalBody style={{ height: "calc(100% - 60px)", overflowY: "auto" }}>
          <PicGallery selectPic={props.selectPic} />
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ float: "right" }}
            color="primary"
            onClick={() => {}}
            outline
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ImageDialog;
