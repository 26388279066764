import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const tempLocalSlice = createSlice({
  initialState,
  name: "tempLocal",
  reducers: {
    setTempLocal: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setTempLocalComments: (state: any, action: PayloadAction<any>) => {
      state.comments = action.payload;
    },
    setTempLocalResults: (state: any, action: PayloadAction<any>) => {
      state.results = action.payload;
    },
  },
});

export const { setTempLocal, setTempLocalResults, setTempLocalComments } =
  tempLocalSlice.actions;

export default tempLocalSlice.reducer;
