import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any =
  process.env.NODE_ENV === "production" ? "videwoing" : "videwoing";

const videoingSlice = createSlice({
  initialState,
  name: "videoing",
  reducers: {
    setVideoing: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setVideoing } = videoingSlice.actions;

export default videoingSlice.reducer;
