import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = "";

const mainloadingSlice = createSlice({
  initialState,
  name: "mainLoading",
  reducers: {
    setMainloading: (state, action) => {
      return action.payload;
    },
  },
});

export const { setMainloading } = mainloadingSlice.actions;

export default mainloadingSlice.reducer;
