import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = [];

const newAssignmentSlice = createSlice({
  initialState,
  name: "newAssignment",
  reducers: {
    setNewAssignmentGlobal: (state: string, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setNewAssignmentGlobal } = newAssignmentSlice.actions;

export default newAssignmentSlice.reducer;
