import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const tutorialVarsSlice = createSlice({
  initialState,
  name: "tutorialVars",
  reducers: {
    setTutorialVars: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    resetTutorialVars: (state: any, action: PayloadAction<any>) => {
      return {};
    },
    setTutorialVarIndiv: (state: any, action: PayloadAction<any>) => {
      state[action.payload.var] = action.payload.value;
    },
  },
});

export const { setTutorialVars, resetTutorialVars, setTutorialVarIndiv } =
  tutorialVarsSlice.actions;

export default tutorialVarsSlice.reducer;
