import { cloudStorage } from "firebaseUtil/firebaseUtil";
import { Howl } from "howler";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getDownloadURL, ref } from "firebase/storage";

const FloatDiv = styled.div`
  height: 40px;
  text-align: right;
  z-index: 95000;
  position: fixed;
  top: -600px;
`;

const MButton = styled.button``;

const MButton1 = styled.button``;

const MInput = styled.input`
  width: 20px;
`;

const InvDiv = styled.div`
  position: fixed;
  top: -50px;
  left: -50px;
`;

const HowlTour = (props: any) => {
  const [myHowler, setMyHowler] = useState<any>(null);
  const [stopped, setStopped] = useState(true);
  const inputRef = useRef<any>(null);

  const loadHowl = async (e: any) => {
    e.stopPropagation();
    const all = decodeURIComponent(inputRef.current.value);

    let parts = all.split("*|xyz|*");

    const url = await getDownloadURL(
      ref(cloudStorage, "gs://chattutorx.appspot.com/help/" + parts[0])
    );

    let sprites: any = {};
    let lastNum = 0;

    let numbers = parts[1].replace(/\*zzz\*/g, "\n").split("\n");

    numbers.forEach((item: any, index: number) => {
      const inum = parseFloat(item.split("\t")[0]) * 1000;
      sprites["a" + index] = [Math.round(lastNum), Math.round(inum - lastNum)];
      lastNum = inum;
    });

    setMyHowler(
      new Howl({
        src: [url],
        sprite: sprites,
        onend: () => {
          setStopped(true);
        },
      })
    );
    setTimeout(() => (inputRef.current.value = "ready"), 0);
  };

  const playHowl = async (e: any = undefined) => {
    e?.stopPropagation();
    await myHowler.play(inputRef.current.value.replace("x", ""));
    setStopped(false);
  };

  const showChange = (e: any) => {
    if (e.target.value.includes("x")) {
      playHowl();
    }
  };

  return (
    <React.Fragment>
      <FloatDiv>
        {myHowler ? <InvDiv id="readydiv" /> : null}
        <MButton id="loadhowl" onClick={loadHowl}></MButton>
        <MButton1 id="playhowl" onClick={playHowl}></MButton1>
        <MInput onChange={showChange} ref={inputRef} id="howlinput"></MInput>
        {stopped ? <div id="xlstopped" /> : null}
      </FloatDiv>
    </React.Fragment>
  );
};

export default HowlTour;
