import { createSlice } from "@reduxjs/toolkit";

const rideStepsSlice = createSlice({
  initialState: "",
  name: "rideSteps",
  reducers: {
    setRideSteps: (state, action) => {
      return action.payload;
    },
  },
});

export const { setRideSteps } = rideStepsSlice.actions;

export default rideStepsSlice.reducer;
