import { createSlice } from "@reduxjs/toolkit";

// ** Initial State

const initialState: any = null;

const crossAssignmentSlice = createSlice({
  initialState,
  name: "crossAssignment",
  reducers: {
    setCrossAssignment: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCrossAssignment } = crossAssignmentSlice.actions;

export default crossAssignmentSlice.reducer;
