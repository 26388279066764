export const bookchange = [
  {
    act: "booksettings",
    target: "#bbbook0",
    content: "This is a book you created earlier.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "alkbcppx1",
  },

  {
    target: "#bbinfo0",
    content: "Click here to change various data about the book.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    spotlightPadding: 0,
    trans: "bcppx21a3",
  },
  {
    target: "body",

    content:
      "Here you can change the general info about the book, you can create a new cover, and you can also publish and share your book.",
    floaterProps: { hideArrow: true },
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "bcppx3a2c",
  },
  {
    target: "body",
    content:
      "The title, author, info and subtitle fields are obvious. Only the title and the author fields are mandatory.",
    floaterProps: { hideArrow: true },
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    placement: "center",
    trans: "bcppx4a5c1",
  },
  {
    target: "#xxcoverdiv",
    content: "You can also change the cover design here.",
    action: [
      {
        newride: "coverdesign-coverdesignend",
        question: { trans: "bub25a", text: "Show me how to change the cover." },
      },
      {
        newride: "bookmore",
        question: { trans: "bub261", text: "Not now. Let's just carry on." },
      },
    ],
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: true,
    trans: "bcppx5c2",
  },

  {
    anchor: "bookmore",
    target: "#publishedxx",
    content:
      "If your book is not yet published, this is where you can publish it. You should also re-publish it after you made changes to it.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "bcppx6c3",
  },
  {
    target: "#xbooksave",
    content: "With this button you can save your changes.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "bcppx7c4",
  },
  {
    target: "#xbookdelete",
    content:
      "With this button you can delete your book. This is final and cannot be undone.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "bcppx8c5",
  },
  {
    target: "#xbookhide",
    content:
      "With this button you can hide and unhide your book in the list. It will only be visible then if you select Show hidden books from the filter menu in the top left corner.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "bcppx9c6",
  },
];
