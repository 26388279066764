import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = true;

const editModeSlice = createSlice({
  initialState,
  name: "editMode",
  reducers: {
    setEditMode: (state, action) => {
      return action.payload;
    },
  },
});

export const { setEditMode } = editModeSlice.actions;

export default editModeSlice.reducer;
