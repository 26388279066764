import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = false;

const hideMenuSlice = createSlice({
  initialState,
  name: "hideMenu",
  reducers: {
    setHideMenu: (state: any, action: any) => {
      return action.payload;
    },
  },
});

export const { setHideMenu } = hideMenuSlice.actions;

export default hideMenuSlice.reducer;
