import React, { Suspense, useEffect, useState } from "react";
import { X } from "react-feather";
import { BiPin } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ModalBody, Button } from "reactstrap";
import { setHelpContent, setHelpOpen } from "redux/reducers/reducer.help";
import { RootState } from "redux/reducers/_index";
import store from "redux/store";
import styled from "styled-components";
import { phaseIn } from "utils/words.utils";
import ReactPlayer from "react-player";
import { tx } from "utils/globalize";

const MyIframe = styled.iframe`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
`;

const MyHeader = styled.div`
  display: flex;
  flex: 0 0 50px;
  align-items: center;
  font-size: 16px;
  padding: 0px 0px 0px 20px;
`;

const ButtonsDiv = styled.div`
  flex: 0 0 auto;
`;

const BlockDiv = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: auto;
`;

const MyPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const MyModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const MyHelpButton = styled(Button)``;

export const MyHelpButtonClose = styled(Button)`
  position: absolute;
  top: 4px;
  right: 10px;
`;
//@ts-ignore
const myApiHelper = window.myApiHelper;

const videos: any = {
  en: {
    list: "PLhuv9vxSn7pkQU3VqHvjyeIaMlmNiXT5P",
    videos: [
      {
        youtube: "gMYfbydFCSg",
        title: "Creating a Book (4:20)",
      },
      {
        youtube: "1ja4W_NVsXk",
        title: "Adding Chapters (2:25)",
      },
      {
        youtube: "SwIIamlr7NQ",
        title: "The Editor (2:55)",
      },
      {
        youtube: "Mgfiw40c4jE",
        title: "Special Elements (2:04)",
      },
      {
        youtube: "L3imO2ApOxI",
        title: "Sharing Books (3:22)",
      },
    ],
  },
  hu: {
    videos: [
      {
        youtube: "II7X1dIEnjA",
        title: "Könyvkészítés (4:53)",
      },
      {
        youtube: "nH61zb8iAbc",
        title: "Több fejezet (2:42)",
      },
      {
        youtube: "3t6PDpCkgmY",
        title: "A szerkesztő (3:18)",
      },
      {
        youtube: "MIiAM-2HrYI",
        title: "Speciális elemek (2:05)",
      },
      {
        youtube: "s6vb0A-dReo",
        title: "Megosztás (3:42)",
      },
    ],
    list: "PLhuv9vxSn7pm9YckR0ou_dNGpP45FPRoN",
  },
};

const HelpModalContent = (props: any) => {
  const [loc, setLoc] = useState("");
  // const [myEditorData, setMyEditorData] = useState({ list: [], content: {} });

  const [pinned, setPinned] = useState(false);

  const help = useSelector<RootState, any>((state) => state.help);
  const language = useSelector<RootState, any>((state) => state.tempLanguage);
  useSelector<RootState, any>((state) => state.appTransParts);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/help/help")) {
      store.dispatch(setHelpOpen(location.search.split("=")[1] || "any"));
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    //@ts-ignore
    if (window.myApiHelper)
      //@ts-ignore
      window.myApiHelper.onHelp((message) => {
        store.dispatch(setHelpOpen(message.code));
      });
  }, []);

  useEffect(() => {
    // const loadHelp = async () => {
    //   const thisHelp = await doc(firestore,"/help/main").get();
    //   setMyEditorData({
    //     list: thisHelp.data()?.list,
    //     content: thisHelp.data()?.content,
    //   });

    //   // setStyles({ ...(thisHelp.data()?.styles || {}) });
    // };

    if (help.open === 0) {
      // loadHelp();
    }

    if (help.open.includes("-")) {
      setLoc(help.open);
      setTimeout(() => {
        document.getElementById(help.open)?.scrollIntoView();
        phaseIn("helperdoc");
      }, 0);
      return;
    }

    if (!help.open) {
      store.dispatch(setHelpContent(null));
      const action = setTimeout(() => setLoc(""), 500);
      return () => clearTimeout(action);
    }
  }, [help.open]);

  // const goList = () => {
  //   phaseOut("helperdoc");
  //   setTimeout(() => {
  //     setLoc("");
  //     store.dispatch(setHelpContent(null));
  //     setTimeout(() => {
  //       phaseIn("helperdoc");
  //     }, 0);
  //   }, 100);
  // };

  // const goThere = (where: any) => {
  //   phaseOut("helperdoc");
  //   setTimeout(() => {
  //     setLoc(where);
  //     setTimeout(() => {
  //       phaseIn("helperdoc");
  //     }, 0);
  //   }, 100);
  // };

  const goClose = () => {
    //@ts-ignore
    if (window.myApiHelper) {
      //@ts-ignore
      window.myApiHelper.closeMe();
      return;
    }

    store.dispatch(setHelpOpen(""));
  };

  const pinMe = () => {
    myApiHelper.pinMe();
    setPinned(!pinned);
  };

  // const showRide = (ride: string) => {
  //   store.dispatch(setHelpOpen(""));
  //   store.dispatch(setRideSteps(ride));
  // };

  return (
    <React.Fragment>
      <MyHeader className="draggy bg-toolbar text-toolbar">
        <div className="text-toolbar" style={{ flex: "1 1 auto" }}>
          {tx("inihwe", "IniShare Help")}
        </div>

        <ButtonsDiv>
          {myApiHelper ? (
            <MyHelpButton
              onClick={pinMe}
              className="btn-icon mr-2 nodraggy text-toolbar"
              color="white"
              style={{ background: pinned ? "gray" : "transparent" }}
              size="sm"
            >
              <BiPin size={21} />
            </MyHelpButton>
          ) : null}
          {/* <MyHelpButton
            onClick={goList}
            className="btn-icon mr-1 nodraggy text-toolbar"
            color="white"
            size="sm"
          >
            <BiListUl size={21} />
          </MyHelpButton> */}
          <MyHelpButton
            onClick={goClose}
            className="btn-icon mr-1 nodraggy text-toolbar"
            color="white"
            size="sm"
          >
            <X size={21} />
          </MyHelpButton>
        </ButtonsDiv>
      </MyHeader>
      <MyModalBody className={"pb-0 pt-0 pl-0 pr-0"} id="helperdoc">
        <Suspense fallback={<div />}>
          <BlockDiv
            style={{
              display: loc && !help.content ? "" : "none",
              overflowY: "hidden",
            }}
          >
            <MyPlayer
              className="react-player"
              url={"https://youtu.be/" + loc}
              width="100%"
              height="100%"
              controls
              config={{
                youtube: {
                  playerVars: {
                    fs: 1,
                    modestbranding: 1,
                    autoplay: 1,
                    listType: "playlist",
                    list: videos[language].list,
                  },
                },
              }}
            />
          </BlockDiv>
          <BlockDiv
            style={{
              display: loc || help.content ? "none" : "",
              padding: "0px 20px 20px 20px",
            }}
          >
            <MyIframe src="https://inishare.com/bhelp?m=0" title="helpmodal" />
          </BlockDiv>
        </Suspense>
        <div style={{ padding: "20px", paddingTop: "0px" }}>{help.content}</div>
      </MyModalBody>
    </React.Fragment>
  );
};

export default HelpModalContent;
