import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = [];

const scheduleSlice = createSlice({
  initialState,
  name: "schedule",
  reducers: {
    setSchedule: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;
