import {
  deleteField,
  doc,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import store from "redux/store";
import { v4 } from "uuid";

import { firestore, FreeData } from "./firebaseUtil";

// eslint-disable-next-line
Date.prototype.toJSON = function () {
  var timezoneOffsetInHours = -(this.getTimezoneOffset() / 60); //UTC minus local time
  var sign = timezoneOffsetInHours >= 0 ? "+" : "-";
  var leadingZero = Math.abs(timezoneOffsetInHours) < 10 ? "0" : "";

  //It's a bit unfortunate that we need to construct a new Date instance
  //(we don't want _this_ Date instance to be modified)
  var correctedDate = new Date(
    this.getFullYear(),
    this.getMonth(),
    this.getDate(),
    this.getHours(),
    this.getMinutes(),
    this.getSeconds(),
    this.getMilliseconds()
  );
  correctedDate.setHours(this.getHours() + timezoneOffsetInHours);
  var iso = correctedDate.toISOString().replace("Z", "");

  return (
    iso +
    sign +
    leadingZero +
    Math.abs(timezoneOffsetInHours).toString() +
    ":00"
  );
};

export const updatePosition = async (position: FreeData) => {
  const currentUser = store.getState().currentUser;
  await updateDoc(
    doc(firestore, `/users/${currentUser?.id}/data/position`),
    position
  );
};

export const updateProgram = async (lesson: string, value: FreeData) => {
  const currentUser = store.getState().currentUser;
  await updateDoc(doc(firestore, `/users/${currentUser?.id}/data/program`), {
    [lesson]: value,
  });
};

export const updateSchedule = async (value: any, userId = "") => {
  const currentUser = store.getState().currentUser;

  const batch = writeBatch(firestore);

  batch.set(
    doc(firestore, `/users/${currentUser?.id}/data/schedule`),
    { [value.id]: JSON.stringify(value.value) },
    { merge: true }
  );

  const members = [
    userId,
    ...[...(store.getState().users[userId + "-c"]?.members || [])],
  ];

  for (let l = 0; l < members.length; l++) {
    batch.set(
      doc(
        firestore,
        `/teachers/${currentUser?.id}/students/${members[l]}/common/schedule`
      ),
      {
        [value.id]: JSON.stringify({
          ...value.value,
          userId: currentUser?.id,
          title: currentUser?.firstName + " " + currentUser?.lastName,
        }),
      },
      { merge: true }
    );
  }

  await batch.commit();
};

export const updateScheduleDelete = async (value: any, userId = "") => {
  const currentUser = store.getState().currentUser;

  const batch = writeBatch(firestore);

  batch.set(
    doc(firestore, `/users/${currentUser?.id}/data/schedule`),
    { [value.id]: deleteField() },
    { merge: true }
  );

  const members = [
    userId,
    ...[...(store.getState().users[userId + "-c"]?.members || [])],
  ];

  for (let l = 0; l < members.length; l++) {
    batch.set(
      doc(
        firestore,
        `/teachers/${currentUser?.id}/students/${members[l]}/common/schedule`
      ),
      { [value.id]: deleteField() },
      { merge: true }
    );
  }

  await batch.commit();
};

export const updateWords = async (wordId: string, value: any) => {
  const currentUser = store.getState().currentUser;
  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/words`),
    { words: { [wordId]: value } },
    { merge: true }
  );
};

export const updateUserSetup = async (value: any) => {
  const currentUser = store.getState().currentUser;

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/usersetup`),
    { ...value, bubo: v4() },
    { merge: true }
  );
};

export const updateSetting = async (setting: FreeData) => {
  const currentUser = store.getState().currentUser;

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/settings`),
    setting,
    {
      merge: true,
    }
  );
};

export const updateBookmarksAdd = async (id: any, data: any) => {
  const currentUser = store.getState().currentUser;

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/bookmarks`),
    { [id]: { ...data } },
    { merge: true }
  );
};

export const updateBookmarksDelete = async (id: any) => {
  const currentUser = store.getState().currentUser;

  return setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/bookmarks`),
    { [id]: deleteField() },
    { merge: true }
  );
};

export const updateBookmarks = async (bookmarks: any) => {
  const currentUser = store.getState().currentUser;

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/bookmarks`),
    bookmarks
  );
};

export const updateAutoTexts = async (setting: FreeData) => {
  const currentUser = store.getState().currentUser;
  let autoTexts = {
    ...(store.getState().settings.autoTexts || {}),
    [setting.id]: setting.value,
  };

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/settings`),
    { autoTexts },
    { merge: true }
  );
};

export const updateAutoTextsDelete = async (id: string) => {
  const currentUser = store.getState().currentUser;
  let autoTexts = { ...(store.getState().settings.autoTexts || {}) };

  delete autoTexts[id];

  await updateDoc(doc(firestore, `/users/${currentUser?.id}/data/settings`), {
    autoTexts: autoTexts,
  });
};

export const updateStatus = async (updater: FreeData) => {
  const userSetup = store.getState().userSetup;
  const currentUser = store.getState().currentUser;
  const users = store.getState().users;

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/userlist`),
    { [userSetup.you.id]: { ...users[userSetup.you.id], ...updater } },
    { merge: true }
  );
};

export const updateHelpTopics = async (topics: FreeData) => {
  const currentUser = store.getState().currentUser;

  await updateDoc(
    doc(firestore, `/users/${currentUser?.id}/data/helptopics`),
    topics
  );
};

export const updateAddToGroup = async (
  user: string,
  group: string,
  name: string
) => {
  const currentUser = store.getState().currentUser;
  const users = store.getState().users;

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/userlist`),
    {
      [group + "-c"]: {
        firstName: name,
        lastName: "",
        members: [...new Set([...(users[group + "-c"]?.members || []), user])],
      },
      [group + "-p"]: "group",
      [group]: {
        status: "1-active",
      },
      list: [...new Set([...users.list, group])],
    },
    { merge: true }
  );
};

export const updateDeleteFromGroup = async (user: string, group: string) => {
  const currentUser = store.getState().currentUser;
  const users = store.getState().users;

  if (users[group + "-c"]?.members.length === 1) {
    const newUsers: any = [];

    users.list.forEach((item: any) => {
      if (item !== group) {
        newUsers.push(item);
      }
    });

    await setDoc(
      doc(firestore, `/users/${currentUser?.id}/data/userlist`),
      {
        [group + "-c"]: deleteField(),
        [group + "-m"]: deleteField(),
        [group + "-p"]: deleteField(),
        list: newUsers,
      },
      { merge: true }
    );
  } else {
    await setDoc(
      doc(firestore, `/users/${currentUser?.id}/data/userlist`),
      {
        [group + "-c"]: {
          members: users[group + "-c"]?.members?.filter(
            (item: any) => item !== user
          ),
        },
      },
      { merge: true }
    );
  }
};

export const updateDeleteGroup = async (group: string) => {
  const currentUser = store.getState().currentUser;
  const users = store.getState().users;

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/userlist`),
    {
      [group + "-c"]: deleteField(),
      [group + "-m"]: deleteField(),
      list: [...users.list.filter((item: any) => item !== group)],
    },
    { merge: true }
  );
};

export const updateSetInactive = async (userId: string) => {
  const currentUser = store.getState().currentUser;

  const batch = writeBatch(firestore);

  batch.set(
    doc(firestore, `/users/${currentUser?.id}/data/userlist`),
    {
      [userId + "-c"]: { inactive: true },
    },
    { merge: true }
  );

  await batch.commit();
};

export const updateLastOpened = async (userId: string) => {
  setDoc(
    doc(firestore, `/users/${store.getState().currentUser?.id}/data/userlist`),
    {
      [userId + "-c"]: {
        ...store.getState().users[userId + "-c"],
        lastOpen: Date.now(),
      },
    },
    { merge: true }
  );
};

export const backDateLastOpened = async (userId: string) => {
  if (
    store.getState().users[userId + "-c"].lastOpen <
    store.getState().users[userId + "-m"]?.[0].time
  ) {
    setDoc(
      doc(
        firestore,
        `/users/${store.getState().currentUser?.id}/data/userlist`
      ),
      {
        [userId + "-c"]: {
          ...store.getState().users[userId + "-c"],
          lastOpen: store.getState().users[userId + "-m"]?.[0]?.time + 1 || 0,
        },
      },
      { merge: true }
    );
  }
};

export const updateSetActive = async (userId: string) => {
  const currentUser = store.getState().currentUser;

  const batch = writeBatch(firestore);

  batch.set(
    doc(firestore, `/users/${currentUser?.id}/data/userlist`),
    {
      [userId + "-c"]: { inactive: false },
    },
    { merge: true }
  );

  await batch.commit();
};
