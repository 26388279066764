export const doexercises = [
  {
    act: "partsmenusome",
    anchor: "partsmenusome",
    target: "body",

    content:
      "This is the list of parts in your book or chapter. What would you like to know?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "dofirstexercise",
        question: {
          trans: "bub35afc28",
          text: "How to add, edit and delete a part?",
        },
      },
      {
        newride: "gotoeditor",
        question: {
          trans: "bub35afc29",
          text: "How to edit the materials?",
        },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1i1",
    noNext: true,
  },
  {
    act: "partsmenu",
    anchor: "partsmenu",
    target: "body",

    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content:
      "This will be the list of parts in your book or chapter. There are none yet, though. What would you like to know?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "dofirstexercise",
        question: {
          trans: "bub35afc30",
          text: "How to add, edit and delete a part?",
        },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1i2",
    noNext: true,
  },
  {
    act: "exercises",
    target: "body",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    position: "center",
    content:
      "Here you will see the existing parts in your chapter or book. There are none yet, though.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "exedbmx3i3",
  },
  {
    anchor: "dofirstexercise",
    target: "#dropdiv",
    content:
      "Click here to add a part, or drag and drop an image or document file here.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "exedbmx4i4",
  },
  {
    target: "#promptbody",
    content: "Set a title for this part and optionally a subtitle.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    trans: "exedbmx5i5",
  },
  {
    target: "#promptbutton",
    content: "Click Add to add the new part.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "exedbmx6i6",
  },
  {
    target: "body",
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content:
      "The new part gets added and it opens in the Editor where you can add text, videos, files, etc. to it.",
    disableBeacon: true,

    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "exedbmx6i7",
  },
  {
    target: "#crclose",
    content:
      "We explain the editor in a separate tour. For now just close it by clicking on the close button.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "exedbmx6i8",
  },
  {
    target: "body",
    placement: "center",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content: "We get back to the list of the parts.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "exedbmx6i9",
  },

  {
    anchor: "someexercises",
    target: "#exeme",
    content:
      "This is the first part here. If you click on the title you can edit the contents in the Editor.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "exedbmx7i10",
  },
  {
    target: "#exeellipsis",
    content:
      "Now click here to show the other available options regarding this part.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    spotlightPadding: 0,
    trans: "exedbmx8i11",
  },
  {
    target: "#exeme",
    content:
      "A few buttons appear here that you can use to add, delete or change parts.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "exedbmx14i12",
  },
  {
    target: "#addabove0",
    content: "Click here to add a new part above this part.",
    hideBackButton: true,
    disableBeacon: true,
    spotlightClicks: false,
    trans: "exedbmx9i13",
  },
  {
    target: "#addheader0",
    content: "Click here to add a new section title above this part.",
    hideBackButton: true,
    disableBeacon: true,
    spotlightClicks: false,
    trans: "exedbmx10i14",
  },
  {
    target: "#delete0",
    content: "Click here to delete this part.",
    hideBackButton: true,
    disableBeacon: true,
    spotlightClicks: false,
    trans: "exedbmx13i18",
  },
  {
    target: "#addbelow0",
    content: "Click here to add a new part below this part.",
    hideBackButton: true,
    disableBeacon: true,
    spotlightClicks: false,
    trans: "exedbmx11i16",
  },
  {
    target: "#addheaderbelow0",
    content: "Click here to add a new section title below this part.",
    hideBackButton: true,
    disableBeacon: true,
    spotlightClicks: false,
    trans: "exedbmx12i17",
  },
  {
    target: "#changetitle0",
    content: "Click here to change the title or subtitle of this part.",
    hideBackButton: true,
    disableBeacon: true,
    spotlightClicks: false,
    trans: "exedbmx10i15",
  },

  {
    target: "#exeme",
    content:
      "You can switch around the sequence of parts by dragging and dropping them.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "exedbmx14i19",
  },
  {
    target: "#dropdiv",
    content:
      "If you click here, a new part will be added after the last one. You can also drag and drop images and certain files onto it.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "exedbmx15i20",
  },
  {
    target: "#presentationx",
    content:
      "If this is checked you will view your book as a reader which is useful for testing your book or doing a presentation from you book.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "exedbmx16i21",
  },
  {
    target: "#moronexe",
    content:
      "And click here if you want to go back to the list of chapters or your library.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "exedbmx17i22",
    goto: "",
  },

  {
    target: "body",

    content: "Do you want to get a short tour on how to edit your parts?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "gotoeditor",
        question: {
          trans: "bub35afc31",
          text: "Yes, show me now.",
        },
      },
      {
        newride: "",
        question: {
          trans: "bub35afc32",
          text: "Not now.",
        },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1i23",
    noNext: true,
  },

  {
    anchor: "gotoeditor",
    target: "#exeme",
    content:
      "This is the first part here. Click on the title now so you can edit the contents in the Editor.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "exedbmx7i24",
  },
  {
    target: "body",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    placement: "center",
    content:
      "It opens in the Editor where you can add or edit your part as in a Word processor.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "exedbmx6i25",
    goto: "editor",
  },
];
