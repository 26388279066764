import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserSubType {
  firstName?: string;
  lastName?: string;
  langArray?: string[];
  email?: string;
  language?: string;
  type?: string;
  [key: string]: any;
}

export type UserType = UserSubType | null;

const currentStudentSlice = createSlice({
  initialState: {
    id: "55555",
    teacher: "pAHeJp4ygIQT4tn2EXOrpTvJxY53",
    email: "joe@example.com",
    type: "student",
  } as UserType,
  name: "currentStudent",
  reducers: {
    setCurrentStudent: (state: UserType, action: PayloadAction<UserType>) => {
      return action.payload;
    },
  },
});

export const { setCurrentStudent } = currentStudentSlice.actions;

export default currentStudentSlice.reducer;
