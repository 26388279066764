import { onAuthStateChanged } from "firebase/auth";
import { auth, getUserProfileDocument } from "firebaseUtil/firebaseUtil";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setAuthLoaded } from "redux/reducers/reducer.authLoaded";
import { setCurrentUser } from "redux/reducers/reducer.currentUser";
import { RootState } from "redux/reducers/_index";
import store from "redux/store";

import { cleanListeners, setupListeners } from "./App.listeners";

const AppAuthStateHandling = (props: any) => {
  const history = useHistory();
  const historyRef = useRef<any>();
  useSelector<RootState, any>((state) => state.authLoaded);

  historyRef.current = history;

  useEffect(() => {
    if (!document.location.href.includes("/help")) {
      onAuthStateChanged(auth, async (userAuth) => {
        if (store.getState().loginFreeze) {
          return;
        }
        if (userAuth) {
          const userRef = await getUserProfileDocument(userAuth);

          if (userRef?.id) {
            setupListeners(userRef);
          }
          if (
            store.getState().authLoaded &&
            document.location.href.includes("/login/")
          ) {
            setTimeout(() => {
              historyRef.current.push("/app/library");
            }, 2000);
          }
        } else {
          console.log("getloggedout");
          store.dispatch(setCurrentUser(null));
          store.dispatch(setAuthLoaded(true));
          cleanListeners();
        }
      });
    }
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default AppAuthStateHandling;
