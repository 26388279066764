import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = -1;

const currentEditedSlice = createSlice({
  initialState,
  name: "currentEdited",
  reducers: {
    setCurrentEdited: (state: number, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setCurrentEdited } = currentEditedSlice.actions;

export default currentEditedSlice.reducer;
