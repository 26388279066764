import React from "react";
import { setConfirm } from "redux/reducers/reducer.confirm";
import store from "redux/store";
import { tx } from "./globalize";

export const validMember = () => {
  return store.getState().currentUser?.expiry > Date.now();
};

export const isDemo = () => {
  return store.getState().currentUser?.email === "demo@netenglish.com";
};

export const canUpload = () => {
  return validMember() || (store.getState().quotas?.quota || 0) < 500000000;
};

export const canOpenNote = () => {
  return validMember() || store.getState().unsaved?.unsaved.length < 2;
};

export const canOCR = () => {
  return (
    store.getState().quotas?.ocrQuotaMonthly > 0 ||
    store.getState().quotas?.ocrQuota > 0
  );
};

export const tooLarge = (size: string) => {
  store.dispatch(
    setConfirm({
      confirmButton: tx("HwePF7diTALiiuwei1indcs", "OK"),
      onConfirm: () => {},
      header: tx("MrmXRBQSXX1weiwefXgZas34", "Error..."),
      content: (
        <React.Fragment>
          <div>
            {tx(
              "wdweadsasdw21csdwwe",
              "File cannot be larger than xxxMB.",
              size
            )}
          </div>
        </React.Fragment>
      ),
      open: true,
    })
  );
};
