export const dics: any = {
  en001: {
    name: "Longman Contemporary",
    url: `https://www.ldoceonline.com/dictionary/xxxxx`,
    site: "https://www.ldoceonline.com/",
    lang: "en",
    id: "en001",
  },
  en002: {
    name: "MacMillan British",
    url: `https://www.macmillandictionary.com/dictionary/british/xxxxx`,
    site: "https://www.macmillandictionary.com",
    lang: "en",
    id: "en002",
  },
  en003: {
    name: "MacMillan American",
    url: `https://www.macmillandictionary.com/dictionary/american/xxxxx`,
    site: "https://www.macmillandictionary.com",
    lang: "en",
    id: "en003",
  },
  en004: {
    name: "Oxford Learner's Dictionary",
    url: `https://www.oxfordlearnersdictionaries.com/definition/english/xxxxx`,
    site: "https://www.oxfordlearnersdictionaries.com/",
    lang: "en",
    id: "en004",
  },
  en005: {
    name: "dictionary.com",
    url: `https://www.dictionary.com/browse/xxxxx`,
    site: "https://www.dictionary.com/",
    lang: "en",
    id: "en005",
  },
  hu001: {
    name: "Sztaki Szótár  - angol => magyar",
    url: `https://szotar.sztaki.hu/search?fromlang=eng&tolang=hun&searchWord=xxxxx&langcode=hu`,
    site: "https://szotar.sztaki.hu",
    lang: "hu",
    id: "hu001",
  },
  hu002: {
    name: "Google Translate Hungarian",
    url: `https://translate.google.com/?op=translate&sl=en&tl=hu&text=xxxxx`,
    site: "https://translate.google.com",
    lang: "hu",
    id: "hu002",
  },
  hu003: {
    name: "Sztaki Szótár  - magyar => angol",
    url: `https://szotar.sztaki.hu/search?fromlang=hun&tolang=eng&searchWord=xxxxx&langcode=hu`,
    site: "https://szotar.sztaki.hu",
    lang: "hu",
    id: "hu003",
  },
  hu004: {
    name: "Google Translate English",
    url: `https://translate.google.com/?op=translate&sl=hu&tl=en&text=xxxxx`,
    site: "https://translate.google.com",
    lang: "en",
    id: "hu004",
  },
};

export const dicBase: any = {
  hu: ["hu001", "hu002", "hu003", "hu004"],
  en: ["en001", "en002", "en003", "en004", "en005"],
};
