export const dochapters = [
  {
    anchor: "dofirstchapter",
    target: "#xchaptersbutton",
    content: "Click here to add the first chapter.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "dbmx8f1",
  },
  {
    target: "#promptbody",
    content: "Set the chapter title and optionally a subtitle.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    trans: "dbmx9f2",
  },
  {
    target: "#xpromptbutton",
    content: "Click Add to create the chapter.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    goto: "chaptershow",
    trans: "dbmx10f3",
  },
  {
    target: "#ellipsisdiv",
    content:
      "To add, delete or change chapters click on the three dots next to the chapter.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "dbmx11f4",
  },
  {
    complete: true,
    target: "#tocexamplesexercise",
    content:
      "You can add new chapters here, add section titles, delete them or change the text in them by clicking on the correct button.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "dbmx12f5",
  },
];
