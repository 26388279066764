import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { dateline: [], pointer: [0, 0], preview: "" };

const fileDataSlice = createSlice({
  initialState,
  name: "fileData",
  reducers: {
    setFileData: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setFileData } = fileDataSlice.actions;

export default fileDataSlice.reducer;
