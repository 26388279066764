import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = "";

const audioSrcSlice = createSlice({
  initialState,
  name: "audioSrcGlobal",
  reducers: {
    setAudioSrc: (state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setAudioSrc } = audioSrcSlice.actions;

export default audioSrcSlice.reducer;
