import { createSlice } from "@reduxjs/toolkit";

// ** Initial State

const chatPagesSlice = createSlice({
  initialState: 2,
  name: "chatpages",
  reducers: {
    setChatPages: (state, action) => {
      return action.payload;
    },
  },
});

export const { setChatPages } = chatPagesSlice.actions;

export default chatPagesSlice.reducer;
