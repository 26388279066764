import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";

import React from "react";
import { setConfirm } from "redux/reducers/reducer.confirm";
import { setGlobalLoading } from "redux/reducers/reducer.globalLoading";
import { setNCounter } from "redux/reducers/reducer.ncounter";
import { setUserStatus } from "redux/reducers/reducer.userStatus";
import store from "redux/store";
import { platform } from "./drive";
import { tx } from "./globalize";

export const updateProduct = (product: any) => {
  let finalStatus: any = {};

  store.dispatch(setNCounter({ id: "setup" }));

  product = product || {};

  const currentUser = store.getState().currentUser;

  const thisPlatform =
    platform === "android" ? "google" : platform === "web" ? "web" : "apple";

  if (currentUser?.payPlatform && currentUser?.expiry > Date.now()) {
    finalStatus.payPlatform = currentUser?.payPlatform;
    finalStatus.expiry = currentUser?.expiry;
    finalStatus.type = "pro";
    finalStatus.renewal = currentUser?.renewal;
    finalStatus.owned = true;
  } else if (product.owned) {
    if (
      !currentUser?.payPlatform ||
      currentUser?.payPlatform !== thisPlatform
    ) {
      finalStatus.wrong = true;
    }

    finalStatus.payPlatform = platform === "android" ? "google" : "apple";
    finalStatus.expiry = product.expiryDate;

    finalStatus.type = "pro";
    finalStatus.owned = true;
  } else {
    finalStatus.payPlatform = currentUser?.payPlatform || thisPlatform;
    finalStatus.expiry = Math.max(
      currentUser?.expiry || 0,
      product.expiryDate || 0
    );
    finalStatus.type = "free";
    finalStatus.renewal = currentUser?.renewal;
    finalStatus.owned = false;
  }

  if (
    platform === "web" &&
    !currentUser?.cancel_url &&
    !currentUser?.update_url
  ) {
    finalStatus.webNoManage = false;
  } else {
    finalStatus.webNoManage = false;
  }

  finalStatus.realOwned = product.owned;

  return finalStatus;
};

export const setupAppPay = (id: string) => {
  InAppPurchase2.verbosity = InAppPurchase2.QUIET;
  InAppPurchase2.applicationUsername = id;
  InAppPurchase2.sandbox = false;

  // InAppPurchase2.validator =
  //   "https://validator.fovea.cc/v1/validate?appName=com.ininotes.app&apiKey=828c05d0-88c1-422d-82e9-ac8464d01932";

  InAppPurchase2.validator =
    "https://validator.fovea.cc/v1/validate?appName=inisharex.app.com&apiKey=692314ac-9d31-41c1-a909-8d147960dea0";

  // InAppPurchase2.when("inisharemonthly").registered(
  //   (product: IAPProduct) => {}
  // );

  InAppPurchase2.register({
    id: "inisharemonthly2",
    type: InAppPurchase2.PAID_SUBSCRIPTION,
  });

  InAppPurchase2.error((e: any) => {
    store.dispatch(setGlobalLoading(false));
  });

  InAppPurchase2.when("inisharemonthly2").updated((product: any) => {
    if (store.getState().currentUser?.id) {
      store.dispatch(
        setUserStatus({
          ...updateProduct(product),
        })
      );
    }
  });

  InAppPurchase2.when("inisharemonthly2")
    .approved((p: any) => {
      p.verify();
    })
    .verified((p: any) => {
      if (store.getState().userStatus.owned === false) {
        store.dispatch(
          setConfirm({
            confirmButton: tx("ok", "OK"),
            header: tx("tdpv7yPTImcgNye_afw", "Payment Successful..."),
            content: (
              <React.Fragment>
                <div>
                  {tx(
                    "youarealwel",
                    "You are now a Pro Member. There are no more limits on the amount of data you can upload, and the amount of people you share books with."
                  )}
                </div>
              </React.Fragment>
            ),
            open: true,
          })
        );
      }
      store.dispatch(setGlobalLoading(false));
      p.finish();
    })
    .cancelled(() => store.dispatch(setGlobalLoading(false)));

  InAppPurchase2.refresh();
};

export const appPayPay = (id: string) => {
  // InAppPurchase2.sandbox = true;

  // InAppPurchase2.update();
  store.dispatch(setGlobalLoading(true));

  // InAppPurchase2.refresh();
  InAppPurchase2.order("inisharemonthly2").error(() =>
    store.dispatch(setGlobalLoading(false))
  );

  return;
};

export const appPayCancel = (id: string) => {
  // InAppPurchase2.sandbox = true;

  InAppPurchase2.update();
  // InAppPurchase2.refresh();
  InAppPurchase2.manageSubscriptions();

  return;
};

export const webPay = (id: string) => {
  //@ts-ignore
  window.Paddle.Checkout.open({
    product: 761898,
    email: store.getState().currentUser?.email,
    passthrough: id,
  });

  //@ts-ignore
  // const TwoCoInlineCart = window.TwoCoInlineCart;

  // TwoCoInlineCart.setup.setMerchant("250631471495"); // your Merchant code

  // TwoCoInlineCart.cart.setReset(true);

  // TwoCoInlineCart.products.removeAll();

  // TwoCoInlineCart.products.add({
  //   code: "inisharemonthly",
  //   quantity: 1,
  // }); // add products to cart

  // TwoCoInlineCart.cart.setCartLockedFlag(true);

  // TwoCoInlineCart.cart.setCurrency("USD");

  // TwoCoInlineCart.cart.setLanguage("EN");

  // TwoCoInlineCart.cart.setAutoAdvance(true);

  // TwoCoInlineCart.cart.setSource(store.getState().currentUser?.id);

  // //@ts-ignore
  // TwoCoInlineCart.cart.setTest(true);

  // // TwoCoInlineCart.cart.setReturnMethod({
  // //   type: "redirect",
  // //   url: "https://netenglish.com/" + bobj.language + "/pending",
  // // });

  // // TwoCoInlineCart.cart.setSignature(bobj.signature);

  // TwoCoInlineCart.cart.checkout(); // start checkout process

  //eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNTA2MzE0NzE0OTUiLCJpYXQiOjE1MTYyMzkwMjJ9.MwTZ82oPvxoJOrzecpU_3DNK9TkzlOuUtodulaWA4d4tBRg-ZsRivZ1vJm5tdOXcVxE3lFgcDG27jbQnQgkvaA
};
