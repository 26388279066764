import React from "react";
import { toast } from "react-toastify";

import { Message } from "semantic-ui-react";

export const showToast = (inData: any) => {
  console.log({
    hideProgressBar: true,
    position: inData.position || "bottom-left",
    autoClose: inData.time || 3000,
    containerId: inData.containerId || "default",
  });

  toast(
    <React.Fragment>
      <Message info>
        <Message.Header>{inData.title}</Message.Header>
        <p>
          {inData.note || ""} {inData.description}
        </p>
      </Message>
    </React.Fragment>,
    {
      hideProgressBar: true,
      position: inData.position || "bottom-left",
      autoClose: inData.time || 3000,
      containerId: inData.containerId || "default",
    }
  );
};
