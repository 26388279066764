import React, { useMemo, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

import { tx } from "utils/globalize";

import { RootState } from "redux/reducers/_index";
import { useSelector } from "react-redux";
import { baseStyles } from "blockEditor/utils/baseStyles";
import BackgroundCreator from "./background";

const BackgroundSelect = (props: any) => {
  const { myStyle, setMyStyle } = props;
  const [open, setOpen] = useState(false);

  let currentStyles = useSelector<RootState, any>(
    (state) => state.currentBook?.styles
  );

  if (!currentStyles["background-Bluish"]) {
    currentStyles = baseStyles;
  }

  const styleList = useMemo(() => {
    const list = [
      ...Object.keys(currentStyles)
        .filter((style: any) => currentStyles[style].type === "background")
        .map((style: any) => ({
          ...currentStyles[style],
          label: currentStyles[style].name,
          value: currentStyles[style].name,
        })),
    ];

    const defi = props.top ? [] : [{ value: "default", label: "default" }];

    return [{ value: "none", label: "none", name: "none" }, ...defi, ...list];
  }, [currentStyles, props.top]);

  const selectStyle = (a: any) => {
    setMyStyle(styleList.find((item: any) => item.value === a.target.value));
    if (props.action) {
      props.action();
    }
  };

  const editBackgrounds = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Form>
        <Row className="mx-0 mb-1">
          <Col sm="12" className="pl-0 pr-0">
            {props.text === "xxx" ? null : (
              <Label for="nameVerticalIcons">
                {props.text || tx("backkowekok", "Background:")}
              </Label>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ flex: "1 1 200px", paddingRight: "8px" }}
                id={"prselect"}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <InputGroup>
                  <CustomInput
                    type="select"
                    name="select"
                    id="select-custom"
                    onChange={selectStyle}
                    value={myStyle.value}
                  >
                    {styleList.map((style: any) => (
                      <option key={style.value} value={style.value}>
                        {style.label}
                      </option>
                    ))}
                  </CustomInput>
                  <Button onClick={editBackgrounds} className="ml-1">
                    {tx("editstyles", "Edit Styles")}
                  </Button>
                </InputGroup>

                {/* <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={myStyle}
                  isSearchable={false}
                  name="bing"
                  autoFocus
                  options={styleList}
                  onChange={selectStyle}
                  menuPlacement={"auto"}
                /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <BackgroundCreator open={open} setOpen={setOpen} orig={myStyle.value} />
    </React.Fragment>
  );
};

export default BackgroundSelect;
