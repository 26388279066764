import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const tempLocalResultsSlice = createSlice({
  initialState,
  name: "tempLocalResults",
  reducers: {
    setTempLocalResults: (state: boolean, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setTempLocalResults } = tempLocalResultsSlice.actions;

export default tempLocalResultsSlice.reducer;
