import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { firestore } from "firebaseUtil/firebaseUtil";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
} from "reactstrap";
import { RootState } from "redux/reducers/_index";
import { TextArea } from "semantic-ui-react";
import styled from "styled-components";

const BaseDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background: white;
  padding: 20px;
`;

const ButtonDiv = styled.div`
padding: 10px
padding-top: 25px;
`;

const MailFinder = (props: any) => {
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  const [myLead, setMyLead] = useState<any>({});
  const [name1, setName1] = useState<any>("");
  const [name2, setName2] = useState<any>("");
  const [emails, setEmails] = useState<any>("");
  const [notes, setNotes] = useState<any>("");
  const [url, setUrl] = useState("");
  const [location, setLocation] = useState("");

  const changeName1 = (e: any) => {
    setName1(e.target.value);
  };

  const changeName2 = (e: any) => {
    setName2(e.target.value);
  };

  const changeUrl = (e: any) => {
    setUrl(e.target.value);
  };

  const changeLocation = (e: any) => {
    setLocation(e.target.value);
  };

  const changeEmails = (e: any) => {
    setEmails(e.target.value);
  };

  const changeNotes = (e: any) => {
    setNotes(e.target.value);
  };

  const getNext = async (status: string) => {
    if (myLead.twitter && status) {
      await setDoc(
        doc(firestore, "/mined/" + myLead.twitter),
        {
          status: status,
        },
        { merge: true }
      );
    }

    const nextDoc = await getDocs(
      query(
        collection(firestore, "/mined"),
        where("status", "==", "mined"),
        limit(1)
      )
    );

    if (nextDoc.docs.length) {
      setMyLead(nextDoc.docs[0].data());
      setUrl(nextDoc.docs[0].data().url);
      setLocation(nextDoc.docs[0].data().location);
    }
    setName1("");
    setEmails("");
    setName2("");
    setNotes("");
  };

  const saveAndNext = async (type = "found") => {
    await setDoc(
      doc(firestore, "/mined/" + myLead.twitter),
      {
        status: type,
        emails: emails.trim(),
        name1: name1.trim(),
        name2: name2.trim(),
        notes: notes,
        url: url,
        location: location,
      },
      { merge: true }
    );

    getNext("");
  };

  const showAll = () => {
    window.open(
      "https://www.facebook.com/search/people/?q=" + url.replace("@", ""),
      "_blank"
    );

    window.open(
      "https://www.google.com/search?q=site%3A" +
        url +
        "+email&oq=site%3A" +
        url +
        "+email",
      "_blank"
    );

    window.open(
      "https://www.google.com/search?q=site%3A" +
        url +
        "+contact&oq=site%3A" +
        url +
        "+contact",
      "_blank"
    );

    window.open(
      "https://twitter.com/" + myLead.twitter.replace("@", ""),
      "_blank"
    );

    window.open("https://" + url, "_blank");
  };

  const searchAll = (name: string) => {
    window.open(
      "https://www.linkedin.com/search/results/people/?keywords=" +
        encodeURIComponent(name.trim()) +
        "&origin=SWITCH_SEARCH_VERTICAL&sid=NTw",
      "_blank"
    );

    window.open("https://www.facebook.com/search/people/?q=" + name, "_blank");

    window.open(
      "https://www.google.com/search?q=" +
        encodeURIComponent(name.trim()) +
        "+email",
      "_blank"
    );
  };

  return (
    <React.Fragment>
      <BaseDiv>
        <Form size="small">
          <FormGroup row>
            <Label className="pt-0" sm="2">
              Name
            </Label>
            <Col sm="9">{myLead.name}</Col>
          </FormGroup>
          <FormGroup row>
            <Label className="pt-0" sm="2">
              Domain
            </Label>
            <Col sm="9">
              <InputGroup className="input-group-merge">
                <Input
                  id="accountNumber"
                  type="text"
                  value={url}
                  onChange={changeUrl}
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label className="pt-0" sm="2">
              Location
            </Label>
            <Col sm="9">
              <InputGroup className="input-group-merge">
                <Input
                  id="accountNumber"
                  type="text"
                  value={location}
                  onChange={changeLocation}
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label className="pt-0" sm="2">
              Followers
            </Label>
            <Col sm="9">{myLead.followers}</Col>
          </FormGroup>
          <FormGroup row>
            <Label className="pt-0" sm="2">
              Type
            </Label>
            <Col sm="9">{myLead.mainType + " / " + myLead.subType}</Col>
          </FormGroup>
          <FormGroup row>
            <Label className="pt-0" sm="2">
              Twitter
            </Label>
            <Col sm="9">{myLead.twitter}</Col>
          </FormGroup>

          <FormGroup row>
            <Label sm="3">Name 1</Label>
            <Col sm="9">
              <InputGroup className="input-group-merge">
                <Input
                  id="accountNumber"
                  type="text"
                  value={name1}
                  onChange={changeName1}
                />
                <Button onClick={() => searchAll(name1)}>Search</Button>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Name 2</Label>
            <Col sm="9">
              <InputGroup className="input-group-merge">
                <Input
                  id="accountNumber"
                  type="text"
                  value={name2}
                  onChange={changeName2}
                />
                <Button onClick={() => searchAll(name2)}>Search</Button>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Emails</Label>
            <Col sm="9">
              <InputGroup className="input-group-merge">
                <TextArea
                  style={{ width: "100%" }}
                  type="text"
                  value={emails}
                  onChange={changeEmails}
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Notes</Label>
            <Col sm="9">
              <InputGroup className="input-group-merge">
                <TextArea
                  style={{ width: "100%" }}
                  type="text"
                  value={notes}
                  onChange={changeNotes}
                />
              </InputGroup>
            </Col>
          </FormGroup>

          <ButtonDiv>
            <Button className="mr-50" onClick={() => getNext("forget")}>
              Pass Forget
            </Button>
            <Button className="mr-50" onClick={() => getNext("failed")}>
              Pass Fail
            </Button>
            <Button onClick={() => saveAndNext("more")}>Pass Save</Button>
            <Button className="ml-1" onClick={showAll}>
              Search
            </Button>
            <Button className="ml-1" onClick={() => saveAndNext("found")}>
              Save and Next
            </Button>
          </ButtonDiv>
        </Form>
      </BaseDiv>
    </React.Fragment>
  );
};

export default MailFinder;
