import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = {
  list: [],
  data: {},
  loading: "idle",
};

const usersInactiveSlice = createSlice({
  initialState,
  name: "usersInactive",
  reducers: {
    setUsersInactive: (state, action) => {
      return action.payload;
    },
    setUsersDataInactive: (state, action) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setUsersInactive, setUsersDataInactive } =
  usersInactiveSlice.actions;

export default usersInactiveSlice.reducer;
