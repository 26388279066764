import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const currentExerciseSlice = createSlice({
  initialState,
  name: "currentExercise",
  reducers: {
    setCurrentExercise: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setCurrentExercise } = currentExerciseSlice.actions;

export default currentExerciseSlice.reducer;
