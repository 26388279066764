import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = "chat";

const helpRandomSlice = createSlice({
  initialState,
  name: "helpRandom",
  reducers: {
    setHelpRandom: (state: string, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setHelpRandom } = helpRandomSlice.actions;

export default helpRandomSlice.reducer;
