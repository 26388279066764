import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const asstoreSlice = createSlice({
  initialState,
  name: "cats",
  reducers: {
    setAssignmentStore: (state: any[], action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setAssignmentStore } = asstoreSlice.actions;

export default asstoreSlice.reducer;
