import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const bookmarkSlice = createSlice({
  initialState,
  name: "bookmark",
  reducers: {
    setBookmarks: (state: any[], action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setBookmarks } = bookmarkSlice.actions;

export default bookmarkSlice.reducer;
