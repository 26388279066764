export const settingsaccount = [
  {
    act: "settings",
    target: "body",
    placement: "center",
    content: "You can change the settings of your app here.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libddfgbmx23k5oi1senm",
    goto: "",
  },
  {
    act: "account",
    target: "body",
    placement: "center",
    content: "You can change your account settings here.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libddfgbmx23k5oi1senk",
    goto: "",
  },
];
