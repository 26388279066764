import { createSlice } from "@reduxjs/toolkit";

const activePlayerSlice = createSlice({
  initialState: null as any,
  name: "activePlayer",
  reducers: {
    setActivePlayer: (state, action) => {
      return action.payload;
    },
  },
});

export const { setActivePlayer } = activePlayerSlice.actions;

export default activePlayerSlice.reducer;
