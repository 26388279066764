import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import store from "redux/store";
import { beginsWith } from "utils/utils";

import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();

  useEffect(() => {
    App.addListener("backButton", () => {});
  }, []);

  useEffect(() => {
    const openAction = (data: any) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2

      if (data.url.indexOf("com.inisharex.app:") === 0) {
        const slug = data.url.split("com.inisharex.app:")[1].replace("//", "/");

        if (slug) {
          setTimeout(() => {
            if (beginsWith(slug, "/login/aclearpicfail")) {
              const email = slug
                ?.match(/email=[^&]*/)?.[0]
                .replace("email=", "");

              if (email && email !== store.getState().currentUser?.email) {
                history.push("/login/login");
              } else {
                history.push("/app/library");
              }
            } else {
              history.push(slug);
            }
          }, 50);
        }
      }

      if (
        data.url.indexOf("https://app.inishare.com") === 0 ||
        data.url.indexOf("https://signup.inishare.com") === 0
      ) {
        const slug =
          data.url.indexOf("https://app.inishare.com") === 0
            ? data.url.split("https://app.inishare.com")[1]
            : data.url.split("https://signup.inishare.com")[1];
        if (slug) {
          if (beginsWith(slug, "/login/aclearpicfail")) {
            const email = slug?.match(/email=[^&]*/)?.[0].replace("email=", "");

            if (email && email !== store.getState().currentUser?.email) {
              history.push("/login/login");
            } else {
              history.push("/app/library");
            }
          } else {
            history.push(slug);
          }
        }
      }
      // If no match, do nothing - let regular routing
      // logic take over
    };

    Capacitor?.addListener?.("App", "appUrlOpen", openAction);
    return () => {
      // CapApp.removeAllListeners();
      Capacitor.removeListener?.("App", "", "appUrlOpen", openAction);
    };
  }, [history]);

  return null;
};

export default AppUrlListener;
