//student
//tx("asdfasdflogin1","===Login page")
import React from "react";
import { useHistory, useLocation } from "react-router";
import { setTempLanguage } from "redux/reducers/reducer.tempLanguage";
import store from "redux/store";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";

import { createStyles, makeStyles } from "@material-ui/styles";
import { allLanguages } from "@translations/languagedata/languages";

export const useStyles = makeStyles(() =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "0px",
      cursor: "pointer",
    },
    flag: {
      marginRight: "8px",
    },
    name: { fontSize: "18px", fontFamily: "var(--font-medium)" },
    img: {},
    menu: {
      left: "auto !important",
      right: "0px !important",
      top: "25px !important",
    },
  })
);

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2px;
`;
const FlagDiv = styled.div`
  margin-right: 8px;
`;
const LangDiv = styled.div`
  position: absolute;
  top: calc(env(safe-area-inset-top) + 10px);
  right: 5px;
`;

const LanguageList = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const chooseLanguage = (language: string) => {
    // const tempParts = location.pathname.split("/");

    if (location.pathname.includes("/login/login")) {
      let newSearch = "?";
      const orig = location.search
        .replace("?", "")
        .replace(/lang=../g, "")
        .replace("&&", "");

      if (!orig) {
        newSearch = "?lang=" + language;
      } else {
        newSearch = "?lang=" + language + "&" + orig;
      }

      history.push("/login/login/" + newSearch);
      return;
    }

    if (location.pathname.includes("/login/referred")) {
      history.push(
        "/login/referred/?" +
          location.search.replace(/lang=../, "").replace("?", "") +
          "lang=" +
          language
      );
      return;
    }

    if (location.pathname.includes("/login/clearpic")) {
      history.push(
        "/login/clearpic/?" +
          location.search.replace(/lang=../, "").replace("?", "") +
          "lang=" +
          language
      );
      return;
    }

    store.dispatch(setTempLanguage(language));
  };

  return (
    <React.Fragment>
      <Dropdown
        style={{ position: "static" }}
        upward={false}
        trigger={
          <LangDiv>
            <FlexDiv>
              <FlagDiv>
                <img
                  alt={"flag"}
                  src={`/flags/${props.language}.png`}
                  height="20px"
                />
              </FlagDiv>
            </FlexDiv>
          </LangDiv>
        }
        icon={null}
        floating
        pointing="top right"
      >
        <Dropdown.Menu className={classes.menu}>
          {allLanguages.map((lang: any) => (
            <Dropdown.Item
              onClick={() => chooseLanguage(lang.code)}
              key={lang.code}
            >
              <div className={classes.flex}>
                <div className={classes.flag}>
                  <img
                    alt={"flag"}
                    src={`/flags/${lang.code}.png`}
                    height="22px"
                    className={classes.img}
                  />
                </div>
                <div className={classes.name}>{lang.ownName}</div>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageList;
