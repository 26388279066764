import { createSlice } from "@reduxjs/toolkit";

const rideActionsSlice = createSlice({
  initialState: {} as any,
  name: "rideActions",
  reducers: {
    setRideActions: (state, action) => {
      return action.payload;
    },
  },
});

export const { setRideActions } = rideActionsSlice.actions;

export default rideActionsSlice.reducer;
