import {
  updateLastOpened,
  updateUserSetup,
} from "firebaseUtil/firebaseUtil.firestore";
import { setAvatars } from "redux/reducers/reducer.avatars";
import { setChatPages } from "redux/reducers/reducer.chatPages";
import { setFakeStudent } from "redux/reducers/reducer.fakeStudent";
import { setUsers } from "redux/reducers/reducer.users";
import { setUserSetup } from "redux/reducers/reducer.userSetup";
import store from "redux/store";

import { phaseFullIn, phaseIn, phaseOut } from "./utils";

export const setUserGlobal = async (userId: string, users: any) => {
  const currentUser = store.getState().currentUser;

  if (userId === store.getState().userSetup?.you?.id) {
    updateLastOpened(userId);
    return;
  }

  // store.dispatch(setChat([]));
  store.dispatch(setChatPages(2));
  phaseOut("chatcontainer");
  setTimeout(() => phaseIn("chatcontainer"), 1000);

  store.dispatch(
    setAvatars({
      myAvatar: "/images/donwload.png",
      yourAvatar: "/images/download.png",
    })
  );

  setTimeout(() => {
    if (store.getState().currentUser?.type === "teacher") {
      updateUserSetup({
        ref: `/teachers/${currentUser?.id}/students/${userId}`,
        me: {
          id: currentUser?.id,
          name: currentUser?.lastName + " " + currentUser?.firstName,
        },
        you: {
          id: userId,
          name:
            users[userId + "-c"]?.firstName +
            " " +
            users[userId + "-c"]?.lastName,
        },
        teacher: currentUser?.id,
        members: users[userId + "-c"]?.members || null,
        origUser: "",
      });
    } else {
      updateUserSetup({
        ref: `/teachers/${userId}/students/${currentUser?.id}`,
        me: {
          id: currentUser?.id,
          name: currentUser?.firstName + " " + currentUser?.lastName,
          avatar: currentUser?.avatar || "/images/download.png",
        },
        you: {
          id: userId,
          name:
            users[userId + "-c"]?.firstName +
            " " +
            users[userId + "-c"]?.lastName,
        },
        teacher: userId,
        origUser: "",
      });
    }
    updateLastOpened(userId);
  }, 150);
};

export const setTestUser = async (isTest = false) => {
  // if (props.inactives) {
  //   alert("Inactive");
  //   return;
  // }

  setTimeout(() => phaseFullIn(), 1000);

  // const currentUser = store.getState().currentUser;
  const userSetup = store.getState().userSetup;

  const userId = "testuser";

  store.dispatch(setFakeStudent(isTest));
  // store.dispatch(
  //   setAvatars({
  //     myAvatar: store.getState().avatars.yourAvatar,
  //     yourAvatar: store.getState().avatars.myAvatar,
  //   })
  // );

  setTimeout(() => {
    store.dispatch(
      setUserSetup({
        ...userSetup,
        me: { ...userSetup.you },
        you: { ...userSetup.me },
      })
    );

    if (isTest) {
      const result = {
        list: [store.getState().currentUser?.id],
        [store.getState().currentUser?.id]: {
          shown: true,
          status: "1-active",
        },
        [store.getState().currentUser?.id + "-c"]: {
          id: store.getState().currentUser?.id,
          firstName: store.getState().currentUser?.firstName,
          lastName: store.getState().currentUser?.lastName,
        },
        [store.getState().currentUser?.id + "-p"]:
          store.getState().currentUser?.pic,
        [store.getState().currentUser?.id + "-m"]: [
          {
            auto: false,
            content: "",
            time: Date.now(),
          },
        ],
      };

      store.dispatch(setUsers(result));
    }

    updateLastOpened(userId);
  }, 0);
};

export const setUserBook = async (bookId: string) => {
  const currentUser = store.getState().currentUser;

  if (bookId === store.getState().userSetup?.you?.id) {
    return;
  }

  // store.dispatch(setChat([]));

  // store.dispatch(
  //   setAvatars({
  //     myAvatar: "/images/donwload.png",
  //     yourAvatar: "/images/download.png",
  //   })
  // );

  const newUserSetup = {
    ref: `/users/${currentUser?.id}/books/${bookId}`,
    me: {
      id: currentUser?.id,
      name: currentUser?.lastName + " " + currentUser?.firstName,
    },
    you: {
      id: bookId,
      name: "My Book",
      teacher: currentUser?.id,
      members: null,
    },
    origUser:
      store.getState().userSetup.origUser || store.getState().userSetup.you.id,
  };

  updateUserSetup(newUserSetup);

  store.dispatch(setUserSetup(newUserSetup));

  // updateLastOpened(userId);
};
