import { combineReducers } from "@reduxjs/toolkit";
import activeEditorItemReducer from "./reducer.activeEditorItem";
import activeIdGlobalReducer from "./reducer.activeIdGlobal";
import activeItemGlobalReducer from "./reducer.activeItem";
import activePageReducer from "./reducer.activePage";
import activePlayerReducer from "./reducer.activePlayer";
import activeSentenceReducer from "./reducer.activeSentence";
import appTransPartsReducer from "./reducer.appTransParts";
import assignmentReducer from "./reducer.assignment";
import assignmentStoreReducer from "./reducer.assignmentStore";
import audioSrcReducer from "./reducer.audioSrc";
import authLoadedReducer from "./reducer.authLoaded";
import avatarsReducer from "./reducer.avatars";
import backgroundReducer from "./reducer.background";
import bookloadedReducer from "./reducer.bookLoaded";
import bookmarkReducer from "./reducer.bookmarks";

import bookStatusReducer from "./reducer.bookStatus";
import catsReducer from "./reducer.cats";
import chatReducer from "./reducer.chat";
import chatCountReducer from "./reducer.chatCount";
import chatPagesReducer from "./reducer.chatPages";
import clipboardReducer from "./reducer.clipboard";
import clipOpenReducer from "./reducer.clipOpen";
import commentingReducer from "./reducer.commenting";
import confirmReducer from "./reducer.confirm";
import confirm2Reducer from "./reducer.confirm2";
import contextReducer from "./reducer.context";
import copyTextReducer from "./reducer.copyText";
import crossAssignment from "./reducer.crossAssignment";
import currentBookReducer from "./reducer.currentBook";
import currentEditedReducer from "./reducer.currentEdited";
import currentExerciseReducer from "./reducer.currentExercise";
import currentStudentReducer from "./reducer.currentStudent";
import currentUserReducer from "./reducer.currentUser";
import currentWordReducer from "./reducer.currentWord";
import customizeReducer from "./reducer.customize";
import datelineReducer from "./reducer.dateline";
import datelineLoadedReducer from "./reducer.datelineLoaded";
import defaultExerciseReducer from "./reducer.defaultExercise";
import dictionaryReducer from "./reducer.dictionaryOpen";
import driveStatusReducer from "./reducer.driveStatus";
import driveOpenReducer from "./reducer.driveOpen";
import editorDataReducer from "./reducer.editorData";
import editModeReducer from "./reducer.editmode";
import exeReducer from "./reducer.exe";
import exercisesReducer from "./reducer.exercises";
import exerciseTagsReducerOwn from "./reducer.exerciseTagsOwn";
import extPointerReducer from "./reducer.extPointer";
import factoryOpenReducer from "./reducer.factoryOpen";
import fakeStudentReducer from "./reducer.fakeStudent";
import fileDataReducer from "./reducer.filedata";
import globalLoadingReducer from "./reducer.globalLoading";
import helpRandomReducer from "./reducer.helpRandom";
import helpReducer from "./reducer.help";
import hideMenuReducer from "./reducer.hideMenu";
import inviteOpenReducer from "./reducer.inviteOpen";
import isChangedReducer from "./reducer.isChanged";
import isLoadingReducer from "./reducer.isLoading";
import joyrideVideoReducer from "./reducer.joyrideVideo";
import lastLocationReducer from "./reducer.lastLocation";
import listEditorReducer from "./reducer.listEditorOpen";
import loginFreezeReducer from "./reducer.loginFreeze";
import mainloadingReducer from "./reducer.mainLoading";
import maxSizeReducer from "./reducer.maxSize";
import mouseReducer from "./reducer.mouse";
import myBooksReducer from "./reducer.myBooks";
import nCounterReducer from "./reducer.ncounter";
import newAssignment from "./reducer.newAssignment";
import newWordOpenReducer from "./reducer.newWordOpen";
import noteTakerReducer from "./reducer.notetaker";
import openClipboardReducer from "./reducer.openClipboard";
import origSentencesReducer from "./reducer.origSentences";
import permissionsReducer from "./reducer.permissions";
import platformReducer from "./reducer.platform";
import playSpeedReducer from "./reducer.playSpeed";
import pointerReducer from "./reducer.pointer";
import quotasReducer from "./reducer.quotas";
import rideStepsReducer from "./reducer.rideSteps";
import rideActionsReducer from "./reducer.rideActions";
import resultCountReducer from "./reducer.resultCount";
import autoRouteReducer from "./reducer.routeMe";
import scheduleReducer from "./reducer.schedule";
import selectionReducer from "./reducer.selection";
import selectionOpenReducer from "./reducer.selectionOpen";
import settingsReducer from "./reducer.settings";
import sidebarOpenReducer from "./reducer.sidebarOpen";
import shareItemReducer from "./reducer.shareItem";
import statusReducer from "./reducer.status";
import tempLanguageReducer from "./reducer.tempLanguage";
import tempLocalReducer from "./reducer.tempLocal";
import tempLocalResultsReducer from "./reducer.tempLocalResults";
import tempLocalCommentsReducer from "./reducer.tempLocalComments";
import thisExerciseReducer from "./reducer.thisExercise";
import tourReducer from "./reducer.tour";
import tourOnReducer from "./reducer.tourOn";
import turnDirectionReducer from "./reducer.turnDirection";
import tutorialReducer from "./reducer.tutorial";
import tutorialVarsReducer from "./reducer.tutorialVars";
import unsavedReducer from "./reducer.unsaved";
import updatingReducer from "./reducer.updating";
import usersReducer from "./reducer.users";
import userSetupReducer from "./reducer.userSetup";
import userStatusReducer from "./reducer.userStatus";
import usersInactiveReducer from "./reducer.usersInactive";
import validHotkeysReducer from "./reducer.validHotkeys";
import videoingReducer from "./reducer.videoing";
import wordbarReducer from "./reducer.wordbar";
import wordsReducer from "./reducer.words";
import words3000Reducer from "./reducer.words3000";
import xfilesReducer from "./reducer.xfiles";

const rootReducer = combineReducers({
  activeEditorItem: activeEditorItemReducer,
  activePage: activePageReducer,
  activeIdGlobal: activeIdGlobalReducer,
  activeItem: activeItemGlobalReducer,
  activePlayer: activePlayerReducer,
  activeSentence: activeSentenceReducer,
  appTransParts: appTransPartsReducer,
  assignment: assignmentReducer,
  assignmentStore: assignmentStoreReducer,
  audioSrc: audioSrcReducer,
  authLoaded: authLoadedReducer,
  autoRoute: autoRouteReducer,
  avatars: avatarsReducer,
  background: backgroundReducer,
  bookLoaded: bookloadedReducer,
  bookmarks: bookmarkReducer,
  bookStatus: bookStatusReducer,
  cats: catsReducer,
  chat: chatReducer,
  chatCount: chatCountReducer,
  chatPages: chatPagesReducer,
  clipOpen: clipOpenReducer,
  clipboardGlobal: clipboardReducer,
  commenting: commentingReducer,
  confirm: confirmReducer,
  confirm2: confirm2Reducer,
  context: contextReducer,
  copyText: copyTextReducer,
  crossAssignment: crossAssignment,
  currentEdited: currentEditedReducer,
  currentExercise: currentExerciseReducer,
  currentStudent: currentStudentReducer,
  currentUser: currentUserReducer,
  currentWord: currentWordReducer,
  customize: customizeReducer,
  dateline: datelineReducer,
  datelineLoaded: datelineLoadedReducer,
  defaultExercise: defaultExerciseReducer,
  dictionary: dictionaryReducer,
  driveOpen: driveOpenReducer,
  driveStatus: driveStatusReducer,
  editMode: editModeReducer,
  editorData: editorDataReducer,
  exe: exeReducer,
  exercises: exercisesReducer,
  factoryOpen: factoryOpenReducer,
  currentBook: currentBookReducer,
  exerciseTagsOwn: exerciseTagsReducerOwn,
  extPointer: extPointerReducer,
  fakeStudent: fakeStudentReducer,
  fileData: fileDataReducer,
  globalLoading: globalLoadingReducer,
  help: helpReducer,
  helpRandom: helpRandomReducer,
  hideMenu: hideMenuReducer,
  inviteOpen: inviteOpenReducer,
  isChanged: isChangedReducer,
  isLoading: isLoadingReducer,
  joyrideVideo: joyrideVideoReducer,
  lastLocation: lastLocationReducer,
  listEditorOpen: listEditorReducer,
  loginFreeze: loginFreezeReducer,
  mainLoading: mainloadingReducer,
  maxSize: maxSizeReducer,
  mouse: mouseReducer,
  myBooks: myBooksReducer,
  nCounter: nCounterReducer,
  newAssignment: newAssignment,
  newWordOpen: newWordOpenReducer,
  noteTaker: noteTakerReducer,
  openClipboard: openClipboardReducer,
  origSentences: origSentencesReducer,
  permissions: permissionsReducer,
  platform: platformReducer,
  playSpeed: playSpeedReducer,
  pointer: pointerReducer,
  quotas: quotasReducer,
  rideActions: rideActionsReducer,
  rideSteps: rideStepsReducer,
  resultCount: resultCountReducer,
  schedule: scheduleReducer,
  selection: selectionReducer,
  selectionOpen: selectionOpenReducer,
  settings: settingsReducer,
  shareItem: shareItemReducer,
  sidebarOpen: sidebarOpenReducer,
  status: statusReducer,
  tempLanguage: tempLanguageReducer,
  tempLocal: tempLocalReducer,
  tempLocalResults: tempLocalResultsReducer,
  tempLocalComments: tempLocalCommentsReducer,
  thisExercise: thisExerciseReducer,
  tour: tourReducer,
  tourOn: tourOnReducer,
  turnDirection: turnDirectionReducer,
  tutorial: tutorialReducer,
  tutorialVars: tutorialVarsReducer,
  unsaved: unsavedReducer,
  users: usersReducer,
  usersInactive: usersInactiveReducer,
  userSetup: userSetupReducer,
  userStatus: userStatusReducer,
  validHotkeys: validHotkeysReducer,
  videoing: videoingReducer,
  wordbar: wordbarReducer,
  words: wordsReducer,
  words3000: words3000Reducer,
  updating: updatingReducer,
  xfiles: xfilesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
