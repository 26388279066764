import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = {
  tags: [],
  index: {},
  exercises: {},
};

const exerciseTagsOwnSlice = createSlice({
  initialState,
  name: "exerciseTagsOwn",
  reducers: {
    setExerciseTagsOwn: (state, action) => {
      return action.payload;
    },
  },
});

export const { setExerciseTagsOwn } = exerciseTagsOwnSlice.actions;

export default exerciseTagsOwnSlice.reducer;
