import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";

import { myApiMain } from "./App";

const ShortCuts = (props: any) => {
  const clipboardShortcut = useSelector<RootState, any>(
    (state) => state.settings.clipboardShortcut
  );
  const screenshotShortcut = useSelector<RootState, any>(
    (state) => state.settings.screenshotShortcut
  );

  useEffect(() => {
    myApiMain?.setupShortcuts({
      screenshotShortcut: screenshotShortcut || "",
      clipboardShortcut: clipboardShortcut || "",
    });
  }, [screenshotShortcut, clipboardShortcut]);

  return <React.Fragment></React.Fragment>;
};

export default ShortCuts;
