import { createSlice } from "@reduxjs/toolkit";

const activeIdGlobalSlice = createSlice({
  initialState: {} as any,
  name: "activeId",
  reducers: {
    setActiveIdGlobal: (state, action) => {
      state[action.payload.editorId] = action.payload.activeId;
    },
  },
});

export const { setActiveIdGlobal } = activeIdGlobalSlice.actions;

export default activeIdGlobalSlice.reducer;
