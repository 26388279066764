import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
//@ts-ignore
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import store from "redux/store";
import { setRideSteps } from "redux/reducers/reducer.rideSteps";
import { setRideActions } from "redux/reducers/reducer.rideActions";

import "arrive/minified/arrive.min.js";
import JoyToolTip from "./joyride.tooltip";

import {
  presentationon,
  booksbyothers,
  dochaptersorig,
} from "./tours/chapters";
import {
  coverdesign,
  dolibrarymine,
  dolibraryminefirst,
  dolibraryothers,
  selectit,
  selectitmine,
} from "./tours/library";
import { setPointer } from "redux/reducers/reducer.pointer";
import { bookchange } from "./tours/bookchange";
import { bookcreatedlg, bookinfo, published } from "./tours/sharing";
import { exercisenoedit, exercisepresentationon } from "./tours/exercises";
import { creator } from "./tours/creator";
import { viewer } from "./tours/viewer";
import { introseq } from "./tours/intro";

import { bookintro } from "./tours/booksintro";
import { doexercises } from "./tours/doparts";
import { dobookchapters, firstdecide } from "./tours/dobook";
import { dobooknochapters } from "./tours/dobooknochapters";
import { dochapters } from "./tours/createchapter";
import { setTutorialVarIndiv } from "redux/reducers/reducer.tutorialVars";
import { paragraph } from "./tours/paragraph";
import { settingsaccount } from "./tours/setaccount";
import { bookTypes } from "./tours/booktypes";
import { libraryshow } from "./tours/around";
import { interact } from "./tours/interactive";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "firebaseUtil/firebaseUtil";

//@ts-ignore

export const steps: any = [
  ...firstdecide,
  ...dobookchapters,
  ...dobooknochapters,
  ...settingsaccount,
  ...dochapters,
  ...dolibrarymine,
  ...dolibraryminefirst,
  ...dolibraryothers,
  ...booksbyothers,
  ...bookTypes,
  ...presentationon,
  ...selectitmine,
  ...selectit,
  ...coverdesign,
  ...bookchange,
  ...bookintro,
  ...viewer,
  ...paragraph,
  ...published,
  ...bookinfo,
  ...bookcreatedlg,
  ...interact,
  ...doexercises,
  ...dochaptersorig,
  ...exercisenoedit,
  ...exercisepresentationon,
  ...introseq,
  ...creator,
  { steps: [], target: "body", act: "xxx" },
].map((item: any) => ({ ...item }));

const beacon: any = { xxxlibraryshow: [...libraryshow] };

const JoyRideElement = (props: any) => {
  const [noNext, setNoNext] = useState(false);
  const [shower, setShower] = useState(false);
  // const [init, setInit] = useState(false);

  const rideSteps = useSelector<RootState, any>((state) => state.rideSteps);
  const rideActions = useSelector<RootState, any>((state) => state.rideActions);
  const pointer = useSelector<RootState, any>((state) => state.pointer);

  useEffect(() => {
    if (rideSteps) {
      store.dispatch(
        setTutorialVarIndiv({ var: "rideSteps", value: rideSteps })
      );
    }

    if (rideSteps.indexOf("xxx") === 0) {
      setShower(true);
      rideActions.reset();
    } else {
      setShower(false);
    }
  }, [rideSteps, rideActions]);

  useEffect(() => {
    if (rideActions.reset) {
      store.dispatch(setRideSteps("bubu"));
      setTimeout(() => store.dispatch(setRideSteps("")), 0);
    }
  }, [rideActions]);

  // useEffect(() => {
  //   let parts: any = [];

  //   const misSteps = steps
  //     .filter((item: any) => item.trans)
  //     .map((item: any) => ["", "", item.trans, item.content]);

  //   const titleSteps = steps
  //     .filter((item: any) => item.transtitle)
  //     .map((item: any) => ["", "", item.transtitle, item.title]);

  //   parts = [...parts, ...misSteps, ...titleSteps];

  //   steps
  //     .filter((item: any) => item.action)
  //     .forEach((item: any) => {
  //       parts = [
  //         ...parts,
  //         ...item.action.map((item: any) => [
  //           "",
  //           "",
  //           item.question.trans || "",
  //           item.question.text || "",
  //         ]),
  //       ];
  //     });

  //   try {
  //     setDoc(doc(firestore, "/trex/done"), { trans: JSON.stringify(parts) });
  //   } catch (e) {}
  // }, []);

  useEffect(() => {
    setNoNext(!!steps[pointer]?.noNext);
  }, [pointer]);

  // useEffect(() => {
  //   if (!rideSteps) {
  //     store.dispatch(resetTutorialVars({}));
  //   }
  // }, [rideSteps]);

  const callback = (data: any) => {
    const { action, index, status, type, step } = data;

    if (action === "close") {
      store.dispatch(setRideSteps(""));
      return;
    }

    if (type === EVENTS.STEP_BEFORE) {
      document.querySelector(step.target)?.scrollIntoView({ block: "nearest" });
      return;
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour

      if (steps[index].resume) {
        const resume = rideSteps.split("-");

        if (resume.length > 1) {
          store.dispatch(
            setRideSteps(rideSteps.split("-").slice(0, -1).join("-"))
          );
          store.dispatch(
            setPointer(
              steps.findIndex(
                (item: any) => item.anchor === resume.slice(-1)[0]
              )
            )
          );
          return;
        }
      }

      if (
        steps[index + 1].anchor &&
        steps[index + 1].anchor === rideSteps.split("-")[1]
      ) {
        store.dispatch(setRideSteps(""));
        return;
      }

      if (steps[index + 1]?.openit) {
        if (!document.getElementById("openxer")?.clientWidth) {
          store.dispatch(setPointer(index + 2));
        } else {
          store.dispatch(setPointer(index + 1));
        }
        return;
      }

      if (steps[index + 1]?.ifchapters) {
        store.dispatch(
          setPointer(
            index + (store.getState().currentBook.basics.chapters ? 1 : 2)
          )
        );

        return;
      }

      if (steps[index + 1]?.ifnochapters) {
        store.dispatch(
          setPointer(
            index + (store.getState().currentBook.basics.chapters ? 1 : 2)
          )
        );

        return;
      }

      if (steps[index + 1]?.if) {
        let inc = 1;

        let cond = steps[index + inc].if;

        while (
          steps[index + inc]?.if &&
          store.getState().tutorialVars[cond.var] !== cond.value
        ) {
          inc++;
          cond = steps[index + inc].if;
        }

        store.dispatch(setPointer(index + inc));

        return;
      }

      if (steps[index].switch) {
        for (let l = 0; l < steps[index].switch.length; l++) {
          let thisSwitch = steps[index].switch[l];

          if (
            store.getState().tutorialVars[thisSwitch.var] === thisSwitch.value
          ) {
            store.dispatch(
              setPointer(
                steps.findIndex((item: any) => item.anchor === thisSwitch.goto)
              )
            );
            break;
          }
        }
        return;
      }

      if (steps[index].goto) {
        if (steps[index].goto === "") {
          store.dispatch(setRideSteps(""));
          return;
        }

        store.dispatch(
          setPointer(
            steps.findIndex((item: any) => item.anchor === steps[index].goto)
          )
        );
        return;
      }

      if (steps[index].gosub) {
        const gosubData = steps[index].gosub.split("-");

        store.dispatch(
          setPointer(
            steps.findIndex((item: any) => item.anchor === gosubData[0])
          )
        );

        store.dispatch(
          setRideSteps(store.getState().rideSteps + "-" + gosubData[1])
        );
        return;
      }

      if (
        steps[index].required &&
        !document.querySelector(steps[index].target).value
      ) {
        return;
      }

      if (action === ACTIONS.PREV) {
        if (store.getState().pointer !== index) {
          return;
        }
        document.arrive(
          steps[index - 1].target,
          { onceOnly: true, existing: true },
          () => {
            setTimeout(() => {
              if (steps[index - 1].xtrafocus) {
                document.querySelector(steps[index - 1].target)?.focus();
              }
            }, 50);
            store.dispatch(setPointer(index - 1));
            setNoNext(!!steps[index - 1].noNext);
          }
        );

        if (document.getElementById(steps[index - 1].target)) {
          store.dispatch(
            setPointer(index + (action === ACTIONS.PREV ? -1 : 1))
          );
          setNoNext(!!steps[index - 1].noNext);
          if (steps[index - 1].xtrafocus) {
            document.querySelector(steps[index - 1].target)?.focus();
          }
        }
      } else {
        if (store.getState().pointer !== index) {
          return;
        }
        if (
          index === steps.length - 1 ||
          (steps[index + 1].act && !steps[index].cont)
        ) {
          store.dispatch(setRideSteps(""));
          return;
        }

        if (steps[index].cont) {
          setNoNext(!!steps[pointer].noNext);
          return;
        }

        document.arrive(
          steps[index + 1].target,
          { onceOnly: true, existing: true },
          () => {
            setTimeout(() => {
              if (steps[index + 1].xtrafocus) {
                document.querySelector(steps[index + 1].target)?.focus();
              }
            }, 50);
            store.dispatch(
              setPointer(index + (action === ACTIONS.PREV ? -1 : 1))
            );
            setNoNext(!!steps[index + 1].noNext);
          }
        );
      }
      // if (!document.querySelector(steps[index + 1].target)) {
      //       setPointer(index + (action === ACTIONS.PREV ? -1 : 1));
      //       if (steps[index + 1].xtrafocus) {
      //         document.querySelector(steps[index + 1].target)?.focus();
      //       }
      //     }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      store.dispatch(setRideSteps(""));
      store.dispatch(setPointer(0));
    }
  };

  return (
    <React.Fragment>
      <Joyride
        debug={false}
        steps={shower ? beacon[rideSteps] : steps}
        run={!!rideSteps}
        continuous={!shower && !noNext}
        getHelpers={(helpers: any) => store.dispatch(setRideActions(helpers))}
        callback={shower ? undefined : callback}
        stepIndex={shower ? undefined : pointer}
        disableOverlayClose={true}
        disableScrollParentFix={false}
        disableScrolling={true}
        tooltipComponent={shower ? undefined : JoyToolTip}
        styles={
          rideSteps === "bubu"
            ? {
                options: {
                  zIndex: -50000,
                  backgroundColor: "rgba(0,0,0,0)",
                  overlayColor: "rgba(0,0,0,0)",
                  textColor: "transparent",
                  width: "0px",
                },
              }
            : {
                options: {
                  zIndex: 50000,
                },
              }
        }
      />
    </React.Fragment>
  );
};

export default JoyRideElement;
