import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 0;

const resultCountSlice = createSlice({
  initialState,
  name: "resultCount",
  reducers: {
    setResultCount: (state: number, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setResultCount } = resultCountSlice.actions;

export default resultCountSlice.reducer;
