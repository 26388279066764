import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const permissionSlice = createSlice({
  initialState: { mic: "prompt" },
  name: "permissions",
  reducers: {
    setPermissionMic: (state: any, action: PayloadAction<string>) => {
      state.mic = action.payload;
    },
  },
});

export const { setPermissionMic } = permissionSlice.actions;

export default permissionSlice.reducer;
