import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = 0;

const pointerGlobalSlice = createSlice({
  initialState,
  name: "pointerGlobal",
  reducers: {
    setPointer: (state: number, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setPointer } = pointerGlobalSlice.actions;

export default pointerGlobalSlice.reducer;
