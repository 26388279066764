export const sampleBooks = [
  {
    id: "xrx-2e2958f5-a97c-492b-8064-5fa3643b9749",
    picBg:
      "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F2e2958f5-a97c-492b-8064-5fa3643b9749%2Fcover%2F5289e14c-f6c9-4e21-ac2c-261d77cb5ed7?alt=media&token=f5db4815-bf90-4e9c-9a62-57d16db6a81e",
    editable: true,
    published: 1663751593368,
    background: "Kingback",
    pic: "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F2e2958f5-a97c-492b-8064-5fa3643b9749%2Fcover%2Feeca060b-7daa-4849-b871-4b0229a9c6a3?alt=media&token=44999430-e417-4f44-b9a4-99252a118d8f",
    chapters: false,
    created: 1663749588859,
    title: "Five Kings",
    info: "Compiled from Wikipedia Articles",
    author: "Vendel Hermann",
    sample: true,
  },
  {
    id: "xrx-0251308a-bcde-4ba7-b43c-8f3966ba907a",
    background: "PinkBasic",
    type: null,
    title: "Cakes from fruits",
    hidden: false,
    picBg:
      "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F0251308a-bcde-4ba7-b43c-8f3966ba907a%2Fcover%2Fc8127f30-b804-42eb-9371-17ea826f28b2?alt=media&token=954d3077-1edc-4800-a7cf-30faeafc98b0",
    pic: "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F0251308a-bcde-4ba7-b43c-8f3966ba907a%2Fcover%2F7cb028f7-44a1-480c-a119-94266b151737?alt=media&token=622f47ad-021d-41d1-bf63-a3c0c6147fa8",
    editable: true,
    subtitle: "Pastry at its best",
    info: null,
    chapters: false,
    created: 1663789218364,
    author: "Jane Austen",
    published: 1663790302026,
    sample: true,
  },
  {
    id: "xrx-6f55726a-0f8f-46d1-b93e-c711f69d319b",
    background: "Bluish",
    chapters: true,
    type: null,
    info: null,
    picBg:
      "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F6f55726a-0f8f-46d1-b93e-c711f69d319b%2Fcover%2F59c94cb0-bee6-4807-abb1-b8d4c0a706a1?alt=media&token=7418fd6a-c5de-4684-bf0f-cebaef4c03c4",
    created: 1663772911988,
    author: "John Smith",
    pic: "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F6f55726a-0f8f-46d1-b93e-c711f69d319b%2Fcover%2F818ba637-9535-47a6-acda-05ffd07fb2b1?alt=media&token=d3fac20c-71de-42a5-a678-b487f1a69c66",
    title: "English Basics",
    editable: true,
    hidden: false,
    sample: true,
  },
  {
    id: "xrx-ca6658d3-f2b4-4de0-9bb8-2e414c0f4d9a",
    published: 1663781605499,
    created: 1663781597390,
    editable: true,
    pic: "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2Fca6658d3-f2b4-4de0-9bb8-2e414c0f4d9a%2Fcover%2Fd816a099-247d-4e89-97cf-25c330051954?alt=media&token=44be58ef-2303-4683-aa8a-19bb8837a80c",
    author: "Vendel Hermann",
    background: "Bluish",
    info: "News from VOA",
    title: "News Aloud",
    chapters: false,
    picBg:
      "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2Fca6658d3-f2b4-4de0-9bb8-2e414c0f4d9a%2Fcover%2F22eec922-4283-49c7-b0ff-78ee13a729bb?alt=media&token=7cbfbc5f-d5a4-4af7-aa84-bb7edd311eca",
    sample: true,
  },
  {
    id: "cb42e379-99d9-4692-8539-e215739d1f17",
    chapters: false,
    author: "Kiss Róza",
    pic: "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2Fcb42e379-99d9-4692-8539-e215739d1f17%2Fcover%2Fc29a761a-9593-4111-8942-d37931169f8d?alt=media&token=45aa7dbb-44bb-476f-9034-922f3953df07",
    title: "Vegánblog",
    background: "Bluish",
    published: 1663364253082,
    editable: true,
    picBg:
      "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2Fcb42e379-99d9-4692-8539-e215739d1f17%2Fcover%2F508018b9-b9d1-4ce6-a712-9a3996baed7c?alt=media&token=ec7b01c3-60f0-4e13-9bb7-89e70750edd2",
    created: 1663614718220,
    hidden: false,
    sample: true,
  },
  {
    id: "xrx-7e1b2ed2-2088-4a0c-b2e2-b2c550b56d05",
    author: "Peter Szeman",
    title: "IniShare",
    editable: true,
    owner: ["dKfPjRmIM8crw23NxGHkJWt5Viu2"],
    published: 1656539898543,
    created: 1655099602910,
    picBg:
      "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F7e1b2ed2-2088-4a0c-b2e2-b2c550b56d05%2Fcover%2F0567be91-6fb0-44bc-b111-6f88ff41cc0a?alt=media&token=99524adb-398d-44b9-afac-8931b02565ff",
    pic: "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F7e1b2ed2-2088-4a0c-b2e2-b2c550b56d05%2Fcover%2F4ac95a6e-7cfb-404d-8898-c8673f70af31?alt=media&token=e7d52cfe-ca22-4ee0-aa1e-3a48e3615914",
    chapters: true,
    info: "Explains the basics of IniShare in simple terms.",
    sample: true,
  },

  {
    id: "xrx-3c046135-c429-42fa-b781-7ef67ee05918",
    title: "Erőemelés Mesterfokon",
    chapters: true,
    info: "Hogyan legyünk bajnokok?",
    editable: true,
    subtitle: "",
    published: 1656539743648,
    owner: ["dKfPjRmIM8crw23NxGHkJWt5Viu2"],
    author: "Bill Strong",
    created: 1656539727233,
    hidden: false,
    picBg:
      "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F3c046135-c429-42fa-b781-7ef67ee05918%2Fcover%2F05033eec-7cd9-45ca-a2af-35364b5cb51e?alt=media&token=3bafc2c9-25fa-4646-9015-6ba034fded28",
    pic: "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F3c046135-c429-42fa-b781-7ef67ee05918%2Fcover%2Fce9fb027-d700-4892-b649-36822d339bde?alt=media&token=8670ed2a-fd88-4fcb-9ccb-f8139440006e",
    sample: true,
  },
  {
    type: null,
    created: 1660941213760,
    title: "ACME Restaurant Welcome Kit",
    editable: true,
    pic: "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F6bc46d39-4332-4e0c-b191-93185397d93a%2Fcover%2Fa2495c0e-f2c5-40f1-bc4f-56ae13ff2b53?alt=media&token=b0c146b1-37b3-4d32-be87-8b49a8ba3f0c",
    id: "xrx-6bc46d39-4332-4e0c-b191-93185397d93a",
    chapters: false,
    info: "Employee Orientation",
    owner: ["dKfPjRmIM8crw23NxGHkJWt5Viu2"],
    author: "Laurent Gausse",
    sample: true,
    picBg:
      "https://firebasestorage.googleapis.com/v0/b/inisharex.appspot.com/o/files%2FdKfPjRmIM8crw23NxGHkJWt5Viu2%2F6bc46d39-4332-4e0c-b191-93185397d93a%2Fcover%2Ff0b90eba-7b89-4579-a88f-5e74de8aad59?alt=media&token=d1b9bb96-b051-4c18-8bce-f191a555fc2c",
  },
];

export const sampleIds = [
  "xrx-6f55726a-0f8f-46d1-b93e-c711f69d319b",
  "xrx-0251308a-bcde-4ba7-b43c-8f3966ba907a",
  "xrx-2e2958f5-a97c-492b-8064-5fa3643b9749",
  "xrx-ca6658d3-f2b4-4de0-9bb8-2e414c0f4d9a",
  "xrx-cb42e379-99d9-4692-8539-e215739d1f17",
  "xrx-7e1b2ed2-2088-4a0c-b2e2-b2c550b56d05",
  "xrx-6bc46d39-4332-4e0c-b191-93185397d93a",
  "xrx-3c046135-c429-42fa-b781-7ef67ee05918",
];
