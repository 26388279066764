import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = 1;

const playSpeedSlice = createSlice({
  initialState,
  name: "playSpeed",
  reducers: {
    setPlaySpeed: (state, action) => {
      return action.payload;
    },
  },
});

export const { setPlaySpeed } = playSpeedSlice.actions;

export default playSpeedSlice.reducer;
