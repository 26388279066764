import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = false;

const factoryOpenSlice = createSlice({
  initialState,
  name: "factoryOpen",
  reducers: {
    setFactoryOpen: (state, action) => {
      return action.payload;
    },
  },
});

export const { setFactoryOpen } = factoryOpenSlice.actions;

export default factoryOpenSlice.reducer;
