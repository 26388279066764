import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = { maxHeight: "736px", maxWidth: "414px" };
//

const maxSizeSlice = createSlice({
  initialState,
  name: "maxSize",
  reducers: {
    setMaxSize: (state, action) => {
      return action.payload;
    },
  },
});

export const { setMaxSize } = maxSizeSlice.actions;

export default maxSizeSlice.reducer;
