export const firstdecide = [
  {
    act: "first",
    anchor: "first",
    target: "body",

    content:
      "In simple IniShare books with only a few parts the book is not divided into chapters, the book is just a list of parts. For more complex books you can use chapters as well. What do you want to do now?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "firstnochapters",
        question: {
          trans: "wszsdfwe22",
          text: "Create a book without chapters (simplest)",
        },
      },
      {
        newride: "firstchapters",
        question: { trans: "bub35afc23", text: "Create a book with chapters." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1g1",
    noNext: true,
  },
];

export const dobookchapters = [
  {
    anchor: "firstchapters",
    act: "firstchapters",
    target: "#createnewbook",
    content: "Click here to create your new book.",
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: true,
    continuous: false,
    noNext: true,
    trans: "dbmx1g2",
  },
  {
    target: "#newbooktitle",
    content: "Add a title for your book.",
    disableBeacon: true,
    spotlightClicks: true,
    xtrafocus: true,
    hideBackButton: true,
    required: true,
    trans: "dbmx2g3",
  },
  // {
  //   target: "#newbooksubtitle",
  //   content: "Add a subtitle to your book (you can leave it empty).",
  //   disableBeacon: true,
  //   spotlightClicks: true,
  //   xtrafocus: true,
  // },
  {
    target: "#newbookauthor",
    content:
      "Specify the author. This is you by default but you can change it.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    xtrafocus: true,
    required: true,
    trans: "dbmx3g4",
  },
  {
    target: "#newbookchapters",
    content:
      "Here you can decide whether you want to divide your book into chapters or just have a list of parts. By default there are no chapters. Check this option now so that you get chapters.",
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: true,
    noNext: true,
    trans: "dbmx3g5",
  },
  // {
  //   target: "#newbookinfo",
  //   content: "Add a subtitle to your book (you can leave it empty).",
  //   disableBeacon: true,
  //   spotlightClicks: true,
  //   xtrafocus: true,
  // },
  {
    target: "#xxcoverdiv",
    content:
      "You can create an individual cover here which is described in a separate tour.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "dbmx4g6",
  },
  {
    target: "#createnewbookbutton",
    anchor: "bookcreate",
    content:
      "Click here to actually create the new book, then wait a few seconds until it's done.",
    disableBeacon: true,
    spotlightClicks: true,
    continuous: false,
    noNext: true,
    hideBackButton: true,
    trans: "dbmx5g7",
  },
  {
    target: "#bbbook0",
    content:
      "Your new book has just been created. Click on its title to open it.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "dbmx6g8",
  },
  {
    target: "body",
    styles: {
      options: {
        overlayColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    content:
      "Here you will see the chapters in the book. There are none yet, though.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "dbmx7g9",
  },
  {
    target: "#moron",
    content: "If you click here you can go back to your library.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    trans: "dbmx7g10",
  },
  {
    target: "body",

    content:
      "Do you also want to see how to create actual chapters for your book?",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "dofirstchapter",
        question: { trans: "wszsdfwe24", text: "Yes, show me how." },
      },
      {
        newride: "",
        question: { trans: "bub35afc25", text: "No. That's enough for now." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intro1g11",
    noNext: true,
  },
];
