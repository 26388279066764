import { setHelpOpen } from "redux/reducers/reducer.help";
import store from "redux/store";

export const helpEditorOpener = (subject: string) => {
  if (subject) {
    subject = "loc";
    //@ts-ignore
    if (window.myApiMain) {
      //@ts-ignore
      window.myApiMain.showHelp(subject);
      return;
    }

    //@ts-ignore
    if (window.myApiView) {
      //@ts-ignore
      window.myApiView.showHelp(subject);
      return;
    }
  }

  store.dispatch(setHelpOpen(subject));
};
