import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = "";

const copyTextSlice = createSlice({
  initialState,
  name: "copyText",
  reducers: {
    setCopyText: (state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setCopyText } = copyTextSlice.actions;

export default copyTextSlice.reducer;
