import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = false;

const listEditorSlice = createSlice({
  initialState,
  name: "listEditorOpen",
  reducers: {
    setListEditorOpen: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setListEditorOpen } = listEditorSlice.actions;

export default listEditorSlice.reducer;
