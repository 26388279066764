import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Button } from "reactstrap";
import { Icon, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { tx } from "utils/globalize";

const ChooseButton = styled(Button)``;

const presetColors: any = {
  strong: [
    "#D0021B",
    "#F5A623",
    "#F8E71C",
    "#8B572A",
    "#7ED321",
    "#417505",
    "#BD10E0",
    "#9013FE",
    "#4A90E2",
    "#50E3C2",
    "#B8E986",
    "#000000",
    "#4A4A4A",
    "#9B9B9B",
    "#FFFFFF",
  ],
  light: [
    "#D0021B",
    "#F5A623",
    "#F8E71C",
    "#8B572A",
    "#7ED321",
    "#417505",
    "#BD10E0",
    "#9013FE",
    "#4A90E2",
    "#50E3C2",
    "#B8E986",
    "#000000",
    "#4A4A4A",
    "#9B9B9B",
    "#FFFFFF",
    "#FDDFDF",
    "#FCF7DE",
    "#DEFDE0",
    "#DEF3FD",
    "#F0DEFD",
    "#EEEEEE",
    "#666666",
  ],
};

const CoverColor = (props: any) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Popup
        style={{ padding: "0px" }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        trigger={
          <div
            className={"mr-50 " + (props.nomargin ? "" : "ml-50")}
            style={{ display: "flex" }}
          >
            <ChooseButton
              outline
              color="primary"
              size="sm"
              id="popTop"
              onClick={(e: any) => e.currentTarget.blur()}
              style={
                props.nomargin
                  ? {
                      backgroundColor: props.color,
                      width: "60px",
                      height: "30px",
                    }
                  : null
              }
            >
              {!props.nomargin ? (
                <Icon
                  name="tint"
                  style={{ color: props.color, fontSize: "15px" }}
                />
              ) : null}
            </ChooseButton>
          </div>
        }
        content={
          <div>
            <div style={{ textAlign: "right", padding: "3px" }}>
              <div
                style={{ display: "inline-block", cursor: "pointer" }}
                onClick={() => setOpen(false)}
              >
                {tx("close1", "close")}
              </div>
            </div>
            <SketchPicker
              color={props.color}
              onChange={(color) => {
                props.setColor(color.hex);
                props.action();
              }}
              presetColors={presetColors[props.type || "light"]}
            />
          </div>
        }
        on="click"
        position={props.position || "bottom center"}
      />
    </React.Fragment>
  );
};

export default CoverColor;
