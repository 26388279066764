import React from "react";
import styled from "styled-components";

const CoverThisDiv = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  z-index: 501;
`;

const CoverDiv = (props: any) => {
  return (
    <React.Fragment>
      <CoverThisDiv onMouseDown={props.clickPopup} />
    </React.Fragment>
  );
};

export default CoverDiv;
