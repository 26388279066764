import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = {
  list: [],
  data: {},
  loading: "idle",
};

const usersSlice = createSlice({
  initialState,
  name: "users",
  reducers: {
    setUsers: (state, action) => {
      return action.payload;
    },
    setUsersData: (state, action) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setUsers, setUsersData } = usersSlice.actions;

export default usersSlice.reducer;
