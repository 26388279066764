import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { open: false, default: "", id: "", ref: "" };

const noteTakerSlice = createSlice({
  initialState,
  name: "noteTaker",
  reducers: {
    setNoteTaker: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setNoteTakerOpen: (state: any, action: PayloadAction<any>) => {
      state.open = action.payload;
    },
  },
});

export const { setNoteTaker, setNoteTakerOpen } = noteTakerSlice.actions;

export default noteTakerSlice.reducer;
