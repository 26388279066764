import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
import { Dimmer } from "semantic-ui-react";

const GlobalLoader = (props: any) => {
  const globalLoading = useSelector<RootState, any>(
    (state) => state.globalLoading
  );
  return (
    <React.Fragment>
      <Dimmer page={true} active={globalLoading} />
    </React.Fragment>
  );
};

export default GlobalLoader;
