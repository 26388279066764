export const paragraph = [
  {
    act: "paradesigner",
    anchor: "paradesigner",
    target: "body",
    placement: "center",
    content:
      "Thiis is the format designer. You can change the formatting of your elements individually and as a style as well.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2asdfk2ck",
  },
  {
    target: "#prselect",
    content:
      "Here you can select which style you want to work with. (Each element type (like paragraphs, videos etc.) have their own styles.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm",
  },
  {
    target: "#prnew",
    content: "You can create a new style with this button.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm1",
  },
  {
    target: "#prdelete",
    content: "You can delete a style with this button.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm2",
  },
  {
    target: "#prfonts",
    content: "You can change the font formatting of the paragraph here.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm3",
  },
  {
    target: "#prpreview",
    content: "This is a rough preview of your style.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm4",
  },
  {
    target: "#prsettings",
    content: "These are the important settings.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm5",
  },
  {
    target: "#checki",
    content:
      "If this option is checked for a style, any new inserted element of this type will use this style.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm6",
  },
  {
    target: "#pronlythis",
    content:
      "If you click on this button the changes will be applied only for the selected paragraphs or elements but the style itself will not change.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm7",
  },
  {
    target: "#prupdateall",
    content:
      "If you click here, the style will be updated with the new settings everywhere in your book so all other paragraphs with this style will change.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm8",
  },
  {
    target: "#prreset",
    content:
      "This button is only shown if you made some changes or if your selected paragraphs have changes compared to the official selected style. Click on this button to remove those changes.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbwermx22k4cm9",
  },
];
