import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const avatarsSlice = createSlice({
  initialState: {
    myAvatar: "/images/download.png",
    yourAvatar: "/images/download.png",
  },
  name: "avatarsStatus",
  reducers: {
    setAvatars: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setAvatars } = avatarsSlice.actions;

export default avatarsSlice.reducer;
