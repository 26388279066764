// import axios from "axios";
import { cloudStorage, firestore, functions } from "firebaseUtil/firebaseUtil";
import { httpsCallableFromURL } from "firebase/functions";
import store from "redux/store";
// import * as fflate from "fflate";
import { v4 } from "uuid";
import { setCurrentBookReducer } from "redux/reducers/reducer.currentBook";
import { setBookLoaded } from "redux/reducers/reducer.bookLoaded";
import { outOfStorageQuota } from "./outofquota";
import { canUpload } from "./validMember";
import axios from "axios";
import { db } from "./dexie";
import * as fflate from "fflate";
import { setTutorialVarIndiv } from "redux/reducers/reducer.tutorialVars";
import { setBookStatusPart } from "redux/reducers/reducer.bookStatus";
import { updateSetting } from "firebaseUtil/firebaseUtil.firestore";
import { setConfirm } from "redux/reducers/reducer.confirm";
import { tx } from "./globalize";
import {
  getMetadata,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { setEditMode } from "redux/reducers/reducer.editmode";
import { sampleBooks } from "library/bookshelf/bookshelf.samples";

const setStatus = (type: any, status: any) => {
  localStorage.setItem(
    store.getState().currentUser?.id + "status",
    JSON.stringify({ ...store.getState().bookStatus, [type]: status })
  );
  store.dispatch(setBookStatusPart({ id: type, value: status }));
};

export const recurseFirebaseUrl = (url: string) => {
  if (!url.indexOf("https://firebasestorage")) {
    return decodeURIComponent(url.split("/o/")[1].split("?")[0]);
  } else {
    return url;
  }
};

export const recurseFirebaseUrlFull = (url: string) => {
  if (!url.indexOf("https://firebasestorage")) {
    return decodeURIComponent(url.split("/o/")[1]);
  } else {
    return url;
  }
};

const loadBookData = async (book: string) => {
  const getSigned = httpsCallableFromURL(
    functions,
    "https://selector-okdm2bbxtq-uc.a.run.app"
  );

  const newUrl = (await getSigned({
    fn: "getSigned",
    src: "books/" + book + "/full",
    type: "read",
  })) as any;

  // const url = await cloudStorage
  //   .refFromURL("gs://inisharex.appspot.com/books/" + book + "/full")
  //   .getDownloadURL();

  const url = newUrl.data.src;

  const newFile = await axios.get(url, { responseType: "arraybuffer" });

  const decompressed = fflate.decompressSync(new Uint8Array(newFile.data));
  const origText = fflate.strFromU8(decompressed);
  return origText;
};

export const getSignedUrl = async (line: string, type: string = "read") => {
  if (line.indexOf("https://") === 0) {
    return line;
  }

  const getSigned = httpsCallableFromURL(
    functions,
    "https://selector-okdm2bbxtq-uc.a.run.app"
  );

  const newUrl = (await getSigned({
    fn: "getSigned",
    src: line,
    type:
      store.getState().currentBook.id.indexOf("xrx-") === 0 ? "read" : "write",
  })) as any;

  return newUrl.data.src;
};

// export const getBook = (book: any) => {
//   if (bookreader) {
//     bookreader();
//   }
//   store.dispatch(setBookLoaded(false));

//   bookreader = doc(firestore,"/booksa/" + book).onSnapshot(
//     (snapshot) => {
//       store.dispatch(
//         setCurrentBookReducer({ ...snapshot.data(), editable: true })
//       );
//       store.dispatch(setBookLoaded(true));
//     },
//     () => console.log("booki")
//   );
// };

export const getBook = async (book: string, confirm = false) => {
  const books = store.getState().myBooks;

  let myBook: any;

  let loadagain = false;

  if (!confirm) {
    if (!books?.[book]?.update) {
      const tempMyBook = await db.books.get(book);
      myBook = tempMyBook?.book;
    }

    if (book.indexOf("xrx-")) {
      store.dispatch(setEditMode(true));
    } else {
      store.dispatch(setEditMode(false));
    }

    // if (book.indexOf("xrx-") === 0) {
    //   const meta = await cloudStorage
    //     .refFromURL("gs://inisharex.appspot.com/books/" + book + "/full")
    //     .getMetadata();

    //   console.log(new Date(meta.updated).valueOf());
    // }

    if (!book.indexOf("xrx-")) {
      const meta = await getMetadata(
        ref(cloudStorage, "gs://inisharex.appspot.com/books/" + book + "/meta")
      );

      if (myBook) {
        if (myBook.date < new Date(meta.updated).valueOf()) {
          console.log("load again");

          loadagain = true;

          myBook = undefined;
        }
      }
    }
  }

  if (!myBook) {
    if (!book.indexOf("xrx-")) {
      myBook = { ...JSON.parse(await loadBookData(book)), date: Date.now() };

      if (loadagain && !sampleBooks.find((item: any) => item.sample === book)) {
        await setDoc(
          doc(
            firestore,
            "/users/" + store.getState().currentUser?.id + "/data/books"
          ),
          { [book]: { ...myBook.basics } },
          { merge: true }
        );
      }

      if (myBook) {
        await db.books.put({
          id: myBook.id,
          date: Date.now(),
          book: { ...myBook },
        });
      }
    } else {
      const tempBook = await getDoc(doc(firestore, "/booksa/" + book));

      myBook = tempBook.data();
    }
  }

  store.dispatch(setCurrentBookReducer({ ...myBook, editable: true }));

  if (store.getState().rideSteps) {
    store.dispatch(
      setTutorialVarIndiv({ var: "tocLength", value: myBook.toc.length })
    );
    store.dispatch(
      setTutorialVarIndiv({
        var: "tocFirstLength",
        value: myBook.toc[0] ? myBook.cats[myBook.toc[0].id].length : 0,
      })
    );
  }

  if (!myBook.basics.chapters) {
    setStatus("cat", myBook.toc[0].id);
  }

  store.dispatch(setBookLoaded(true));

  return myBook;

  // cloudStorage.ref("gs://publishedappbook4d/" + book + "/versions/" + version);
};

export const getDirectory = (editorId: string, add = "") => {
  if (editorId === "editor") {
    return (
      store.getState().currentUser?.id +
      "/" +
      store.getState().currentBook.id +
      "/" +
      store.getState().bookStatus.exercise
    );
  }

  if (!editorId.indexOf("status")) {
    return (
      store.getState().currentUser?.id +
      "/" +
      store.getState().currentBook.id +
      "/" +
      editorId.replace("status", "")
    );
  }

  if (editorId === "clipboard") {
    return "clipboard/" + store.getState().currentUser?.id;
  }
  return store.getState().currentUser?.id + "/" + editorId + "/cover";
};

export const uploadGCFile = async (
  dir: string,
  file: any,
  setFname: any,
  setIsLoading: any,
  setStatus: any = undefined,
  isClipboard: any = false
) => {
  if (!canUpload()) {
    setIsLoading(false);
    setStatus(undefined);
    outOfStorageQuota();
    return;
  }

  setStatus?.(0);

  var newMetadata = {
    origin: ["*"],
    method: ["GET"],
    responseHeader: ["Content-Type"],
    cacheControl: "private, max-age=7776000000, immutable",
    contentType: file.type,
    customMetadata: {
      uid: store.getState().currentUser?.id,
      owner: "|" + store.getState().currentBook?.basics?.owner?.join("|") + "|",
      bowner: "a",
    },
  };

  let id = v4();

  // dir = "coverpics";

  const refId = "gs://inisharex.appspot.com/files/" + dir + "/" + id;

  const myRef = ref(cloudStorage, refId);

  const upload = uploadBytesResumable(myRef, file, newMetadata);

  upload.on(
    "state_changed",
    //@ts-ignore

    function (snapshot) {
      var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setStatus?.(percent);
    }
  );

  const res = await upload;

  const path = await getDownloadURL(myRef);

  //const path1 = await getSignedUrl(fileId, "write");

  if (!setFname) {
    return {
      url: path,
      filename: file.name,
      size: res.totalBytes,
      iType: file.type,
    };
  }

  if (setFname.current) {
    setFname.current(path, file.name, res.totalBytes, file.type);
  } else {
    setFname(path, file.name, res.totalBytes, file.type);
  }

  setTimeout(() => {
    setIsLoading(false);
    setStatus(undefined);
  });
};

export const deleteGCfiles = async (list: any) => {
  list = [...new Set([...list])];

  const delList: any[] = [];

  for (let g = 0; g < list.length; g++) {
    if (list[g]) {
      if (!list[g].indexOf("gs://")) {
        try {
          delList.push(deleteObject(ref(cloudStorage, list[g])));
        } catch (e) {}
      } else {
        try {
          delList.push(deleteObject(ref(cloudStorage, list[g])));
        } catch (e) {}
      }
    }
    try {
      await Promise.all([...delList]);
    } catch (e) {}
  }
};

export const duplicateGCFile = async (src: string, setIsLoading: any) => {
  if (!canUpload()) {
    setIsLoading(false);
    outOfStorageQuota();
    return "";
  }
  const copyClipboardGC = httpsCallableFromURL(
    functions,
    "https://selector-okdm2bbxtq-uc.a.run.app"
  );

  const srcId = recurseFirebaseUrl(src);

  const newId = v4();

  await copyClipboardGC({
    fn: "copyClipboardGC",
    src: srcId,
    dest:
      "files/" +
      store.getState().currentUser?.id +
      "/" +
      store.getState().currentBook.id +
      "/" +
      store.getState().bookStatus.exercise +
      "/" +
      newId,
    owner: "|" + store.getState().currentBook?.basics?.owner?.join("|") + "|",
  });

  const url = await getDownloadURL(
    ref(
      cloudStorage,
      "gs://inisharex.appspot.com/files/" +
        store.getState().currentUser?.id +
        "/" +
        store.getState().currentBook.id +
        "/" +
        store.getState().bookStatus.exercise +
        "/" +
        newId
    )
  );

  return url;
};

export const checkBookForUpdate = async () => {
  const book = store.getState().currentBook;

  if (
    !book?.id ||
    book?.id.indexOf("xrx-") ||
    store.getState().bookStatus.status === "books"
  ) {
    return;
  }

  const lastChecked = store.getState().settings.bookTime?.[book.id];

  console.log("checking1");
  console.log(lastChecked);

  if (lastChecked && lastChecked < Date.now() - 30000) {
    console.log("checkforbook");

    const meta = await getMetadata(
      ref(cloudStorage, "gs://inisharex.appspot.com/books/" + book.id + "/meta")
    );

    if (book.date < new Date(meta.updated).valueOf()) {
      console.log("chheckfound");

      store.dispatch(
        setConfirm({
          confirmButton: tx("update", "Update"),
          onConfirm: async () => {
            await getBook(book.id, true);
          },
          header: tx("neweweconf", "Confirm..."),
          cancelButton: tx("laterxxx", "Later"),
          content: tx(
            "nweewewesure",
            "A new version of this book available. Download?."
          ),
          open: true,
        })
      );
    }
    updateSetting({ bookTime: { [book.id]: Date.now() } });
  }
};

export const uploadInvoice = async (file: any, add: string = "") => {
  var newMetadata = {
    origin: ["*"],
    method: ["GET"],
    responseHeader: ["Content-Type"],
    cacheControl: "private, max-age=7776000000, immutable",
    contentType: file.type,
    customMetadata: {
      uid: store.getState().currentUser?.id,
      owner: "|" + store.getState().currentBook?.basics?.owner?.join("|") + "|",
      bowner: "a",
    },
  };

  const refId =
    "gs://inisharex.appspot.com/" +
    add +
    "invoices/" +
    store.getState().currentUser?.id;

  const myRef = ref(cloudStorage, refId);

  const upload = uploadBytesResumable(myRef, file, newMetadata);

  await upload;

  const path = await getDownloadURL(myRef);

  return path;
};
