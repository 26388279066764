//student
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
import { setConfirm } from "redux/reducers/reducer.confirm";
import store from "redux/store";

import { tx } from "./globalize";
import { setIsLoading } from "redux/reducers/reducer.isLoading";

export function isScrolledIntoView(el: Element | null) {
  if (!el) {
    return true;
  }
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;

  // Only completely visible elements return true:
  var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export const phaseBodyOut = () => {
  document.getElementById("dimmerlite")?.classList.add("mfhidden");
  document.getElementById("mainmenu")?.classList.add("mfhidden");
  document.getElementById("loaderdiv")?.classList.remove("ishidden");
};

export const phaseBodyIn = () => {
  document.getElementById("dimmerlite")?.classList.remove("mfhidden");
  document.getElementById("mainmenu")?.classList.remove("mfhidden");
};
document.getElementById("loaderdiv")?.classList.add("ishidden");

export const phaseOut = (div: string = "appmain") => {
  document.getElementById(div)?.classList.add("mfhidden");
  store.dispatch(setIsLoading(true));
};

export const phaseFullOut = (div: string = "appmain") => {
  document.getElementById(div)?.classList.add("mfhidden");
  document.getElementById("appheader")?.classList.add("mfhidden");
};

export const phaseIn = (div: string = "appmain") => {
  document.getElementById(div)?.classList.remove("mfhidden");
  document.getElementById("ipaper")?.scrollTo(0, 0);
  store.dispatch(setIsLoading(false));
};

export const phaseFullIn = (div: string = "appmain") => {
  document.getElementById(div)?.classList.remove("mfhidden");
  document.getElementById("appheader")?.classList.remove("mfhidden");
  document.getElementById("ipaper")?.scrollTo(0, 0);
};

export const phaseOut400 = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.add("mfhidden400");
};

export const phaseIn400 = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.remove("mfhidden400");
};

export function shuffleArray(arrayIn: any[]) {
  let array = [...arrayIn];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const stepPhased = (doThis: any) => {
  phaseOut400("qualquestion");
  setTimeout(() => {
    doThis();
    phaseIn400("qualquestion");
  }, 400);
};

export const disableAction = () => {
  const mainFrame = document.getElementById("mainFrame");
  if (mainFrame) {
    mainFrame.style.pointerEvents = "none";
  }
};

export const enableAction = () => {
  const mainFrame = document.getElementById("mainFrame");
  if (mainFrame) {
    mainFrame.style.pointerEvents = "auto";
  }
};

export const beginsWith = (needle: string, arr: string | string[]) => {
  if (typeof arr === "string") {
    arr = [arr];
  }
  for (let g = 0; g < arr.length; g++) {
    if (needle.indexOf(arr[g]) === 0) {
      return true;
    }
  }
  return false;
};

export const contains = (needle: string, arr: string | string[]) => {
  if (typeof arr === "string") {
    arr = [arr];
  }
  for (let g = 0; g < arr.length; g++) {
    if (needle.indexOf(arr[g]) > -1) {
      return true;
    }
  }
  return false;
};

export const encodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&/g, "&amp;")
    .replace(/>/g, "gt;")
    .replace(/</g, "lt;");
};

export const decodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&nbsp;/g, " ");
};

export const getTitle = () => {
  if (document.location.hostname === "szv.gyakorlatias-angol.hu") {
    return "SzókincsVarázsló";
  } else {
    return "NetEnglish";
  }
};

export const hideOut = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.add("fullhidden");
};

export const hideIn = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.remove("fullhidden");
};

export function isSuperset(set: any, subset: any) {
  for (let elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

export const routeNice = (route: string, history: any) => {
  phaseOut();
  setTimeout(() => history.push(route), 150);
};

export function countWords(str: string = "") {
  if (!str.trim()) {
    return 0;
  }
  return str.trim().split(/\s+/).length;
}

let interval: any = null;

export const waitFor = (element: any, resolve: any, extra = 0) => {
  interval = setInterval(() => {
    if (document.querySelector(element)) {
      setTimeout(() => {
        clearInterval(interval);
        document.querySelector(element).scrollIntoView();
        resolve("x");
      }, extra);
    } else {
      console.log(element);
    }
  }, 100);
};

export const waitForLimit = (
  element: any,
  resolve: any,
  callback: any,
  reject: any
) => {
  let count = 0;
  interval = setInterval(() => {
    if (document.querySelector(element) || count === 5) {
      clearInterval(interval);
      if (document.querySelector(element)) {
        document.querySelector(element).scrollIntoView();

        resolve("x");
      } else {
        reject("x");
        callback();
      }
    } else {
      count++;
    }
  }, 100);
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const useTeacherStatus = () => {
  const isTeacher = useSelector<RootState, any>(
    (state) => state.currentUser?.type === "teacher"
  );
  const fakeStudent = useSelector<RootState, any>((state) => state.fakeStudent);

  return [fakeStudent, isTeacher && !fakeStudent];
};

export const showNoAppDialog = () => {
  store.dispatch(
    setConfirm({
      confirmButton: tx("ok", "OK"),
      onConfirm: () => {},
      header: tx("tdpv7yPTImcgweNye_", "Use the App Version..."),
      cancelButton: "",
      content: (
        <React.Fragment>
          <div>
            {tx(
              "aZSmswe4ri6tTzaoTLy",
              "Please download and use the app version of IniShare for this function"
            )}
          </div>
        </React.Fragment>
      ),
      open: true,
    })
  );
};
