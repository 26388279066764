import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const commentingSlice = createSlice({
  initialState,
  name: "commenting",
  reducers: {
    setCommenting: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setCommenting } = commentingSlice.actions;

export default commentingSlice.reducer;
