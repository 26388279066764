import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = "";

const defaultExerciseSlice = createSlice({
  initialState,
  name: "defaultExercise",
  reducers: {
    setDefaultExercise: (state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setDefaultExercise } = defaultExerciseSlice.actions;

export default defaultExerciseSlice.reducer;
