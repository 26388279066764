import { Device } from "@capacitor/device";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { setTempLanguage } from "redux/reducers/reducer.tempLanguage";
import { RootState } from "redux/reducers/_index";
import store from "redux/store";
import { platform } from "utils/drive";
import Translation from "./Translation";

const ifSupported = () => {
  let langer = "en";

  const languages = navigator.languages || [];

  for (let l = 0; l < languages.length; l++) {
    const navLang = languages[l].slice(0, 2);

    if (["en", "hu"].includes(navLang)) {
      langer = navLang;
      break;
    }
  }
  return langer;
};

const TranslationService = (props: any) => {
  const [ready, setReady] = useState(false);
  const location = useLocation();

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  useEffect(() => {
    const startApp = async () => {
      let langCode = "";

      if (platform !== "web") {
        langCode = (await Device.getLanguageCode()).value;
      }

      let language =
        currentUser?.language ||
        location.search?.match(/lang=[^&]*/)?.[0].replace("lang=", "") ||
        localStorage.getItem("language") ||
        langCode ||
        ifSupported();

      localStorage.setItem("language", language || "");

      if (store.getState().tempLanguage === language && ready) {
        return;
      }

      store.dispatch(setTempLanguage(language));
      localStorage.setItem("language", language);
      // store.dispatch(setAppTransParts({ ...translation, loaded: true }));

      // try {
      //   (await loadAppTranslation(language)) as any;
      // } catch (e) {
      //   console.log(e);
      // }

      setTimeout(() => {
        setReady(true);
      }, 0);
    };

    startApp();
  }, [location.search, ready, currentUser]);

  return (
    <React.Fragment>
      <Translation />
    </React.Fragment>
  );
};

export default TranslationService;
