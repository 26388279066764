import { setAuthLoaded } from "redux/reducers/reducer.authLoaded";
import { setBookmarks } from "redux/reducers/reducer.bookmarks";
// import { setChat } from "redux/reducers/reducer.chat";
// import { setChatCount } from "redux/reducers/reducer.chatCount";
// import { setChatPages } from "redux/reducers/reducer.chatPages";
import { setClipboardGlobal } from "redux/reducers/reducer.clipboard";
import { setCurrentUser } from "redux/reducers/reducer.currentUser";
import { setDateline } from "redux/reducers/reducer.dateline";
import { setDatelineLoaded } from "redux/reducers/reducer.datelineLoaded";
import { setMyBooks } from "redux/reducers/reducer.myBooks";
import { setSchedule } from "redux/reducers/reducer.schedule";
import { setSettings } from "redux/reducers/reducer.settings";
import { setUsers } from "redux/reducers/reducer.users";
import { setUserSetup } from "redux/reducers/reducer.userSetup";
import { setWords } from "redux/reducers/reducer.words";
import store from "redux/store";
import { dicBase } from "utils/languages/languages.dictionaries";
import { canUse } from "utils/permissions";

// import { firestore } from "../firebaseUtil/firebaseUtil";
import { firestore } from "../firebaseUtil/firebaseUtil";
import { setQuotas } from "redux/reducers/reducer.quotas";
import { showToast } from "utils/Toast";
import { tx } from "utils/globalize";
import { setEditMode } from "redux/reducers/reducer.editmode";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  onSnapshot,
  setDoc,
} from "firebase/firestore";

const defaultSettings = {
  testReview: false,
  autoRead: true,
  pronunciation: true,
  writing: true,
  readSpeed: 1,
  playSpeed: 1,
  pro: true,
  startingLevel: "A",
  showFirstPage: true,
  wordLevel: "A",
  playing: true,
  highlight: true,
  fontSize: 18,
  head: true,
  trialUser: false,
  hideTutorial: false,
  autoTexts: [],
  testRepetitions: 5,
  exeRepetitions: 3,
  status: { status: "books" },
  theme: "blue",
};

let datelineListener: any = null;
let userListener: any = null;
// let chatListener: any = null;
// let studentScheduleListener: any = null;
let quotaListener: any = null;

let trials = 0;

export const setupListeners = (userRef: DocumentReference<DocumentData>) => {
  //********************************
  //** User Snapshot
  //********************************
  console.log("start setting up listeners");

  userListener = onSnapshot(
    userRef,
    async (snapShot: DocumentSnapshot) => {
      trials = 0;

      if (snapShot.data()) {
        store.dispatch(
          setCurrentUser({
            ...snapShot.data(),
            id: userRef.id,
          })
        );
      } else {
        // console.log("bubornyak");
        store.dispatch(setCurrentUser(null));
        return;
      }

      const settingsTemp = localStorage.getItem(`ne_settings${userRef.id}`);

      if (settingsTemp) {
        store.dispatch(setSettings(JSON.parse(settingsTemp)));
      }

      setupCombinedListener(userRef.id);
      setupQuotaListener(userRef.id);

      localStorage.setItem("currentUser", userRef.id);
      localStorage.setItem("userType", snapShot.data()?.type);
      localStorage.setItem("userLang", snapShot.data()?.language);

      store.dispatch(setAuthLoaded(true));

      trials = 0;
    },
    (error) => {
      console.log("this is the userlistener errror");
      if (error.code === "permission-denied" && trials < 3) {
        console.log(userRef.id);
        // setTimeout(() => setupListeners(userRef), 100);
        trials++;
      }

      console.log("userlistener", error);
    }
  );
};

// export const chatListenerSetup = () => {
//   const userSetup = store.getState().userSetup;

//   if (chatListener) {
//     chatListener();
//   }

//   if (!userSetup?.ref) {
//     return;
//   }

//   const newChatRef = firestore
//     .collection(`${userSetup.ref}/chat`)
//     .orderBy("lastMessage", "desc")
//     .limit(store.getState().chatPages);

//   chatListener = newChatRef.onSnapshot(
//     async (snapShot) => {
//       console.log("chat success");

//       if (!snapShot.empty) {
//         const tempSnapshot = [...snapShot.docs];
//         if (snapShot.docs.length < store.getState().chatPages) {
//           store.dispatch(setChatPages(snapShot.docs.length));
//         }
//         tempSnapshot.reverse();
//         store.dispatch(
//           setChat([].concat(...tempSnapshot.map((doc) => doc.data().messages)))
//         );

//         setTimeout(() => {
//           document.getElementById("gr-lastmessage")?.scrollIntoView();
//         }, 100);
//       } else {
//         store.dispatch(setChat([]));
//       }

//       snapShot.docChanges().forEach((change) => {
//         if (change.doc.id === "pt") {
//           store.dispatch(setChatCount(change.doc.data()?.count || 0));
//         }
//       });
//     },
//     (error) => {
//       console.log("chatlistener", error);
//     }
//   );
// };

export const dateLineListenerSetup = () => {
  //********************************
  //** Dateline Snapshot
  //********************************

  if (datelineListener) {
    datelineListener();
  }

  const userSetup = store.getState().userSetup;

  if (!userSetup.ref) {
    return;
  }

  const datelineRef = doc(firestore, `${userSetup.ref}/common/dateline`);

  datelineListener = onSnapshot(
    datelineRef,
    async (snapShot) => {
      console.log("dateline success");
      store.dispatch(setDateline(snapShot.data()?.dateline || []));
      store.dispatch(setDatelineLoaded(userSetup.ref));
    },
    (error) => {
      console.log("datelinelistener", error);
    }
  );
};

export const setupQuotaListener = (currentUserId: string) => {
  //********************************
  //** Quota Listener Snapshot
  //********************************

  if (quotaListener) {
    quotaListener();
  }

  if (!currentUserId) {
    return;
  }

  const quotaRef = doc(firestore, `/users/${currentUserId}/quotas/quotasingle`);
  console.log("quotasingleset");

  quotaListener = onSnapshot(
    quotaRef,
    async (snapShot) => {
      store.dispatch(setQuotas(snapShot?.data() || {}));
    },
    (error) => {
      console.log("quotaslistener", error);
    }
  );
};

let combinedListener: any = null;

export const setupCombinedListener = (userId: string) => {
  if (combinedListener) {
    combinedListener();
  }

  if (!userId) {
    return;
  }

  let userDataRef: any = undefined;

  if (store.getState().currentUser?.email === "binger@netenglish.com") {
    store.dispatch(setEditMode(false));
    let usecheck: any = document.location.href
      ?.match(/usecheck=[^&]*/)?.[0]
      .replace("usecheck=", "");

    if (usecheck) {
      localStorage.setItem("usecheck", usecheck);
    }

    usecheck = localStorage.getItem("usecheck");

    console.log(usecheck);

    userDataRef = collection(
      firestore,
      `/users/${usecheck || "Dj907KH7nleE8YYBEJ3BF1Xngbq1"}/data`
    );
  } else {
    userDataRef = collection(firestore, `/users/${userId}/data`);
  }

  combinedListener = onSnapshot(
    userDataRef,
    async (snapShot: any) => {
      snapShot.docChanges().forEach((change: any) => {
        switch (change.doc.id) {
          case "settings":
            store.dispatch(
              setSettings({
                ...((change.doc.data() as any) || {
                  ...defaultSettings,
                  dics: [
                    ...dicBase[
                      store
                        .getState()
                        .currentUser?.language?.replace("en", "") || "none"
                    ],
                    ...dicBase["en"],
                  ],
                }),
                loaded: true,
              })
            );
            if (change.doc.data().showFirstPage === undefined) {
              setDoc(
                doc(firestore, `/users/${userId}/data/settings`),
                defaultSettings
              );
            }
            localStorage.setItem(
              `ne_settings${userId}`,
              JSON.stringify(change.doc.data() || defaultSettings)
            );
            return;
          case "userlist":
            let result: any = { ...(change.doc.data() || { list: [] }) };
            if (store.getState().fakeStudent) {
              result = {
                list: [store.getState().currentUser?.id],
                [store.getState().currentUser?.id]: {
                  shown: true,
                  status: "1-active",
                },
                [store.getState().currentUser?.id + "-c"]: {
                  id: store.getState().currentUser?.id,
                  firstName: store.getState().currentUser?.firstName,
                  lastName: store.getState().currentUser?.lastName,
                },
                [store.getState().currentUser?.id + "-p"]:
                  store.getState().currentUser?.pic,
                [store.getState().currentUser?.id + "-m"]: [
                  {
                    auto: false,
                    content: "",
                    time: Date.now(),
                  },
                ],
              };
            }

            store.dispatch(setUsers(result));

            return;
          case "schedule":
            if (
              store.getState().currentUser?.type !== "teacher" ||
              store.getState().fakeStudent
            ) {
              return;
            }
            {
              const tempSchedule = change.doc.data() || {};
              const scheduleKeys = Object.keys(tempSchedule);

              scheduleKeys.sort();

              const schedule = scheduleKeys.map((item) =>
                JSON.parse(tempSchedule[item])
              );

              store.dispatch(setSchedule(schedule));
            }
            return;
          case "usersetup":
            const currentUser = store.getState().currentUser;

            if (canUse("chat", store.getState().currentUser?.permissions)) {
              store.dispatch(
                setUserSetup({
                  ...(change.doc.data() || {
                    me: {
                      id: store.getState().currentUser?.id,
                      name:
                        store.getState().currentUser?.firstName +
                        " " +
                        store.getState().currentUser?.lastName,
                    },
                  }),
                  bubo: "aasd",
                })
              );
            } else {
              store.dispatch(
                setUserSetup({
                  ...(change.doc.data() || {
                    ref:
                      "/teachers/" +
                      currentUser?.id +
                      "/students/" +
                      currentUser?.id,
                    me: {
                      id: store.getState().currentUser?.id,
                      name:
                        store.getState().currentUser?.firstName +
                        " " +
                        store.getState().currentUser?.lastName,
                    },
                    you: {
                      id: store.getState().currentUser?.id,
                      name:
                        store.getState().currentUser?.firstName +
                        " " +
                        store.getState().currentUser?.lastName,
                    },
                  }),
                  bubo: "aasd",
                })
              );
            }
            dateLineListenerSetup();
            store.dispatch(setDatelineLoaded(change.doc.data().ref));
            return;

          case "words":
            store.dispatch(setWords(change.doc.data()?.wordRaw || {}));
            return;
          case "bookmarks":
            store.dispatch(setBookmarks(change.doc.data() || {}));
            return;
          case "clipboard":
            if (
              change.doc.data()["Transcripts"]?.length >
              store.getState().clipboardGlobal["Transcripts"]?.length
            ) {
              showToast({
                title: tx("asdfwweoewo", "New transcript..."),
                description: tx(
                  "asdf089wews8wekkskas",
                  "New transcript on the clipboard."
                ),
                type: "success",
                time: 5000,
                position: "bottom-right",
              });
            }

            store.dispatch(setClipboardGlobal(change.doc.data() || {}));
            return;

          case "books":
            store.dispatch(setMyBooks(change.doc.data()));
            return;
          // if (!change.doc.data()[store.getState().currentBook?.basics?.id]) {
          //   store.dispatch(
          //     setBookStatus({
          //       bookId: "",
          //       status: "books",
          //       cat: "",
          //       exercise: "exewewe",
          //     })
          //   );
          // }
          // return;
        }
      });
    },
    (error: any) => {
      console.log("combined listener setup error", error);
    }
  );

  return combinedListener;
};

export const cleanListeners = () => {
  console.log("cleaningListeners");

  if (userListener) {
    userListener();
    userListener = null;
  }

  if (quotaListener) {
    quotaListener();
    quotaListener = null;
  }

  if (combinedListener) {
    combinedListener();
    combinedListener = null;
  }

  // if (chatListener) {
  //   chatListener();
  //   chatListener = null;
  // }

  if (datelineListener) {
    datelineListener();
    datelineListener = null;
  }

  // if (studentScheduleListener) {
  //   studentScheduleListener();
  //   studentScheduleListener = null;
  // }
};
