//student
//tx("asdfasdf1upp","===Updating")
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
import { Dimmer, Loader } from "semantic-ui-react";
import { tx } from "utils/globalize";

const AppUpdating = (props: any) => {
  const updating = useSelector<RootState, any>((state) => state.updating);
  return (
    <React.Fragment>
      <Dimmer active={updating} page style={{ zIndex: 50000 }}>
        <Loader content={tx("updatinfasdff", "Updating...")} />
      </Dimmer>
    </React.Fragment>
  );
};

export default AppUpdating;
