export const styleSeq: any = {
  Body: "a010",
  "Title Centered": "a020",
  "Heading 1": "a030",
  "Heading 1 wo space": "a040",
  "Heading 2": "a050",
  "Heading 3": "a060",
  Caption: "a070",
  Quote: "a080",
  "Small byline": "a090",
  "Small byline w space": "a100",
  Signature: "a110",
  "Signature no space": "a120",
  Source: "a130",
  Standard: "a140",
  "Standard w caption": "a150",
};

export const baseStyles: any = {
  "paragraph-Body": {
    innerCss: "width: 100%",
    outerCss:
      'padding-top: 6px;\npadding-bottom: 6px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 18px;\nfont-weight: 400;\nfont-style: normal;\nfont-family: "Raleway";\ncolor: #000000;\n-webkit-text-align: justify;\ntext-align: left',
    style: {},
    type: "paragraph",
    name: "Body",
    seq: "a0010",
    custom: {},
  },
  "paragraph-Title Centered": {
    name: "Title Centered",
    type: "paragraph",
    outerCss:
      'padding-top: 18px;\npadding-bottom: 18px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 24px;\nfont-weight: 700;\nfont-style: normal;\nfont-family: "Raleway";\ncolor: #4a90e2;\n-webkit-text-align: center;\ntext-align: center',
    innerCss: "width: 100%",
    seq: "a0020",
    custom: {},
  },
  "paragraph-Heading 1": {
    custom: {},
    style: {},
    outerCss:
      'padding-top: 18px;\npadding-bottom: 12px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 24px;\nfont-weight: 700;\nfont-style: normal;\nfont-family: "Raleway";\ncolor: #4a90e2',
    name: "Heading 1",
    type: "paragraph",
    innerCss: "width: 100%",
    seq: "a0030",
  },
  "paragraph-Heading 1 wo space": {
    name: "Heading 1 wo space",
    custom: {},
    outerCss:
      'padding-top: 18px;\npadding-bottom: 3px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 24px;\nfont-weight: 700;\nfont-style: normal;\nfont-family: "Raleway";\ncolor: #4a90e2',
    innerCss: "width: 100%",
    type: "paragraph",
    seq: "a0040",
  },
  "paragraph-Heading 2": {
    name: "Heading 2",
    style: {},
    custom: {},
    outerCss:
      'padding-top: 12px;\npadding-bottom: 12px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 21px;\nfont-weight: 700;\nfont-style: normal;\nfont-family: "Raleway";\ncolor: #4a90e2',
    innerCss: "width: 100%",
    type: "paragraph",
    seq: "a0050",
  },
  "paragraph-Heading 3": {
    type: "paragraph",
    custom: {},
    outerCss:
      'padding-top: 12px;\npadding-bottom: 12px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 18px;\nfont-weight: 700;\nfont-style: normal;\nfont-family: "Raleway";\ncolor: #000000',
    name: "Heading 3",
    innerCss: "width: 100%",
    style: {},
    seq: "a0060",
  },

  "paragraph-Caption": {
    custom: {},
    innerCss: "width: 80%",
    type: "paragraph",
    name: "Caption",
    outerCss:
      'padding-top: 6px;\npadding-bottom: 12px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 14px;\nfont-weight: 400;\nfont-style: normal;\nfont-family: "Raleway";\ncolor: #000000;\n-webkit-text-align: center;\ntext-align: center',
    seq: "a0070",
  },
  "paragraph-Quote": {
    custom: {},
    innerCss: "width: 100%",
    outerCss:
      'padding-top: 6px;\npadding-bottom: 6px;\npadding-left: 18px;\npadding-right: 12px;\nline-height: 130%;\nfont-size: 18px;\nfont-weight: 400;\nfont-style: italic;\nfont-family: "Raleway";\ncolor: #888888',
    type: "paragraph",
    name: "Quote",
    seq: "a0080",
  },
  "paragraph-Small byline": {
    name: "Small byline",
    type: "paragraph",
    outerCss:
      'padding-top: 0px;\npadding-bottom: 0px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 14px;\nfont-weight: 700;\nfont-style: italic;\nfont-family: "Raleway";\ncolor: #000000',
    custom: {},
    innerCss: "width: 100%",
    seq: "a0090",
  },

  "paragraph-Small byline w space": {
    outerCss:
      'padding-top: 0px;\npadding-bottom: 12px;\npadding-left: 6px;\npadding-right: 6px;\nline-height: 130%;\nfont-size: 14px;\nfont-weight: 700;\nfont-style: italic;\nfont-family: "Raleway";\ncolor: #000000',
    type: "paragraph",
    name: "Small byline w space",
    innerCss: "width: 100%",
    custom: {},
    seq: "a0100",
  },

  "paragraph-Signature": {
    custom: {},
    name: "Signature",
    type: "paragraph",
    innerCss: "width: 100%",
    outerCss:
      'padding-top: 0px;\npadding-bottom: 6px;\npadding-left: 6px;\npadding-right: 18px;\nline-height: 130%;\nfont-size: 18px;\nfont-weight: 400;\nfont-style: italic;\nfont-family: "Raleway";\ncolor: #666666;\n-webkit-text-align: right;\ntext-align: right',
    seq: "a0110",
  },
  "paragraph-Signature no space": {
    innerCss: "width: 100%",
    type: "paragraph",
    name: "Signature no space",
    custom: {},
    outerCss:
      'padding-top: 0px;\npadding-bottom: 0px;\npadding-left: 6px;\npadding-right: 18px;\nline-height: 130%;\nfont-size: 18px;\nfont-weight: 400;\nfont-style: italic;\nfont-family: "Raleway";\ncolor: #666666;\n-webkit-text-align: right;\ntext-align: right',
    seq: "a0120",
  },

  "paragraph-Source": {
    outerCss:
      'padding-top: 0px;\npadding-bottom: 12px;\npadding-left: 6px;\npadding-right: 13px;\nline-height: 130%;\nfont-size: 16px;\nfont-weight: 400;\nfont-style: italic;\nfont-family: "Raleway";\ncolor: #888888;\n-webkit-text-align: right;\ntext-align: right',
    custom: {},
    name: "Source",
    innerCss: "width: 80%",
    type: "paragraph",
    seq: "a0130",
  },
  "image-Standard": {
    innerStyle: {},
    custom: {},
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    type: "image",
    name: "Standard",
    outerStyle: {},
    innerCss: "",
    seq: "a0140",
  },

  "image-Standard w caption": {
    outerCss:
      "padding-top: 24px;\npadding-bottom: 0px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard w caption",
    innerCss: "width: 100%",
    custom: {},
    type: "image",
  },

  "youtube-Standard": {
    outerStyle: {},
    innerCss: "width: 100%",
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "youtube",
  },
  "drive-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "drive",
  },
  "form-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "form",
  },
  "html-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "webpage",
  },
  "webpage-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "webpage",
  },
  "slideshow-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 0px;\npadding-bottom: 0px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "webpage",
  },

  "maps-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "maps",
  },
  "pdf-Standard": {
    outerStyle: {},
    innerCss: "width: 100%",
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "pdf",
  },
  "office-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 24px;\npadding-bottom: 24px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "office",
  },
  "audiobook-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 0px;\npadding-bottom: 12px;\npadding-left: 6px;\npadding-right: 6px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "audiobook",
  },
  "background-Bluish": {
    name: "Bluish",
    portrait:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/bg/angryimg.png",
    landscape:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/bg/angryimg.png",
    type: "background",
  },
  "background-Yellowish": {
    name: "Yellowish",
    portrait:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/bg/yellow.jpeg",
    landscape:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/bg/yellow.jpeg",
    type: "background",
  },
  "background-Greenish": {
    name: "Greenish",
    portrait:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/bg/green.jpeg",
    landscape:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/bg/green.jpeg",
    type: "background",
  },
  "background-GreenishAlt": {
    name: "GreenishAlt",
    portrait:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/greenish2.jpeg",
    landscape:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/greenish2.jpeg",
    type: "background",
  },
  "background-PinkHearts": {
    name: "PinkHearts",
    portrait:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/pinkheart.jpeg",
    landscape:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/pinkheart.jpeg",
    type: "background",
  },
  "background-PinkBasic": {
    name: "PinkBasic",
    portrait:
      "https://downloadsnetenglish.s3.us-west-004.backblazeb2.com/bg/pink.jpeg",
    landscape:
      "https://downloadsnetenglish.s3.us-west-004.backblazeb2.com/bg/pink.jpeg",
    type: "background",
  },

  "epub-Standard": {
    outerStyle: {},
    innerCss: "",
    outerCss:
      "padding-top: 0px;\npadding-bottom: 0px;\npadding-left: 0px;\npadding-right: 0px;\n-webkit-text-align: center;\ntext-align: center",
    name: "Standard",
    custom: {},
    innerStyle: {},
    type: "office",
  },
  "equation-Standard": {
    type: "equation",
    name: "Standard",
    outerCss: `
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "code-Standard": {
    type: "code",
    name: "Standard",
    outerCss: `
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
    custom: { codeLang: "javascript", codeTheme: "3024-day" },
  },
  "stocks-Standard": {
    type: "stocks",
    name: "Standard",
    outerCss: `
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "mp3-Standard": {
    type: "mp3",
    name: "Standard",
    outerCss: `
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "file-Standard": {
    type: "file",
    name: "Standard",
    outerCss: `
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "fileDownload-Standard": {
    type: "fileDownload",
    name: "Standard",
    outerCss: `
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "dictionary-Standard": {
    type: "dictionary",
    name: "Standard",
    outerCss: `
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "divider-Standard": {
    type: "divider",
    name: "Standard",
    outerCss: `
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "complete-Standard": {
    type: "complete",
    name: "Standard",
    outerCss: `
    padding-top: 0px;
    padding-bottom: 18px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: right;
      `,

    innerCss: ``,
    outerStyle: {},
    innerStyle: {},
  },
  "essay-Standard": {
    type: "essay",
    name: "Standard",
    outerCss: `
    padding-top: 12px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: left;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "feedback-Standard": {
    type: "feedback",
    name: "Standard",
    outerCss: `
    padding-top: 12px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: left;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "rating-Standard": {
    type: "rating",
    name: "Standard",
    outerCss: `
    padding-top: 12px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "link-Standard": {
    type: "link",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    padding-top: 6px;
    padding-bottom: 12px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: left;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "chapterlink-Standard": {
    type: "chapterlink",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: left;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "chapterlink-StandardNoBottom": {
    type: "chapterlink",
    name: "StandardNoBottom",
    outerCss: `
    font-size: 18px;
    padding-top: 6px;
    padding-bottom: 0px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: left;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "cards-Standard": {
    type: "cards",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "quizditch-Standard": {
    type: "quizditch",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "player-Standard": {
    type: "player",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "mp3player-Standard": {
    type: "mp3player",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "sentence-Standard": {
    type: "sentence",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    line-height: 190%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 18px;
    padding-right: 6px;
      text-align: left;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "quiz-Standard": {
    type: "quiz",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    line-height: 190%;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 6px;
    padding-right: 6px;
      text-align: left;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "table-Standard": {
    type: "table",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    line-height: 190%;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 18px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "embed-Standard": {
    type: "embed",
    name: "Standard",
    outerCss: `
    font-size: 18px;
    line-height: 190%;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 18px;
    padding-right: 6px;
      text-align: center;
      `,

    innerCss: `
      width: 100%;
      `,
    outerStyle: {},
    innerStyle: {},
  },
  "template-Picture": {
    type: "template",
    name: "Picture",
    value: {
      list: [
        "75af349f-92fd-4749-b696-44262f1d4ee4",
        "c3cc89a8-41c8-442c-8ea5-4282c425c8aa",
      ],
      content: {
        "75af349f-92fd-4749-b696-44262f1d4ee4": {
          name: "Standard",
          type: "image",
          url: "*pic*",
          caption: "something here",
          custom: {},
          parent: null,
          date: "7dc22150-8b9f-42f8-a522-e377098d4c1a",
          outerStyle: { paddingBottom: 0 },
          innerStyle: {},
        },
        "c3cc89a8-41c8-442c-8ea5-4282c425c8aa": {
          name: "Caption",
          type: "paragraph",
          text: "*info*",
          outerStyle: {},
          innerStyle: {},
          custom: {},
          date: "06f2adc1-29c1-4b35-8353-284e007ff51b",
        },
      },
    },
  },
};

export const baseTheme: any = {
  primary: "#ff0000",
  secondary: "#00ff00",
  font: "Nunito",
};

export const stringStyles = (fullStyle: any) => {
  const newObject: any = {};

  Object.keys(fullStyle).forEach((item: any) => {
    if (item.includes("padding") || item.includes("fontSize")) {
      newObject[item] = fullStyle[item] + "px";
    } else if (item === "width" || item === "lineHeight") {
      newObject[item] = (fullStyle[item] + "%").replace("%%", "%");
    } else {
      newObject[item] = fullStyle[item];
    }
  });

  return newObject;
};

export const cleanUp = (str: string) => {
  return str.replace(/ /g, "_");
};

export const getCssFromObject = (obj: any) => {
  return Object.keys(obj)
    .map((key: any) => {
      let newLine = key.replace(/([A-Z])/g, "-$1").toLowerCase() + ": ";

      if (
        [
          "paddingLeft",
          "paddingTop",
          "paddingRight",
          "paddingBottom",
          "fontSize",
        ].includes(key)
      ) {
        newLine = newLine + obj[key] + "px";
      } else if (["width", "lineHeight"].includes(key)) {
        newLine = (newLine + obj[key] + "%").replace(/%{2,}/g, "%");
      } else if (["fontFamily"].includes(key)) {
        newLine = newLine + '"' + obj[key] + '"';
      } else {
        newLine = newLine + obj[key];
      }
      return newLine;
    })
    .join(";\n");
};
