import { Browser } from "@capacitor/browser";
import { Device } from "@capacitor/device";
import React from "react";
import { FaAndroid, FaApple } from "react-icons/fa";
import styled from "styled-components";
import { tx } from "utils/globalize";

const AppDiv: any = styled.div`
  border: 1px solid lightgrey;
  padding: 10px 10px 10px 10px;
  font-weight: bold;
  color: var(--toolbar-background);
  cursor: pointer;
`;

let needsapp = false;
let os = "";

try {
  Device.getInfo().then((i: any) => {
    if (
      i.platform === "web" &&
      (i.operatingSystem === "android" || i.operatingSystem === "ios")
    ) {
      needsapp = true;
    }

    os = i.operatingSystem;
  });
} catch (e) {
  console.log(e);
}

const GetApp = (props: any) => {
  const openStore = () => {
    if (os === "ios") {
      Browser.open({
        url: "https://apps.apple.com/am/app/inishare/id1626735943",
      });
    } else {
      Browser.open({
        url: "https://play.google.com/store/apps/details?id=com.inisharex.app",
      });
    }
  };

  return (
    <React.Fragment>
      {needsapp ? (
        <React.Fragment>
          <AppDiv onClick={openStore}>
            {os === "android" ? <FaAndroid /> : <FaApple />}
            {"   "}
            <span style={{ textDecoration: "underline" }}>
              {tx("getapppp", "Get the app. It's even better.")}
            </span>
          </AppDiv>
          {props.bigger ? <div style={{ height: "15px" }} /> : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default GetApp;
