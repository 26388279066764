import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const shareItemSlice = createSlice({
  initialState,
  name: "shareItem",
  reducers: {
    setShareItem: (state: string, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setShareItem } = shareItemSlice.actions;

export default shareItemSlice.reducer;
