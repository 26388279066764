import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const contextSlice = createSlice({
  initialState,
  name: "context",
  reducers: {
    setContext: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setContext } = contextSlice.actions;

export default contextSlice.reducer;
