import { createSlice } from "@reduxjs/toolkit";

// ** Initial State
const initialState: any = {};

const myBooksSlice = createSlice({
  initialState,
  name: "myBooks",
  reducers: {
    setMyBooks: (state, action) => {
      return action.payload;
    },
  },
});

export const { setMyBooks } = myBooksSlice.actions;

export default myBooksSlice.reducer;
