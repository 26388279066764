import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = null;

const exeSlice = createSlice({
  initialState,
  name: "exe",
  reducers: {
    setExe: (state: any[], action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setExe } = exeSlice.actions;

export default exeSlice.reducer;
