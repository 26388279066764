export const introseq = [
  {
    act: "intro",
    target: "body",
    starter: true,
    title: "Welcome to IniShare!",
    transtitle: "zmgong",

    content:
      "Hey, you just signed up for IniShare, the app where you can create professional looking digital books and booklets, and read and share them.<br/>You can always get help by clicking on the ? button wherever you are.",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "",
        question: { trans: "wszsdwefwe33", text: "Let me just look around." },
      },
      {
        newride: "helpoptions",
        question: { trans: "bub35afwe34", text: "Open the help." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "lweibdbmx1intro1k1",
    noNext: true,
  },
  {
    act: "introsamples",
    target: "body",
    title: "Welcome to IniShare!",
    starter: true,
    transtitle: "zmgong",

    content:
      "Hey, you just signed up for IniShare, the app where you can create professional looking digital books and booklets, and read and share them.<br/>You can always get help by clicking on the ? button wherever you are.",
    placement: "center",
    disableBeacon: true,
    action: [
      {
        newride: "",
        question: {
          trans: "wszsdwefwe33cs",
          text: "Let me just read my book.",
        },
      },
      {
        newride: "helpoptions",
        question: { trans: "bub35afwe34", text: "Open the help." },
      },
    ],
    spotlightClicks: false,
    hideBackButton: true,
    trans: "libdbmx1intrcmrwweo1k1",
    noNext: true,
  },
  {
    anchor: "helpavailable",
    target: "body",
    placement: "center",
    content:
      "No problem. But never forget, that help is always available by clicking on the ? button wherever you are.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmx2asdfk2",
  },
  {
    act: "moveover",
    target: "body",
    placement: "center",
    content:
      "Ok, let's start. You are now seeing books which OTHERS shared with you. To create your book you have to switch to your OWN books.",
    disableBeacon: true,
    spotlightClicks: false,
    noNext: false,
    hideBackButton: true,
    trans: "libdbmwex2asdfk3",
  },

  {
    target: "#bookfilterid",
    content: "Click to open the filter menu.",
    disableBeacon: true,
    spotlightClicks: true,
    noNext: true,
    hideBackButton: true,
    trans: "libdbwermx22k4",
  },

  {
    target: "#bbyme",
    content: "Click to show books YOU created.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    noNext: true,
    trans: "libddfgbmx23k5",
    goto: "first",
  },
  {
    act: "helpavailable",
    anchor: "helpavailable",
    target: "body",
    placement: "center",
    content:
      "IniShare has a context sensitive help. Whenever you are not sure what to do, just click on one of the ? buttons at the bottom or at the top.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libddfgbmx23k5oi",
  },
  {
    target: "body",
    placement: "center",
    content:
      "You will then get a tour or the functions of the program available on that screen. Sometimes you have to click on the Next button, sometimes you have to click on something on the screen.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libddfgbmx23k5oi1",
  },
  {
    target: "body",
    placement: "center",
    content: "Just close this now and click on the ? button for further help.",
    disableBeacon: true,
    spotlightClicks: false,
    hideBackButton: true,
    noNext: false,
    trans: "libddfgbmx23k5oi2",
  },
];
