import { FreeData } from "firebaseUtil/firebaseUtil";

import { createSlice } from "@reduxjs/toolkit";

// ** Initial State

export interface PointerType {
  assignmentId: string;
  exercise: number;
  iteration: number;
}

export interface IterationType {
  exercise: any;
  done: number;
  results: FreeData;
}

export interface ExerciseType {
  origId: string;
  title: string;
  done: number;
  iterations: IterationType[];
  rounds?: number;
  type?: string;
}

export interface AssignmentType {
  id: string;
  date: number;
  done: number;
  rules: any;
  exercises: ExerciseType[];
}

const initialState: AssignmentType = {
  id: "",
  date: 0,
  done: 0,
  rules: [{ tags: [], required: 20, size: 10 }],
  exercises: [
    {
      origId: "",
      title: "",
      done: 0,
      iterations: [
        {
          exercise: {},
          done: 0,
          results: {},
        },
      ],
    },
  ],
};

const assignmentSlice = createSlice({
  initialState,
  name: "assignment",
  reducers: {
    setAssignment: (state, action) => {
      return action.payload;
    },
    addIteration: (state, action) => {
      state.exercises[action.payload.exercise].iterations.push(
        action.payload.newIteration
      );
    },
  },
});

export const { setAssignment, addIteration } = assignmentSlice.actions;

export default assignmentSlice.reducer;
