import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = false;

const driveStatusSlice = createSlice({
  initialState,
  name: "driveStatus",
  reducers: {
    setDriveStatus: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setDriveStatus } = driveStatusSlice.actions;

export default driveStatusSlice.reducer;
